import styled, { css } from "styled-components";
import {
  CameraSelection,
  FormField,
  MicSelection,
  QualitySelection,
  SpeakerSelection,
} from "amazon-chime-sdk-component-library-react";

import PreviewVideo from "./PreviewVideo";

export const title = `
  text-transform: uppercase;
  font-size: 1rem !important;
  margin-bottom: 1.75rem;
`;

export const StyledPreviewGroup = styled.div`
  margin-bottom: 0.5rem;
`;

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 65rem;
  height: auto;
  padding: 1rem 0 3rem 0;

  > * {
    flex-basis: auto;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    padding: 2.5rem 0 6rem 0;

    > * {
      flex-basis: 50%;
    }

    @media (max-height: 800px) {
      padding: 2rem 0 2rem;
    }
  }
`;

export const StyledCard = styled.div`
  padding: 0.75rem;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0.5rem;
`;

export const StyledInputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledPreviewVideo = styled(PreviewVideo)`
  margin-bottom: 0.25rem;
  height: auto;
  background: unset;
  & video {
    position: static;
    object-fit: cover !important;
    border-radius: 10px !important;
  }
`;
export const StyledIconWrapper = styled.div`
  margin-right: 0.5rem;
  & svg {
    display: block;
  }
`;

export const StyledHeaderText = styled.div`
  color: var(--text-text, #292524);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
`;

export const StyledDeviceSelectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const StyledDeviceSelectionHeaderIconAndText = styled.div`
  display: flex;
  align-items: center;
`;

const getDisabledStyle = (disabled: boolean | undefined) => css`
  & select {
    pointer-events: ${disabled ? "none" : "all"};
    opacity: ${disabled ? 0.7 : 1};
  }
`;
export const StyledCameraSelection = styled(CameraSelection)<{
  $disabled?: boolean;
}>`
  ${({ $disabled }) => getDisabledStyle($disabled)}
`;

export const StyledMicSelection = styled(MicSelection)<{
  $disabled?: boolean;
}>`
  ${({ $disabled }) => getDisabledStyle($disabled)}
`;
export const StyledSpeakerSelection = styled(SpeakerSelection)<{
  $disabled?: boolean;
}>`
  ${({ $disabled }) => getDisabledStyle($disabled)}
`;

export const StyledQualitySelection = styled(QualitySelection)<{
  $disabled?: boolean;
}>`
  ${({ $disabled }) => getDisabledStyle($disabled)}
`;

export const StyledFormField = styled(FormField)<{ $disabled?: boolean }>`
  ${({ $disabled }) => getDisabledStyle($disabled)}
`;
