import clsx from "clsx";
import { MouseEventHandler, useContext, useMemo } from "react";
import { BaseContext } from "../../lib/context/context";
import { UserInfo } from "../../lib/interfaces/user";
import { AvatarGroupCircle } from "../avatarGroupCircle/AvatarGroupCircle";
import { Badge } from "../badge/Badge";
import { Body } from "../typography/body/Body";
import styles from "./style.module.css";

export type ButtonType =
  | "primary"
  | "secondary"
  | "secondary-gray"
  | "tertiary";
export type ButtonSize = "medium" | "small";

export interface ChatPreviewProps {
  onClick?: MouseEventHandler;
  participants: UserInfo[];
  name?: string;
  time: string;
  preview: string;
  unread?: boolean;
  selected?: boolean;
  regarding?: string;
  className?: string;
  truncatePreviewOneLine?: boolean;
  chatId?: string;
}

export const ChatPreview = ({
  onClick,
  participants,
  name,
  preview,
  time,
  selected = false,
  unread = false,
  regarding,
  className,
  truncatePreviewOneLine,
  chatId,
}: ChatPreviewProps) => {
  const { user } = useContext(BaseContext);

  const subText = useMemo(() => {
    const patientTitle: any = [];
    const otherTitle: any = [];

    // Iterate over the filtered participants and separate the names
    participants.forEach((user) => {
      if (user.title === "Patient" && user.name) {
        patientTitle.push(user.title);
      } else {
        otherTitle.push(user.title);
      }
    });
    // Format patient names with brackets and join with other names
    const formattedPatientTitles =
      patientTitle.length > 0 ? `[${patientTitle.join(", ")}]` : "";
    const formattedOtherTitles = otherTitle.join(", ");

    // Combine the formatted names
    return [formattedPatientTitles, formattedOtherTitles]
      .filter(Boolean)
      .join(", ");
  }, [participants]);

  const allNames = useMemo(() => {
    const patientNames: any = [];
    const otherNames: any = [];

    participants.forEach((participant) => {
      // If the participant's ID matches the user's ID, use 'You' instead of their name
      const name = participant.id === user?.sub ? "You" : participant.name;

      if (participant.title === "Patient" && participant.name) {
        patientNames.push(name);
      } else {
        otherNames.push(name);
      }
    });

    // Format patient names with brackets and join with other names
    const formattedPatientNames =
      patientNames.length > 0 ? `[${patientNames.join(", ")}]` : "";
    const formattedOtherNames = otherNames.join(", ");

    // Combine the formatted names
    return [formattedPatientNames, formattedOtherNames]
      .filter(Boolean)
      .join(", ");
  }, [participants]);

  const chatName = useMemo(() => {
    const patientNames: any = [];
    const otherNames: any = [];

    participants.forEach((participant) => {
      // If the participant's ID matches the user's ID, use 'You' instead of their name
      const name = participant.id === user?.sub ? "You" : participant.name;

      if (participant.title === "Patient" && participant.name) {
        patientNames.push(name);
      } else {
        otherNames.push(name);
      }
    });

    // Format patient names with brackets and join with other names
    const formattedPatientNames =
      patientNames.length > 0 ? `[${patientNames.join(", ")}]` : "";
    const formattedOtherNames = otherNames.join(", ");

    // Combine the formatted names
    return [formattedPatientNames, formattedOtherNames]
      .filter(Boolean)
      .join(", ");
  }, [participants]);

  return (
    <div
      onClick={onClick}
      className={clsx(styles.base, selected && styles.selected, className)}
      data-dd-action-name={chatId}
    >
      <div className={styles.avatarContainer}>
        <AvatarGroupCircle users={participants} size="lg" />
      </div>

      <div className={styles.chatPreviewContainer}>
        <div
          data-dd-privacy="mask"
          title={allNames}
          className={styles.nameTimeContainer}
        >
          <div className={styles.nameContainer}>
            <Body
              className={styles.names}
              size="sm"
              weight="bold"
              color="secondary"
              data-dd-privacy="mask"
            >
              {chatName}
            </Body>
            {/* display number of extra participants +5 */}
            {(!name || name.length === 0) && participants.length > 5 && (
              <Body
                size="sm"
                weight="bold"
                color="secondary"
                data-dd-privacy="mask"
              >
                {["+", participants.length - 5].join("")}
              </Body>
            )}
          </div>
          <Body
            className={styles.time}
            size="xs"
            weight="regular"
            color="secondary"
          >
            {time}
          </Body>
        </div>

        <Body
          className={styles.subText}
          size={"sm"}
          weight="regular"
          color="secondary"
          data-dd-privacy="mask"
          title={subText}
        >
          {subText}
        </Body>
        {regarding && (
          <Body
            className={styles.subText}
            size={"sm"}
            weight="regular"
            color="secondary"
            data-dd-privacy="mask"
          >{`Discussing ${regarding} (patient)`}</Body>
        )}

        <div className={styles.previewContainer}>
          <Body
            className={clsx(
              styles.preview,
              truncatePreviewOneLine && styles.truncate,
              preview === "noLatestMessage" && styles.italic,
            )}
            size="sm"
            weight={unread ? "bold" : "regular"}
            color="primary"
            data-dd-privacy="mask"
          >
            {preview === "noLatestMessage" ? "Draft" : preview}
          </Body>
        </div>
      </div>

      {unread ? <Badge type="neutral" top="50%" right={16} /> : null}
    </div>
  );
};
