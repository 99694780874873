import {
  VideoTileGrid,
  UserActivityProvider,
} from "amazon-chime-sdk-component-library-react";
import { MarkLogo } from "../../../../components/customIcons/CustomIcons";

import MeetingControls from "../../containers/MeetingControls";
import NavigationControl from "../../containers/Navigation/NavigationControl";

import { StyledContent, StyledDefaultBackground, StyledLayout } from "./Styled";
import { Sidebar } from "../../../../components/sidebar/Sidebar";
import { MenuButton } from "../../../../components/menuButton/MenuButton";
import { useVideoChat } from "../../context/VideoChatContext";
import { useStartVideo } from "../../hooks/useStartVideo";

const Meeting = () => {
  useStartVideo();
  const { showRoster, toggleRoster } = useVideoChat();

  return (
    <div style={{ width: "100%" }}>
      <UserActivityProvider>
        <StyledLayout showNav={true} showRoster={true} showChat={true}>
          <Sidebar>
            <li>
              <MenuButton
                type="videochat"
                onClick={toggleRoster}
                active={showRoster}
              />
            </li>
          </Sidebar>
          <StyledContent>
            <VideoTileGrid
              layout={"standard"}
              className="videos"
              noRemoteVideoView={
                <StyledDefaultBackground>
                  <MarkLogo size={150} />
                </StyledDefaultBackground>
              }
            />
            <MeetingControls />
          </StyledContent>
          <NavigationControl />
        </StyledLayout>
      </UserActivityProvider>
    </div>
  );
};

export default Meeting;
