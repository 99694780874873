import clsx from "clsx";
import { Body } from "../typography/body/Body";
import styles from "./style.module.css";
import {
  X,
  UserCircle,
  Warning,
  WarningCircle,
  CheckCircle,
  WarningOctagon,
} from "phosphor-react";
import { useMemo } from "react";

export type NotificationType =
  | "neutral"
  | "info"
  | "success"
  | "danger"
  | "warning";
export interface AlertBannerProps {
  type?: NotificationType;
  message: string;
  onClose?: () => void;
  withIcon?: boolean;
}

export const AlertBanner = ({
  type = "neutral",
  message,
  onClose,
  withIcon = true,
}: AlertBannerProps) => {
  const Icon = useMemo(() => {
    if (withIcon)
      switch (type) {
        case "neutral":
          return UserCircle;
        case "info":
          return WarningCircle;
        case "success":
          return CheckCircle;
        case "danger":
          return WarningOctagon;
        case "warning":
          return Warning;
        default:
          return undefined;
      }
    else return undefined;
  }, [type]);

  return (
    <div className={clsx(styles.alertBanner, styles[type])}>
      {Icon && <Icon size={20} color="white" />}
      <Body size="sm" color="white">
        {message}
      </Body>
      {onClose && (
        <button className={styles.closeButton} onClick={onClose}>
          <X size={20} color="white" />
        </button>
      )}
    </div>
  );
};
