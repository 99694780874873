import { useEffect, useState } from "react";
import { Heading } from "../../../components/typography/heading/Heading";
import { VitalCard } from "../../../components/vitalCard/VitalCard";
import {
  RulerIcon,
  ScaleIcon,
  PersonIcon,
  TemperatureIcon,
  BubbleIcon,
  StethoscopeIcon,
} from "../../../components/customIcons/CustomIcons";
import { useVitals } from "../../../lib/hooks/useVitals";
import { VitalsInterface } from "../../../lib/interfaces/vitals";
import { format } from "date-fns";
import styles from "./style.module.css";
import { PatientInfo } from "../../../lib/interfaces/user";
import { Spinner } from "../../../components/spinner/Spinner";
import axios from "axios";

export interface VitalsProps {
  patient: PatientInfo;
}

export const Vitals = ({ patient }: VitalsProps) => {
  const { getVitals } = useVitals();

  const controller = new AbortController();

  const [loading, setLoading] = useState<boolean>(true);
  const [vitals, setVitals] = useState<VitalsInterface>({
    Height: { reading: "-", date: "" },
    Weight: { reading: "-", date: "" },
    BMI: { reading: "-", date: "" },
    Temp: { reading: "-", date: "" },
    SpO2: { reading: "-", date: "" },
    BP: { reading: "-", date: "" },
  });

  useEffect(() => {
    getVitals(patient.id, controller).then((_vitals) => {
      setVitals(_vitals);
      setLoading(false);
    });

    return () => controller.abort();
  }, []);

  // useEffect(() => {
  //   setLoading(false)
  // }, [vitals])

  const getFriendlyDate = (unfriendlyDate: string | undefined) => {
    if (unfriendlyDate !== "-" && unfriendlyDate !== undefined) {
      const date = new Date(unfriendlyDate);
      return format(date, "MM/dd/yyyy");
    } else return "";
  };

  return (
    <div>
      <Heading className={styles.heading} type="02">
        Vitals
      </Heading>
      {!loading ? (
        <div className={styles.cards}>
          <VitalCard
            className={styles.card}
            Icon={RulerIcon}
            label={"Height"}
            data={vitals.Height.reading !== "-" ? vitals.Height.reading : "-"}
            caption={
              vitals.Height.date !== ""
                ? getFriendlyDate(vitals.Height.date)
                : ""
            }
          />
          <VitalCard
            className={styles.card}
            Icon={ScaleIcon}
            label={"Weight"}
            data={vitals.Weight.reading !== "-" ? vitals.Weight.reading : "-"}
            caption={
              vitals.Weight.date !== ""
                ? getFriendlyDate(vitals.Weight.date)
                : ""
            }
          />
          <VitalCard
            className={styles.card}
            Icon={PersonIcon}
            label={"BMI"}
            data={vitals.BMI.reading !== "-" ? vitals.BMI.reading : "None"}
            caption={
              vitals.BMI.date !== "" ? getFriendlyDate(vitals.BMI.date) : ""
            }
          />
          <VitalCard
            className={styles.card}
            Icon={TemperatureIcon}
            label={"Temperature"}
            data={vitals.Temp.reading !== "-" ? vitals.Temp.reading : "-"}
            caption={
              vitals.Temp.date !== "" ? getFriendlyDate(vitals.Temp.date) : ""
            }
          />
          <VitalCard
            className={styles.card}
            Icon={BubbleIcon}
            label={"O2 Saturation"}
            data={vitals.SpO2.reading}
            caption={
              vitals.SpO2.date !== "" ? getFriendlyDate(vitals.SpO2.date) : ""
            }
          />
          <VitalCard
            className={styles.card}
            Icon={StethoscopeIcon}
            label={"Blood Pressure"}
            data={vitals.BP.reading}
            caption={
              vitals.BP.date !== "" ? getFriendlyDate(vitals.BP.date) : ""
            }
          />
        </div>
      ) : (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
