import { useOktaAuth } from "@okta/okta-react";
import {
  fetchFiles,
  fetchFile,
  postFile,
  deleteFile,
  DeleteFilePayload,
} from "../apis/files";
import { File } from "../interfaces/file";
import { AlertContext } from "../context/context";
import { useContext } from "react";
import { UploadFilePayload } from "../apis/files";

export const useFiles = () => {
  const { oktaAuth } = useOktaAuth();
  const { pushAlert } = useContext(AlertContext);
  const accessToken = oktaAuth.getAccessToken();

  const getFiles = async (
    userId: string,
    controller?: AbortController,
  ): Promise<File[]> => {
    if (accessToken) {
      const result = await fetchFiles(userId, controller);
      if (!result)
        pushAlert(
          "Failed to get patient's files. Please try refreshing the page.",
          "danger",
        );
      if (!result || result === "cancelled") return [];
      return result;
    }
    return [];
  };

  const getFile = async (
    userId: string,
    attachmentId: string,
  ): Promise<File[]> => {
    if (accessToken) {
      const result = await fetchFile(userId, attachmentId);
      return result;
    }
    return [];
  };

  const uploadFile = async (file: UploadFilePayload) => {
    if (accessToken) {
      const result = await postFile(file);
      return result;
    }
  };

  const removeFile = async (file: DeleteFilePayload) => {
    if (accessToken) {
      const result = await deleteFile(file);

      if (result.status === 200) {
        pushAlert("File successfully deleted", "success");
        return "success";
      } else {
        pushAlert("File unable to be deleted. Please try again.", "danger");
        return "error";
      }
    }
  };

  return { getFiles, getFile, uploadFile, removeFile };
};
