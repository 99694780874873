import { useState, useEffect, useContext } from "react";
import { Heading } from "../../../components/typography/heading/Heading";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { NewAppointmentCard } from "../../../components/newAppointmentCard/NewAppointmentCard";
import { Checkbox } from "../../../components/checkbox/Checkbox";
import {
  defaultProvider,
  PatientInfo,
  ProviderInfo,
} from "../../../lib/interfaces/user";
import { ProviderSearchOption } from "../../../lib/interfaces/schedule";
import { format, isValid } from "date-fns";
import { Option } from "../../../lib/interfaces/input";
import { Spinner } from "../../../components/spinner/Spinner";
import styles from "./style.module.css";
import { BaseContext } from "../../../lib/context/context";
import { AppointmentType } from "../../../lib/apis/types/visitReason.types";

export interface NewApptDetailsProps {
  showAppointmentDetails: boolean;
  patientId: string;
  selectedPatientInfo: PatientInfo | undefined;
  selectedDate: string;
  selectedTime: Option;
  selectedProvider: ProviderSearchOption;
  visitType: Option;
  bookAnother: boolean;
  handleCheckboxToggle: () => void;
  handleCancel: () => void;
  handleBook: () => void;
  bookLoading: boolean;
}

export const NewApptDetails = ({
  showAppointmentDetails,
  patientId,
  selectedPatientInfo,
  selectedDate,
  selectedTime,
  selectedProvider,
  visitType,
  bookAnother,
  handleCheckboxToggle,
  handleCancel,
  handleBook,
  bookLoading,
}: NewApptDetailsProps) => {
  const { allProviders } = useContext(BaseContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [provider, setProvider] = useState<ProviderInfo>();

  useEffect(() => {
    if (selectedProvider.provider.value !== "" && allProviders)
      setProvider(
        allProviders.find(
          (provider) => provider.id === selectedProvider.provider.value,
        ),
      );
  }, [selectedProvider, allProviders]);

  useEffect(() => {
    if (selectedPatientInfo && provider) {
      setLoading(false);
    }
  }, [selectedPatientInfo, provider]);

  const getFriendlyDate = (unfriendlyDate: string, time: string) => {
    const browserFriendlyDate =
      unfriendlyDate.substring(0, 10) + "T" + unfriendlyDate.substring(10 + 1);
    if (!isValid(new Date(browserFriendlyDate))) {
      return "";
    }
    const date = new Date(browserFriendlyDate);
    return format(date, "cccc, MMMM d") + " at " + time;
  };

  return (
    <div>
      {showAppointmentDetails && (
        <div>
          <Heading type="03" className={styles.heading}>
            Appointment Details
          </Heading>
          {!loading ? (
            <div>
              <NewAppointmentCard
                patient={
                  selectedPatientInfo || {
                    id: "",
                    name: "",
                    firstName: "",
                    lastName: "",
                    title: "Patient",
                  }
                }
                date={getFriendlyDate(selectedDate, selectedTime.name)}
                provider={provider || defaultProvider}
                appointmentType={visitType.secondaryText as AppointmentType}
                visitType={visitType.name}
              />
              <div className={styles.book}>
                <Checkbox
                  checked={bookAnother}
                  onToggle={() => {
                    handleCheckboxToggle();
                  }}
                  label="Book Another"
                />
                <ButtonGroup className={styles.buttonGroup}>
                  <Button
                    onClick={() => {
                      handleCancel();
                    }}
                    type="secondary-gray"
                    label="Cancel"
                  ></Button>
                  <Button
                    onClick={() => {
                      handleBook();
                    }}
                    type="primary"
                    label="Book"
                    loading={bookLoading}
                  ></Button>
                </ButtonGroup>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      )}
    </div>
  );
};
