import { Option } from "./input";

/* Interfaces for WeeklyHours component */

export interface Hours {
  from: Option;
  to: Option;
}

export interface WeeklyHoursArray {
  [weekday: string]: Hours[];
}

export interface KeysArray {
  [weekday: string]: string[];
}

export interface Checked {
  [weekday: string]: boolean;
}

export const defaultChecked = {
  MON: false,
  TUE: false,
  WED: false,
  THU: false,
  FRI: false,
  SAT: false,
};

export const defaultWeeklyHours = {
  MON: [
    {
      from: { name: "9:00am", value: "9:00am" },
      to: { name: "5:00pm", value: "5:00pm" },
    },
  ],
  TUE: [
    {
      from: { name: "9:00am", value: "9:00am" },
      to: { name: "5:00pm", value: "5:00pm" },
    },
  ],
  WED: [
    {
      from: { name: "9:00am", value: "9:00am" },
      to: { name: "5:00pm", value: "5:00pm" },
    },
  ],
  THU: [
    {
      from: { name: "9:00am", value: "9:00am" },
      to: { name: "5:00pm", value: "5:00pm" },
    },
  ],
  FRI: [
    {
      from: { name: "9:00am", value: "9:00am" },
      to: { name: "5:00pm", value: "5:00pm" },
    },
  ],
  SAT: [
    {
      from: { name: "9:00am", value: "9:00am" },
      to: { name: "5:00pm", value: "5:00pm" },
    },
  ],
};

export const defaultKeys = {
  MON: [],
  TUE: [],
  WED: [],
  THU: [],
  FRI: [],
  SAT: [],
};

/* Interfaces for DateOverrides component */

export interface OverridenDate {
  date: string;
  hours?: Hours[] /*can be optional*/;
}
