import { ForwardRefExoticComponent, RefAttributes } from "react";
import { IconProps } from "phosphor-react";

import { CustomIcon } from "../../../../../components/customIcons/CustomIcons";
import { Switch, SwitchProps } from "../../../../../components/switch/Switch";
import {
  StyledDeviceSelectionHeader,
  StyledDeviceSelectionHeaderIconAndText,
  StyledHeaderText,
  StyledIconWrapper,
} from "../Styled";

interface IDeviceSelectionHeader extends Partial<SwitchProps> {
  Icon:
    | ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>
    | CustomIcon;
  text: string;
}
export const DeviceSelectionHeader = ({
  Icon,
  text,
  active,
  onToggle,
  disabled = false,
}: IDeviceSelectionHeader) => {
  return (
    <StyledDeviceSelectionHeader>
      <StyledDeviceSelectionHeaderIconAndText>
        <StyledIconWrapper>
          <Icon />
        </StyledIconWrapper>
        <StyledHeaderText>{text}</StyledHeaderText>
      </StyledDeviceSelectionHeaderIconAndText>
      {active !== undefined && onToggle ? (
        <Switch active={active} onToggle={onToggle} disabled={disabled} />
      ) : null}
    </StyledDeviceSelectionHeader>
  );
};
