import {
  PopOver,
  PopOverHeader,
  PopOverSeparator,
} from "amazon-chime-sdk-component-library-react";

import { PopOverOption, MediaDeviceKindEnum } from "../../../types";

import {
  StyledCaretDown,
  StyledCaretUp,
  StyledPopOverItem,
  StyledPopOverItemSubheader,
} from "./Styled";

interface ControlPopOverProps {
  options: PopOverOption[];
  popOverHeader?: string;
  textColor?: string;
}

const getDeviceKindLabel = (kind: MediaDeviceKind | undefined) => {
  switch (kind) {
    case MediaDeviceKindEnum.AUDIOINPUT:
      return "Microphone";
    case MediaDeviceKindEnum.AUDIOOUTPUT:
      return "Speaker";
    case MediaDeviceKindEnum.VIDEOINPUT:
      return "Camera";
    default:
      return "";
  }
};

const ControlPopOver = ({
  options,
  popOverHeader,
  textColor = "#ffffff",
}: ControlPopOverProps) => {
  const getButtonContents = (isOpen: boolean, textColor?: string) => {
    if (isOpen) {
      return <StyledCaretUp textColor={textColor} size={12} />;
    }

    return <StyledCaretDown textColor={textColor} size={12} />;
  };
  return (
    <PopOver
      a11yLabel="Click me"
      renderButton={(isOpen) => getButtonContents(isOpen, textColor)}
    >
      {popOverHeader && <PopOverHeader title={popOverHeader} subtitle=" " />}
      {options.map((option, i) => {
        const item = (
          <StyledPopOverItem
            key={option.deviceId}
            checked={option.checked}
            onClick={option.handleClick}
          >
            <span>{option.label}</span>
          </StyledPopOverItem>
        );
        if (i !== 0 && options[i].kind !== options[i - 1].kind) {
          return (
            <>
              <PopOverSeparator />
              <StyledPopOverItemSubheader>
                <div>{getDeviceKindLabel(option.kind)}</div>
              </StyledPopOverItemSubheader>
              {item}
            </>
          );
        } else {
          if (i === 0) {
            return (
              <>
                <StyledPopOverItemSubheader>
                  <div>{getDeviceKindLabel(option.kind)}</div>
                </StyledPopOverItemSubheader>
                {item}
              </>
            );
          }
          return <>{item}</>;
        }
      })}
    </PopOver>
  );
};

export { ControlPopOver };
