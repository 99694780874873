import React from "react";
import styles from "./style.module.css";
import clsx from "clsx";

interface MembershipTypeLabelProp {
  label: string;
  type?: string;
  className?: string;
}

const MembershipTypeLabel: React.FC<MembershipTypeLabelProp> = ({
  label,
  type = "light",
  className,
}) => {
  return (
    <div className={clsx(styles.theme, styles[type], className)}>
      {label} Member
    </div>
  );
};

export default MembershipTypeLabel;
