export enum ProviderType {
  Physician = "Physician",
  OutsideSpecialist = "Outside Specialist",
  InsideSpecialist = "Inside Specialist",
  TriageNurse = "Triage Nurse",
  MedAssistant = "Med Assistant",
  LabAgent = "Lab Agent",

  PrimaryCarePhysician = "Primary Care Physician",
  NursePractitioner = "Nurse Practitioner",
  HealthCoach = "Health Coach",
  HealthCoachPool = "Health Coach Pool",
  CareCoordinator = "Care Coordinator",
  CareNavigator = "Care Coordinator",
  SiteAdmin = "Admin",
}

export type Provider = {
  providerId: string;
  ehrId?: string;
  ehrMessagePoolId?: string;
  npi?: string;
  photoUrl: string;
  preferredName: string;
  firstName: string;
  lastName: string;
  email: string;
  providerType: ProviderType;
  highlights?: string[];
  bio?: string;
  areasOfFocus?: string[];
  languages?: string[];
  education?: string[];
  certifications?: string[];
  supportScheduling?: boolean;
  index?: number;
};

export type ProviderSubset = Pick<
  Provider,
  | "providerId"
  | "ehrId"
  | "photoUrl"
  | "preferredName"
  | "email"
  | "providerType"
>;
