import axios, { AxiosPromise } from "axios";

export interface MetadataData {
  type: string;
  title: string;
  url: string;
  domain: string;
  imageUrl?: string;
  customResourceBaseUrl?: string;
}

export interface MetadataResponse extends AxiosPromise {
  data: MetadataData;
}

export const fetchAll = async () => {
  const result = await axios({
    method: "get",
    url: "/resources",
  }).catch((err) => err);
  return result;
};

export const fetch = async (id: string, controller?: AbortController) => {
  const result = await axios
    .get("/resources", {
      params: {
        id,
      },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });

  return result;
};

export const fetchMetadata = async (resourceUrl?: string) => {
  const result = await axios
    .get<MetadataData>("/preview/metadata", {
      params: { url: resourceUrl },
    })
    .catch((err) => err);

  return result;
};

export default {
  fetch,
  fetchAll,
  fetchMetadata,
};
