import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Flex,
  Modal,
  ModalBody,
  ModalHeader,
  useLocalVideo,
  useMeetingManager,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";

import Card from "../../components/Card";
import { useVideoChat } from "../../context/VideoChatContext";

import { StyledPrimaryButton, StyledSecondaryButton } from "./Styled";

const MeetingJoinDetails = () => {
  const meetingManager = useMeetingManager();
  const { muted } = useToggleLocalMute();
  const { isVideoEnabled } = useLocalVideo();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { meetingId, attendeeId } = useVideoChat();

  const handleJoinMeeting = async () => {
    setIsLoading(true);

    try {
      if (muted) await meetingManager.audioVideo?.realtimeMuteLocalAudio();
      await meetingManager.start();
      setIsLoading(false);
      history.push(`/video-chat/${meetingId}/${attendeeId}/meeting`, {
        isUserVideo: isVideoEnabled,
      });
    } catch (error) {
      setIsLoading(false);
      setError((error as Error).message);
    }
  };

  const handleCancel = () => {
    history.push("/scheduling");
  };

  return (
    <>
      <Flex
        container
        alignItems="center"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <StyledSecondaryButton label="Cancel" onClick={handleCancel} />
        <StyledPrimaryButton
          label={isLoading ? "Loading..." : "Join visit"}
          onClick={handleJoinMeeting}
        />
      </Flex>
      {error && (
        <Modal size="md" onClose={(): void => setError("")}>
          <ModalHeader title={`Meeting ID: ${meetingId}`} />
          <ModalBody>
            <Card
              title="Unable to join meeting"
              description="There was an issue in joining this meeting. Check your connectivity and try again."
              smallText={error}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default MeetingJoinDetails;
