import { useState, useContext, useMemo } from "react";
import { UserInfo } from "../../../lib/interfaces/user";
import { AvatarLabelGroup } from "../../../components/avatarLabelGroup/AvatarLabelGroup";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { UsersThree, ArrowSquareOut } from "phosphor-react";
import {
  Participant,
  DiscussedPatient,
} from "../../../lib/interfaces/messaging";
import { BaseContext } from "../../../lib/context/context";
import { useHistory } from "react-router-dom";
import { Modal, useMemoryRouter } from "../../../components/modal/Modal";
import { ParticipantDetails } from "../participantDetails/ParticipantDetails";
import styles from "./style.module.css";
import { v4 } from "uuid";
import { AvatarLabelGroupChat } from "../../../components/avatarLabelGroupChat/AvatarLabelGroupChat";

export interface ChatHeaderProps {
  participants: Participant[];
  discussedPatient?: DiscussedPatient;
  onPopoutClick?: () => void;
}

export const ChatHeader = ({
  participants,
  discussedPatient,
  onPopoutClick = () => {},
}: ChatHeaderProps) => {
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const { user } = useContext(BaseContext);
  const history = useHistory();
  let userIncluded = false;

  const patientParticipant = useMemo(
    () =>
      participants.filter(
        (participant) => participant.title.toUpperCase() === "PATIENT",
      )[0],
    [participants],
  );

  const participantsInfo: UserInfo[] = participants.map((participant) => {
    const { firstName, lastName, photo, title, id } = participant;
    const name = [firstName, lastName].join(" ");
    return { name, firstName, lastName, title, id, photo };
  });

  const participantsInfoExcludingUser = participantsInfo;
  if (participantsInfoExcludingUser.length !== participantsInfo.length)
    userIncluded = true;

  const handleDiscussedPatientClick = () => {
    if (discussedPatient?.id)
      history.push(`/patient/${discussedPatient.id}/careplan`);
  };

  const titleOverride: JSX.Element | undefined = discussedPatient ? (
    <>
      {`Discussing `}
      <a
        className={styles.discussedPatient}
        onClick={handleDiscussedPatientClick}
      >
        {`${discussedPatient.firstName} ${discussedPatient.lastName} (Patient)`}
      </a>
    </>
  ) : undefined;

  const handleAvatarClick = () => {
    if (patientParticipant) {
      history.push(`/patient/${patientParticipant.id}/careplan`);
    }
  };

  const modalMap = useMemo(
    () => ({
      participants: {
        title: "Participants",
        route: "participants",
        component: ParticipantsList,
        props: {
          participants: participantsInfo,
          closeModal: () => {
            setShowParticipantsModal(false);
          },
        },
      },
      "participant-detail": {
        title: "Participant Detail",
        route: "participant-detail",
        component: ParticipantDetails,
      },
    }),
    [participants],
  );

  return (
    <div className={styles.chatHeader}>
      <AvatarLabelGroupChat
        onClick={handleAvatarClick}
        users={participantsInfoExcludingUser}
        userIncluded={userIncluded}
        size="xl"
        titleOverride={titleOverride}
      />
      <ButtonGroup>
        <Button
          type="secondary-gray"
          size="small"
          Icon={ArrowSquareOut}
          onClick={onPopoutClick}
        />
        <Button
          type="secondary-gray"
          size="small"
          Icon={UsersThree}
          onClick={() => setShowParticipantsModal(true)}
        />
      </ButtonGroup>

      <Modal
        className={styles.participantsModal}
        visible={showParticipantsModal}
        initialRoute="participants"
        onCloseModal={() => setShowParticipantsModal(false)}
        componentMap={modalMap}
      />
    </div>
  );
};

interface ParticipantsListProps {
  participants: UserInfo[];
  closeModal?: () => void;
}

const ParticipantsList = ({
  participants,
  closeModal,
}: ParticipantsListProps) => {
  const { pushRoute } = useMemoryRouter();

  const handleParticipantClick = (participant: UserInfo) => {
    if (participant.title.toUpperCase() === "PATIENT") {
      pushRoute("participant-detail", {
        props: {
          id: participant.id,
          userType: "patient",
          closeModal: closeModal,
        },
      });
    } else {
      pushRoute("participant-detail", {
        props: { id: participant.id, userType: "provider" },
      });
    }
  };

  return (
    <ul className={styles.participantsList}>
      {participants.map((participant) => (
        <li className={styles.participant} key={participant.id}>
          <a onClick={() => handleParticipantClick(participant)}>
            <AvatarLabelGroup
              className={styles.avatarLabel}
              size="md"
              users={[participant]}
            />
          </a>
        </li>
      ))}
    </ul>
  );
};
