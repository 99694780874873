import { MagnifyingGlass } from "phosphor-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup } from "../../../../components/button/Button";
import { InteractiveList } from "../../../../components/interactiveList/InteractiveList";
import { useMemoryRouter } from "../../../../components/modal/Modal";
import { Spinner } from "../../../../components/spinner/Spinner";
import { TextInput } from "../../../../components/textInput/TextInput";
import { Body } from "../../../../components/typography/body/Body";
import { AlertContext, BaseContext } from "../../../../lib/context/context";
import { useChats } from "../../../../lib/hooks/useChats";
import { useProvider } from "../../../../lib/hooks/useProvider";
import { ChatPreview } from "../../../../lib/interfaces/messaging";
import { UserInfo } from "../../../../lib/interfaces/user";
import { PatientSelectFormSubmission } from "../patientSelectForm/PatientSelectForm";
import styles from "./style.module.css";
import clsx from "clsx";

export interface ProviderSelectFormProps extends PatientSelectFormSubmission {
  onChatCreated: (chat: ChatPreview | undefined) => void;
}

export const ProviderSelectForm = ({
  patient,
  regarding,
  onChatCreated,
}: ProviderSelectFormProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [allProviders, setAllProviders] = useState<UserInfo[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<UserInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const { getAllProviders } = useProvider();
  const { closeModal } = useMemoryRouter();
  const { getChatWithUsers, createChat } = useChats();
  const { pushAlert, clearAlerts } = useContext(AlertContext);
  const { user } = useContext(BaseContext);

  useEffect(() => {
    getAllProviders().then((res) => {
      res.forEach((provider) => (provider.title = provider.providerType));
      //remove the signed in user from the list of providers
      res.splice(
        res.findIndex((provider) => {
          return provider.id === user?.sub;
        }),
        1,
      );
      setAllProviders(res);
    });
  }, []);

  useEffect(() => () => setLoading(false), [allProviders]);

  const handleSelect = (providers: UserInfo[]) => {
    setSelectedProviders(providers);
  };

  const handleCreateSelect = async () => {
    await getChatWithUsers(
      patient,
      selectedProviders.map((provider) => provider.id),
      regarding?.id,
    ).then((res) => {
      if (res) {
        onChatCreated(res?.chat);
        clearAlerts();
        closeModal();
      } else {
        pushAlert("Failed to create new chat", "danger");
        closeModal();
      }
    });
  };

  const listItems = useMemo(() => {
    return allProviders.filter((provider) => {
      const fullName = [provider.firstName, provider.lastName].join(" ");
      return fullName.match(new RegExp(searchValue, "i")) !== null;
    });
  }, [allProviders, searchValue]);

  return (
    <div className={styles.providerSelectForm}>
      <TextInput
        value={searchValue}
        onChange={setSearchValue}
        LeftIcon={MagnifyingGlass}
      />
      <div className={clsx(styles.listContaner, styles.scrollbar)}>
        <Body className={styles.listLabel} size="sm" color="secondary">
          Providers
        </Body>
        {loading ? (
          <Spinner />
        ) : (
          <InteractiveList
            items={listItems}
            value={selectedProviders} //@ts-ignore
            onChange={handleSelect}
          />
        )}
      </div>
      <ButtonGroup className={styles.buttonContainer}>
        <Button
          label="Create Message"
          disabled={selectedProviders.length === 0 && !patient}
          onClick={handleCreateSelect}
        />
      </ButtonGroup>
      {(patient || regarding || selectedProviders.length > 0) && (
        <Body className={styles.participants} size="sm">
          <strong>{`Message`}</strong>
          {patient ? ` (Patient) ${patient.name}` : <></>}
          {selectedProviders.length > 0 ? (
            ` (Providers) ${selectedProviders
              .map((provider) => provider.name)
              .join(", ")}`
          ) : (
            <></>
          )}
          {regarding ? ` to discuss (Patient) ${regarding.name}` : <></>}
        </Body>
      )}
    </div>
  );
};
