import {
  ChatBubble,
  ChatSender,
} from "../../../components/chatBubble/ChatBubble";
import { Body } from "../../../components/typography/body/Body";
import { Participant, Message } from "../../../lib/interfaces/messaging";
import { useContext, useMemo, useRef, useState } from "react";
import {
  format,
  isToday,
  isYesterday,
  isThisYear,
  isWithinInterval,
  subDays,
  startOfToday,
  isSameMinute,
} from "date-fns";
import { Spinner } from "../../../components/spinner/Spinner";
import { BaseContext } from "../../../lib/context/context";
import { useResources } from "../../../lib/hooks/useResources";
import { ResourceCard } from "../../../components/resourceCard/ResourceCard";
import styles from "./style.module.css";
import { Drawer } from "../../../components/drawer/Drawer";
import { ResourceDetails } from "../../carePlan/resourceDetails/ResourceDetails";
import { Resource, ResourcePreview } from "../../../lib/interfaces/resource";

// Note: 'messages' and 'members' have differently formatted 'id' values for the same user
// 'id' in 'members' is formmated as "<id>"
// 'id' in 'messages' is formatted as "provider_<id>" or "patient_<id>"
// with <id> being the account ID of the user.

export interface ChatThreadProps {
  participants: Participant[];
  messages: Message[];
  loading: boolean;
  onResourceExpand: (resource: string) => void;
}

export const ChatThread = ({
  loading,
  participants,
  messages,
  onResourceExpand,
}: ChatThreadProps) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const { user } = useContext(BaseContext);

  const handleResourceClick = (resource: ResourcePreview) => {
    if (resource.type.includes("custom")) {
      onResourceExpand(resource.id);
    } else {
      window.open(resource.url, "_blank");
    }
  };

  const renderedChatThread: JSX.Element[] = useMemo(
    () =>
      messages
        .map((message, i) => {
          const render = [];
          const { author, body, createdDate } = message;
          const date = new Date(createdDate);
          const nextDate = new Date(messages[i + 1]?.createdDate);
          if (i === messages.length - 1 || !isSameMinute(date, nextDate)) {
            let formattedDate: string;

            if (isToday(date))
              formattedDate = `Today ${format(date, "h:mm a")}`;
            else if (isYesterday(date))
              formattedDate = `Yesterday ${format(date, "h:mm a")}`;
            else if (
              isWithinInterval(date, {
                start: subDays(startOfToday(), 6),
                end: startOfToday(),
              })
            )
              formattedDate = format(date, "eeee, h:mm a");
            else if (!isThisYear(date))
              formattedDate = format(date, "MMM d, yyyy, h:mm a");
            else formattedDate = format(date, "E, MMM d, h:mm a");

            render.push(
              <Body
                key={`${message.id}_timestamp`}
                className={styles.timeStamp}
                size="xs"
                color="secondary"
              >
                {formattedDate}
              </Body>,
            );
          }

          if (message.resource) {
            render.push(
              <ResourceCard
                className={
                  author === `provider_${user?.sub}`
                    ? styles.sent
                    : styles.recieved
                }
                title={message.resource.title}
                photo={message.resource.imageUrl}
                caption={message.resource.domain} //@ts-ignore
                onClick={() => handleResourceClick(message.resource)}
                isVideo={message.resource.type === "video"}
                key={message.id}
              />,
            );
          } else
            render.push(
              <ChatBubble
                key={message.id}
                className={
                  author === `provider_${user?.sub}`
                    ? styles.sent
                    : styles.recieved
                }
                message={{ content: body }}
                type={author === `provider_${user?.sub}` ? "sent" : "recieved"}
              />,
            );

          if (
            author !== `provider_${user?.sub}` &&
            author !== messages[i - 1]?.author
          ) {
            const sender = participants.filter((participant) => {
              return author.endsWith(participant.id);
            })[0];
            if (sender)
              render.push(
                <ChatSender key={`${message.id}_sender`} user={sender} />,
              );
          }

          render.push(
            <div
              key={`${message.id}_separator`}
              style={{
                flexShrink: 0,
                height: author === messages[i - 1]?.author ? 4 : 16,
              }}
            />,
          );
          return render;
        })
        .reverse()
        .flat(),
    [messages],
  );

  return (
    <div className={styles.chatThreadContainer} ref={scrollerRef}>
      <div className={styles.chatThread}>
        {!loading ? renderedChatThread : <Spinner size={64} />}
      </div>
    </div>
  );
};
