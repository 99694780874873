import _ from "lodash";
import { Books, MagnifyingGlass, Plus } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { Button } from "../../../components/button/Button";
import { useMemoryRouter } from "../../../components/drawer/Drawer";
import { InteractiveCard } from "../../../components/interactiveCard/InteractiveCard";
import { NavigationTabs } from "../../../components/navigationTabs/NavigationTabs";
import { Select } from "../../../components/select/Select";
import { Spinner } from "../../../components/spinner/Spinner";
import { TextInput } from "../../../components/textInput/TextInput";
import { useResources } from "../../../lib/hooks/useResources";
import { Resource } from "../../../lib/interfaces/resource";
import { Option } from "../../../lib/interfaces/input";
import styles from "./style.module.css";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { Body } from "../../../components/typography/body/Body";

interface ResourcesFilterOption extends Option {
  value: "everything" | "article" | "video" | "recipe";
}
interface ResourcesSortOption extends Option {
  value: "title" | "author" | "type";
}

const filterOptions: ResourcesFilterOption[] = [
  { name: "Everything", value: "everything" },
  { name: "Article", value: "article" },
  { name: "Video", value: "video" },
  { name: "Recipe", value: "recipe" },
];

const sortByOptions: ResourcesSortOption[] = [
  { name: "Title", value: "title" },
  { name: "Author", value: "author" },
  { name: "Type", value: "type" },
];

export interface ResourcesSelectionListProps {
  defaultResources: string[];
  preSelectedResources?: Resource[];
  onSubmit: (resource: Resource[]) => void;
}

export const ResourcesSelectionList = ({
  defaultResources,
  preSelectedResources,
  onSubmit,
}: ResourcesSelectionListProps) => {
  const [selectedResources, setSelectedResources] = useState<Resource[]>([]);
  const [allResources, setAllResources] = useState<Resource[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState<ResourcesFilterOption>(filterOptions[0]);
  const [sortBy, setSortBy] = useState<ResourcesSortOption>(sortByOptions[0]);
  const [loading, setLoading] = useState(true);
  const { getResources } = useResources();

  const { pushRoute } = useMemoryRouter();

  const selectedResourceIds = useMemo(
    () => selectedResources.map((resource) => String(resource.id)),
    [selectedResources],
  );

  const expandContent = (resource: Resource) =>
    pushRoute("resource", { props: { resource } });
  const handleFilterSelect = (value: string) => {
    const filterOption = _.find(
      filterOptions,
      (option) => option.value === value,
    );
    if (filterOption) setFilter(filterOption);
  };
  useEffect(() => {
    getResources().then((resources: Resource[]) => {
      setAllResources(resources);
      // setSelectedResources(resources.filter(resource => defaultResources.includes(resource.id)))
    });
    preSelectedResources && setSelectedResources(preSelectedResources);
  }, []);

  useEffect(() => () => setLoading(false), [allResources]);

  const resourcesChanged = useMemo(
    () => !_.isEqual(selectedResources, defaultResources),
    [selectedResources],
  );

  const escapeRegExp = (str: string) => {
    return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const displayedResources: Resource[] = useMemo(() => {
    let displayedResources = allResources;
    if (searchTerm.length > 0) {
      const searchTermEscapeRegex = escapeRegExp(searchTerm);
      const searchTermRegex = new RegExp(searchTermEscapeRegex, "i");
      displayedResources = displayedResources.filter(
        (resource) =>
          resource.title.match(searchTermRegex) ||
          resource.tags.join("").match(searchTermRegex),
      );
    }
    if (filter.value !== "everything") {
      displayedResources = displayedResources.filter(
        (resource) => resource.type === filter.value,
      );
    }

    return _.orderBy(
      displayedResources,
      [(resource) => resource[sortBy.value].toLowerCase()],
      ["asc"],
    );
  }, [allResources, searchTerm, filter, sortBy]);

  const handleResourceSelect = (resource: Resource, selected: boolean) => {
    if (selected) {
      setSelectedResources([...selectedResources, resource]);
    } else {
      setSelectedResources(
        selectedResources.filter((id) => !_.isEqual(id.id, resource.id)),
      ); //id !== resource
    }
  };

  const newlySelectedResources = useMemo(
    () =>
      _.filter(
        selectedResources,
        _.negate((resource) => defaultResources.includes(resource.id)),
      ).length,
    [selectedResources, defaultResources],
  );

  const handleSaveResources = () => onSubmit(selectedResources);

  return (
    <div className={styles.resourcesSelectionContainer}>
      <div className={styles.resourcesSelectionHeader}>
        <div className={styles.searchSortContainer}>
          <TextInput
            LeftIcon={MagnifyingGlass}
            placeholder="Search recources"
            value={searchTerm}
            onChange={setSearchTerm}
            clearable={true}
          />
          <Select
            displayedValue={`Sorted by: ${sortBy.name}`}
            value={sortBy}
            options={sortByOptions}
            onChange={setSortBy}
            width={374}
          />
          {/* <Button label={`Add Resources${resourcesChanged && newlySelectedResources > 0 ? ` (${newlySelectedResources})` : ''}`} Icon={Plus} disabled={newlySelectedResources === 0} onClick={handleSaveResources} /> */}
          <Button
            label={`Save Resources`}
            disabled={
              selectedResources.length === 0 &&
              preSelectedResources?.length === 0
            }
            onClick={handleSaveResources}
          />
        </div>
        <div className={styles.filterContainer}>
          <NavigationTabs
            routes={filterOptions}
            value={filter.value}
            onChange={handleFilterSelect}
          />
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : displayedResources.length > 0 ? (
        <div className={styles.resourcesSelectionList}>
          <div className={styles.bufferSpace} />
          {displayedResources.map((resource) => (
            <InteractiveCard
              key={resource.id}
              className={styles.interactiveCard}
              image={resource.image}
              title={resource.title}
              caption={resource.author}
              content={resource.previewDescription}
              type={resource.type}
              checked={selectedResourceIds.includes(String(resource.id))}
              shared={defaultResources.includes(String(resource.id))}
              tags={resource.tags.map((tag) => ({ content: tag }))}
              onClick={(selected: boolean) =>
                handleResourceSelect(resource, selected)
              }
              onContentClick={() => expandContent(resource)}
            />
          ))}
        </div>
      ) : (
        <div className={styles.noResults}>
          <div className={styles.noResultsIcon}>
            <FeaturedIcon Icon={Books} type="gray" />
          </div>
          <Body color="secondary">We couldn't find any results</Body>
        </div>
      )}
      <div className={styles.bufferSpace} />
    </div>
  );
};
