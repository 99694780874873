import axios, { AxiosResponse } from "axios";

export type Subscription = {
  status: string;
  created: number;
};

interface SubscriptionResponse extends AxiosResponse {
  data: Subscription;
}

type FetchSubscriptionsParams = {
  userId: string;
};

type PostSubscriptionsParams = {
  userId: string;
  userRole: "patient" | "provider" | "regarding";
};

export const fetchSubscription = async (params: FetchSubscriptionsParams) => {
  const result = await axios
    .get<SubscriptionResponse, SubscriptionResponse>("/subscription", {
      params,
    })
    .catch((err) => err);

  return result.data as Subscription;
};

export const postUpgradeToPremium = async (params: PostSubscriptionsParams) => {
  const result = await axios
    .post("/subscription/upgrade", {
      ...params,
    })
    .catch((err) => {
      throw err;
    });

  return result.data;
};
