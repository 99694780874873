import {
  PrimaryButton,
  SecondaryButton,
} from "amazon-chime-sdk-component-library-react";
import styled from "styled-components";

const buttonStyles = `
  border-radius: 0.5rem;
  font-family: var(--font-family-primary);
  font-weight: 700;
  margin: 0 0.5rem;
`;
export const StyledPrimaryButton = styled(PrimaryButton)`
  ${buttonStyles}
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  ${buttonStyles}
`;
