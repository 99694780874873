import { useState } from "react";
import {
  SpeakerSelection,
  SecondaryButton,
  useAudioOutputs,
} from "amazon-chime-sdk-component-library-react";

import { SpeakerIcon } from "../../../../../components/customIcons/CustomIcons";
import { useAudioInputAndOutputOptions } from "../../../hooks/useAudioInputAndOutputOptions";
import TestSound from "../../../utils/TestSound";

import { DeviceSelectionHeader } from "../DeviceSelectionHeader";
import { StyledSpeakerSelection } from "../Styled";

const SpeakerDevices = () => {
  const { selectedDevice } = useAudioOutputs();
  const [selectedOutput, setSelectedOutput] = useState(selectedDevice);
  const { disabled } = useAudioInputAndOutputOptions();

  const handleChange = (deviceId: string): void => {
    setSelectedOutput(deviceId);
  };

  const handleTestSpeaker = () => {
    new TestSound(selectedOutput);
  };

  return (
    <div>
      <DeviceSelectionHeader Icon={SpeakerIcon} text="Speaker settings" />
      <StyledSpeakerSelection
        onChange={handleChange}
        label="Select Device"
        $disabled={disabled}
      />
      <SecondaryButton label="Test speakers" onClick={handleTestSpeaker} />
    </div>
  );
};

export default SpeakerDevices;
