import {
  useState,
  useEffect,
  useMemo,
  MutableRefObject,
  useContext,
} from "react";
import { format, addDays, getDay, isToday, isPast } from "date-fns";
import { useInView } from "react-intersection-observer";
import { useVisits } from "../../../lib/hooks/useVisits";
import { Visit } from "../../../lib/interfaces/visits";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { Heading } from "../../../components/typography/heading/Heading";
import { Body } from "../../../components/typography/body/Body";
import { FunnelSimple } from "phosphor-react";
import { SearchOption } from "../../../lib/interfaces/listOfPatients";
import {
  defaultPatient,
  defaultProvider,
  ProviderInfo,
} from "../../../lib/interfaces/user";
import { Spinner } from "../../../components/spinner/Spinner";
import { ScheduleAppointmentCard } from "../../../components/scheduleAppointmentCard/ScheduleAppointmentCard";
import { Drawer } from "../../../components/drawer/Drawer";
import { AppointmentDetail } from "../appointmentDetail/AppointmentDetail";
import _ from "lodash";
import clsx from "clsx";
import styles from "./style.module.css";
import { AlertContext, BaseContext } from "../../../lib/context/context";
import { NotificationType } from "../../../components/alertBanner/AlertBanner";
import { ProviderType } from "../../../lib/apis/types/provider.types";
import { AppointmentType } from "../../../lib/apis/types/visitReason.types";

const weekDays = [1, 2, 3, 4, 5, 6];

interface WeekSchedule {
  [day: number]: Visit[];
}

export interface AppointmentListProps {
  signedInUser: ProviderInfo;
  actionRef?: MutableRefObject<any>;
  appointmentList?: Object;
  weekDisplayed: Date;
  resetLoading: boolean;
  searchOption: SearchOption;
  assignedToMe: boolean;
  apptWithMe: boolean;
}

export const AppointmentList = ({
  actionRef,
  signedInUser,
  weekDisplayed,
  searchOption,
  assignedToMe,
  apptWithMe,
}: AppointmentListProps) => {
  const { pushAlert } = useContext(AlertContext);
  const { allProviders, userInfo } = useContext(BaseContext);
  const { getAllVisits, getAppointmentInfoFromVisit, isPreVisitCompleted } =
    useVisits();
  const { updateCareStatus } = useVisits();
  const [loading, setLoading] = useState<boolean>(true);
  const [schedule, setSchedule] = useState<WeekSchedule>();
  const [expandedVisit, setExpandedVisit] = useState<Visit>();
  const [appointmentDetailVisible, setAppointmentDetailVisible] =
    useState(false);
  const [todayRef] = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (actionRef) actionRef.current = updateSchedule;
  }, []);

  useEffect(() => {
    if (!loading)
      document.getElementById("today")?.scrollIntoView({ behavior: "smooth" });
  }, [loading]);

  const filterByPatient = (visit: Visit): boolean => {
    if (searchOption?.patient?.value) {
      return searchOption.patient.value === visit.userId;
    } else return true;
  };

  const filterByAssignedToMe = (visit: Visit): boolean => {
    if (assignedToMe) {
      return signedInUser.id === visit.checkingInWith;
    } else return true;
  };

  const filterByApptWithMe = (appt: Visit): boolean => {
    if (apptWithMe) {
      return signedInUser.id === appt.providerId;
    } else return true;
  };

  const getProvider = (id: string) => {
    const provider = _.find(allProviders, (provider) => {
      return provider.id === id;
    });
    if (provider) return provider;
    else return defaultProvider;
  };

  useEffect(() => {
    if (userInfo) {
      updateSchedule();
    }
  }, [weekDisplayed, userInfo]);

  const updateSchedule = (updWeekDisplayed?: Date) => {
    setLoading(true);
    const startDate =
      format(updWeekDisplayed || weekDisplayed, "yyyy-MM-dd") + "T00:00:00";
    const endDate =
      format(addDays(updWeekDisplayed || weekDisplayed, 6), "yyyy-MM-dd") +
      "T00:00:00";

    let providerId = "";

    if (userInfo?.providerType === ProviderType.OutsideSpecialist) {
      providerId = userInfo.id;
    }

    getAllVisits(startDate, endDate, (providerId as string) ?? null).then(
      (res) => {
        const sortedVisits = _.sortBy(
          res,
          (appointment) => appointment.dateTime,
        );
        const groupedVisits = _.groupBy(sortedVisits, (appointment) =>
          getDay(new Date(appointment.dateTime)),
        );
        setSchedule(groupedVisits);
      },
    );
  };

  const filteredSchedule = useMemo(() => {
    if (schedule) {
      setLoading(false);
      return weekDays.map((day) => {
        if (!schedule[day]) return [];
        else
          return schedule[day]
            .filter(filterByPatient)
            .filter(filterByApptWithMe)
            .filter(filterByAssignedToMe);
      });
    } else return undefined;
  }, [schedule, searchOption, assignedToMe, apptWithMe]);

  useEffect(() => {
    if (expandedVisit) setAppointmentDetailVisible(true);
  }, [expandedVisit]);

  const isFilteredScheduleEmpty = useMemo(() => {
    if (filteredSchedule && (apptWithMe || assignedToMe)) {
      for (let i = 0; i < weekDays.length; i++) {
        const day = weekDays[i];
        if (filteredSchedule[day - 1].length > 0) return false;
      }
      return true;
    } else return false;
  }, [filteredSchedule]);

  const weekDates = useMemo(() => {
    const _weekDates: { [day: number]: string } = {};

    weekDays.forEach((day) => {
      const date = addDays(weekDisplayed, day - 1);
      if (!isToday(date))
        _weekDates[day] = format(date, "EEEE, MMM d y").toUpperCase();
      else
        _weekDates[day] = (
          "Today, " + format(date, "EEEE, MMM d y")
        ).toUpperCase();
    });
    return _weekDates;
  }, [weekDisplayed]);

  const onDrawerClose = () => {
    setAppointmentDetailVisible(false);
    setExpandedVisit(undefined);
  };

  const handleAppointmentCancelled = () => {
    setAppointmentDetailVisible(false);
    pushAlert("Appointment successfuly cancelled.", "success");
    updateSchedule();
  };

  const handleAppointmentUpdated = (
    close: boolean,
    alertMessage?: string,
    alertType?: NotificationType,
  ) => {
    if (close) setAppointmentDetailVisible(false);
    if (alertMessage) pushAlert(alertMessage, alertType);
    updateSchedule();
  };

  const handleChangeCareCoordinator = async (
    visitId: string,
    currentCareCoordinatorId?: string,
  ) => {
    const result = await updateCareStatus(
      visitId,
      undefined,
      currentCareCoordinatorId === signedInUser.id
        ? "default"
        : signedInUser.id,
    );
    if (result) {
      const updatedAppointment = await getAppointmentInfoFromVisit(result);
      if (updatedAppointment.careCoordinator)
        return {
          name: updatedAppointment.careCoordinator.name,
          id: updatedAppointment.careCoordinator.id,
        };
      else return undefined;
    } else return undefined;
  };
  return (
    <div>
      {isFilteredScheduleEmpty && !assignedToMe ? (
        <div className={styles.emptyState}>
          <FeaturedIcon Icon={FunnelSimple} type="gray" />
          <Heading className={styles.heading} type="02">
            No results
          </Heading>
          <Body color="secondary">Adjust your filter for more results</Body>
        </div>
      ) : (
        <div>
          {weekDays.map((day: number) => (
            <div
              key={day}
              className={styles.apptList}
              id={
                weekDates[day]?.startsWith("TODAY") ? "today" : weekDates[day]
              }
              ref={todayRef}
            >
              <Body
                size="md"
                weight="bold"
                style={{ padding: "9px 0px 9px 32px" }}
                className={clsx(
                  styles.dayTitle,
                  weekDates[day]?.startsWith("TODAY")
                    ? styles.todayText
                    : styles.notToday,
                )}
              >
                {weekDates[day]}
              </Body>

              <div className={styles.apptCards}>
                {!loading && filteredSchedule ? (
                  filteredSchedule[day - 1].length < 1 ? (
                    <div className={styles.noApptText}>
                      <Body size="md" color="secondary">
                        {"No Appointments"}
                      </Body>
                    </div>
                  ) : (
                    filteredSchedule[day - 1].map((appointment) => (
                      <div className={styles.section} key={appointment.visitId}>
                        <ScheduleAppointmentCard
                          time={format(new Date(appointment.dateTime), "p")}
                          provider={getProvider(appointment.providerId)}
                          appointmentType={appointment.appointmentType || ""}
                          visitType={appointment.visitDisplayTitle ?? ""}
                          id={appointment.visitId ?? ""}
                          user={signedInUser}
                          key={appointment.visitId}
                          patient={appointment.patient}
                          atmanMember={false}
                          checkedIn={appointment?.checkedIn || false}
                          careCoordinator={getProvider(
                            appointment.checkingInWith || "",
                          )}
                          careStatusNeeded={
                            appointment.appointmentType ===
                              AppointmentType.Virtual &&
                            typeof appointment.careGiven !== "boolean" &&
                            isPast(new Date(appointment.dateTime)) &&
                            isToday(new Date(appointment.dateTime))
                          }
                          preVisitInfoComplete={isPreVisitCompleted(
                            appointment,
                          )}
                          onAppointmentClick={() => {
                            setExpandedVisit(appointment);
                          }}
                          onCareCoordinatorClick={handleChangeCareCoordinator}
                        />
                      </div>
                    ))
                  )
                ) : (
                  <Spinner top={0} />
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <Drawer
        visible={appointmentDetailVisible}
        onClose={onDrawerClose}
        title="Appointment Detail"
      >
        <div style={{ overflow: "auto", maxHeight: "100%" }}>
          {expandedVisit ? (
            <AppointmentDetail
              onAppointmentUpdated={handleAppointmentUpdated}
              onCancelAppointment={handleAppointmentCancelled}
              patient={expandedVisit.patient || defaultPatient}
              visitId={expandedVisit.visitId || ""}
              careGivenStatus={expandedVisit.careGiven}
              reason="reason"
            />
          ) : (
            <></>
          )}
        </div>
      </Drawer>
    </div>
  );
};
