import clsx from "clsx";
import styles from "./style.module.css";

interface SearchSelectLabelProps {
  headingAlignment?: "left" | "center" | "right";
  size?: number | string;
  headingText: string | undefined;
}

export const SearchSelectLabel = ({
  headingAlignment = "left",
  size = "40px",
  headingText,
}: SearchSelectLabelProps) => {
  const headingStyle = {
    textAlign: headingAlignment,
    fontSize: size,
  };
  return (
    <div className={clsx(styles.heading)} style={headingStyle}>
      <label className={clsx(styles.label)}>{headingText}</label>
    </div>
  );
};
