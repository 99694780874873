import { Button, ButtonGroup } from "../../../components/button/Button";
import { Heading } from "../../../components/typography/heading/Heading";
import {
  FunnelSimple,
  MagnifyingGlass,
  NotePencil,
  ArrowsClockwise,
} from "phosphor-react";

import styles from "./style.module.css";
import { Body } from "../../../components/typography/body/Body";
import { MutableRefObject } from "react";

export interface ChatListHeaderProps {
  onFiltersButtonClick: () => void;
  onNewChatButtonClick: () => void;
  numFilters: number;
  refreshChatList: MutableRefObject<any>;
  isLoading: boolean;
  handleRefreshClick: (data: boolean) => void;
}

export const ChatListHeader = ({
  onFiltersButtonClick,
  onNewChatButtonClick,
  numFilters,
  refreshChatList,
  isLoading,
  handleRefreshClick,
}: ChatListHeaderProps) => {
  const handleRefreshBtn = () => {
    const { refetch } = refreshChatList.current;
    handleRefreshClick(true);
    refetch();
  };
  return (
    <div className={styles.chatListHeader}>
      <Heading type="01">{"Messages"}</Heading>
      <ButtonGroup>
        <div className={styles.filter}>
          <Button
            onClick={onFiltersButtonClick}
            type="tertiary"
            size="small"
            Icon={FunnelSimple}
          />
          {numFilters > 0 && (
            <Body weight="bold" color="link">
              ({numFilters})
            </Body>
          )}
        </div>
        {/* <Button onClick={() => { }} type='tertiary' size='small' Icon={MagnifyingGlass} /> */}
        <Button
          onClick={onNewChatButtonClick}
          type="tertiary"
          size="small"
          Icon={NotePencil}
        />
        <Button
          loading={isLoading}
          onClick={handleRefreshBtn}
          type="tertiary"
          size="small"
          Icon={ArrowsClockwise}
        />
      </ButtonGroup>
    </div>
  );
};
