import { useState, useEffect } from "react";
import { CalendarPlus } from "phosphor-react";
import { Button } from "../../../components/button/Button";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import {
  Hours,
  OverridenDate,
} from "../../../lib/interfaces/availbilityManagement";
import { ListDateOverrides } from "../listDateOverrides/ListDateOverrides";
import { AddDateOverrides } from "../addDateOverrides/AddDateOverrides";
import {
  FormattedAvailability,
  useAvailabilityManagement,
} from "../../../lib/hooks/useAvailabilityManagement";
import {
  AvailabilityPayload,
  AvailabilityExceptionItem,
  DeletePayload,
} from "../../../lib/apis/availabilityManagement";
import { Spinner } from "../../../components/spinner/Spinner";
import styles from "./style.module.css";
import _ from "lodash";

export interface DateOverridesProps {
  userId: string;
  onSave: () => void;
  availability: FormattedAvailability | undefined;
  loading: boolean;
}

export const DateOverrides = ({
  userId,
  onSave,
  availability,
  loading,
}: DateOverridesProps) => {
  const { updateDateOverrides, removeDateOverrides } =
    useAvailabilityManagement();

  const [editMode, setEditMode] = useState(false);
  const [dates, setDates] = useState<OverridenDate[]>([]);

  useEffect(() => {
    if (availability) {
      setDates(availability.dateOverrides);
    }
  }, [availability]);

  //used to add or update date overrides in the backend
  const addDateOverrides = (userId: string, newDates: OverridenDate[]) => {
    const availability: AvailabilityExceptionItem[] = newDates.map((item) => {
      //if the date override has available hours
      if (item.hours) {
        const data: AvailabilityExceptionItem = {
          date: item.date,
          available: true,
          times: item.hours,
        };
        return data;
      }
      //if the date override is marked as unavailable
      else {
        const data: AvailabilityExceptionItem = {
          date: item.date,
          available: false,
          times: [],
        };
        return data;
      }
    });
    const payload: AvailabilityPayload = {
      availabilityMaster: {
        providerId: userId,
        availability: [], //we don't need to update the availabilityMaster
      },
      availabilityExceptions: {
        providerId: userId,
        availability: availability, //we only need to update the availabilityExceptions
      },
    };
    updateDateOverrides(userId, payload);
    //onSave is used to update the last update section on the availability management page
    onSave();
  };

  //used to add or delete date overrides in the backend
  const deleteDateOverride = (
    userId: string,
    targetDate: string,
    targetHourIndex: number,
  ) => {
    dates.map((item) => {
      //if the date override includes one or more set of available hours
      if (item.date === targetDate && item.hours) {
        const startHour = item.hours[targetHourIndex].from;
        const payload: DeletePayload = {
          providerId: userId,
          date: targetDate,
          from: startHour,
        };
        removeDateOverrides(userId, payload);
      }
      //if the date override is marked as unavailble (it doesn't have any hours)
      else if (item.date === targetDate && !item.hours) {
        const payload: DeletePayload = {
          providerId: userId,
          date: targetDate,
        };
        removeDateOverrides(userId, payload);
      }
    });
    onSave();
  };

  const handleOnClick = () => {
    setEditMode(true);
  };

  const handleOnSave = (date: string, hours: Hours[], unavailable: boolean) => {
    let updatedDates: OverridenDate[] = JSON.parse(JSON.stringify(dates));
    const dateExists = _.find(updatedDates, (overridenDate) => {
      return overridenDate.date === date;
    });
    if (dateExists) {
      updatedDates = updatedDates.map((overridenDate) =>
        overridenDate.date === date
          ? !unavailable
            ? { date: date, hours: hours }
            : { date: date }
          : overridenDate,
      );
    } else {
      updatedDates = [
        ...updatedDates,
        !unavailable ? { date: date, hours: hours } : { date: date },
      ];
    }
    setDates(updatedDates);
    //add or update the date override to the backend
    addDateOverrides(userId, updatedDates);
    setEditMode(false);
  };

  const handleOnCancel = () => {
    setEditMode(false);
  };

  const handleOnDelete = (date: string, index: number) => {
    let newDates = JSON.parse(JSON.stringify(dates));
    newDates.forEach((item: OverridenDate) => {
      //if the date override has multiple sets of available hours and we just want to delete one of those sets of available hours
      if (item.date === date && item.hours && item.hours.length > 1) {
        item.hours.splice(index, 1);
      }
      //if the date override is marked as unavailable or it only has a single set of available hours, remove that date override completely
      else if (item.date === date && (!item.hours || item.hours.length === 1)) {
        newDates = newDates.filter((item: OverridenDate) => item.date != date);
      }
    });
    setDates(newDates);
    //update the date overrides in the backend
    deleteDateOverride(userId, date, index);
  };

  return (
    <div className={styles.dateOverridesContent}>
      <Heading className={styles.dateOverridesHeading} type="03">
        {"Add Date Overrides"}
      </Heading>
      <Body
        className={styles.dateOverridesDescription}
        size="sm"
        weight="regular"
        color="secondary"
      >
        {"Set availability for specific days by adding date overrides."}
      </Body>
      {!editMode ? (
        <div>
          <Button
            onClick={() => {
              handleOnClick();
            }}
            type="secondary-gray"
            size="small"
            Icon={CalendarPlus}
            label="Add Date Overrides"
            className={styles.addButton}
          />
          {!loading ? (
            <ListDateOverrides
              editMode={editMode}
              dates={dates}
              handleOnDelete={handleOnDelete}
            />
          ) : (
            <Spinner />
          )}
        </div>
      ) : (
        <AddDateOverrides
          handleOnCancel={handleOnCancel}
          handleOnSave={handleOnSave}
        />
      )}
    </div>
  );
};
