import styled from "styled-components";

export const StyledSidebar = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  background-color: var(--color-primary-600);
  height: 100%;
  width: 96px;
  max-width: 96px;
  padding: 24px 20px;
  box-sizing: border-box;
`;

export const StyledLogoContainer = styled.div`
  height: 56px;
  width: 56px;
  border-radius: var(--border-radius-pill);
`;

export const StyledNavButtonContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;
  gap: 20px;
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const StyledVersionParagraph = styled.p`
  background-color: white;
  padding: 4px 8px;
  border-radius: 8px;
`;

export const StyledSeparator = styled.div`
  flex-grow: 1;
`;
