import axios, { AxiosResponse } from "axios";
import { Provider } from "./types/provider.types";

export type ResponseData = Provider;

interface AllProvidersResponse extends AxiosResponse {
  data: Provider[];
}

export const fetchAll = async (): Promise<Provider[]> => {
  const { data } = await axios
    .get<AllProvidersResponse, AllProvidersResponse>("/")
    .catch((err) => err);
  return data.providers || [];
};

export const fetch = async (providerId: string) => {
  const { data } = await axios
    .get<AllProvidersResponse, AllProvidersResponse>("", {
      params: {
        providerIds: [providerId],
      },
    })
    .catch((err) => err);

  return data?.providers[0];
};

export default {
  fetchAll,
  fetch,
};
