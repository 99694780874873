import { useLocalVideo } from "amazon-chime-sdk-component-library-react";
import { useEffect } from "react";

import {
  VideoIcon,
  VideoDisabledIcon,
} from "../../../../../components/customIcons/CustomIcons";
import { useVideoInputOptions } from "../../../hooks/useVideoInputOptions";
import { DeviceSelectionHeader } from "../DeviceSelectionHeader";
import {
  StyledInputGroup,
  StyledPreviewVideo,
  StyledCameraSelection,
} from "../Styled";

const CameraDevices = () => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { disabled } = useVideoInputOptions();

  useEffect(() => {
    if (!isVideoEnabled) toggleVideo();
  }, []);

  return (
    <div>
      <StyledPreviewVideo />
      <DeviceSelectionHeader
        Icon={!isVideoEnabled || disabled ? VideoDisabledIcon : VideoIcon}
        text={`Join with${!isVideoEnabled || disabled ? "out" : ""} Video`}
        active={isVideoEnabled && !disabled}
        onToggle={toggleVideo}
        disabled={disabled}
      />
      <StyledInputGroup>
        <StyledCameraSelection
          label="Select camera"
          $disabled={!isVideoEnabled || disabled}
        />
      </StyledInputGroup>
    </div>
  );
};

export default CameraDevices;
