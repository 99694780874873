import { PropsWithChildren } from "react";
import styled from "styled-components";

interface IMenuButtonProps {
  $active: boolean | undefined;
  $badge: boolean | undefined;
}

const StyledBase = styled.a<IMenuButtonProps>`
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  box-sizing: border-box;
  ${(props) =>
    props.$badge &&
    `
      ::after {
        content: '';
        position: absolute;
        top: 10px;
        right: 10px;
        width: 8px;
        height: 8px;
        border: 1px solid var(--color-white);
        border-radius: var(--border-radius-pill);
        background-color: var(--color-red-600);
      }
  `}
`;
export const StyledNavAvatar = StyledBase;

// TODO https://github.com/microsoft/TypeScript/issues/37597
// issue producing DefaultProps error
// @ts-ignore
export const StyledNavButton = styled(StyledBase)`
  display: block;
  position: relative;
  background-color: transparent;
  padding: 10px;

  :hover {
    background-color: var(--color-primary-500);
  }
  ${(props) =>
    props.$active &&
    `
        background-color: var(--color-primary-400);
   `}
`;
