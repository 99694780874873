import { Heading } from "../../../components/typography/heading/Heading";
import { Table } from "../../../components/table/Table";
import { CellProps } from "../../../components/table/Table";
import { Insurance } from "../../../lib/interfaces/patientInfo";
import styles from "./style.module.css";

export interface InsuranceInformationProps {
  coverage: Insurance[];
}

export const InsuranceInformation = ({
  coverage,
}: InsuranceInformationProps) => {
  const columns = ["Insurance Provider", "Membership ID"];

  const getInsuranceData = () => {
    const data: CellProps[][] = [];
    coverage.map((provider) => {
      const row: CellProps[] = [];
      row.push(provider.payor.name);
      row.push(provider.subscriber.id);
      data.push(row);
    });
    return data;
  };

  return (
    <div>
      {/* <div className={(upcomingVisits.length === 0 && pastVisits.length === 0 ? styles.borderBottom : "")}> */}
      <Heading type="02" className={styles.header}>
        Insurance Information
      </Heading>
      <div>
        <Table
          friendlyTitle="insurance information"
          data={getInsuranceData()}
          columns={columns}
        />
      </div>
    </div>
  );
};
