import _ from "lodash";
import { v4 as uuid } from "uuid";
import { useRef, useState } from "react";
import { NotificationType } from "../../components/alertBanner/AlertBanner";

export interface Alert {
  id: string;
  message: string;
  type?: NotificationType;
  closeable?: boolean;
  withIcon?: boolean;
}

export const useAlerts = () => {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const alertsRef = useRef<Alert[]>(alerts);

  const pushAlert = (
    message: string,
    type?: NotificationType,
    closeable?: boolean,
    withIcon?: boolean,
    closeAfter?: number,
  ) => {
    const duplicateAlerts = _.find(alertsRef.current, (alert) => {
      return alert.message === message && alert.type === type;
    });
    //if the alert isn't a duplicate
    if (!duplicateAlerts) {
      const id = uuid();
      alertsRef.current = alertsRef.current.concat({
        id,
        message,
        type,
        closeable,
        withIcon,
      });
      setAlerts(alertsRef.current);
      if (closeAfter)
        setTimeout(() => {
          closeAlert(id);
        }, closeAfter);
    }
  };

  const closeAlert = (id: string) => {
    alertsRef.current = alertsRef.current.filter((alert) => alert.id !== id);
    setAlerts(alertsRef.current);
  };

  const clearAlerts = () => {
    alertsRef.current = [];
    setAlerts([]);
  };

  return { alerts, pushAlert, closeAlert, clearAlerts, setAlerts };
};
