import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Pencil } from "phosphor-react";
import { Button } from "../../../components/button/Button";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { EditWeeklyHours } from "../editWeeklyHours/EditWeeklyHours";
import {
  WeeklyHoursArray,
  Checked,
  defaultChecked,
  defaultWeeklyHours,
} from "../../../lib/interfaces/availbilityManagement";
import {
  useAvailabilityManagement,
  FormattedAvailability,
} from "../../../lib/hooks/useAvailabilityManagement";
import { AvailabilityPayload } from "../../../lib/apis/availabilityManagement";
import { Spinner } from "../../../components/spinner/Spinner";
import clsx from "clsx";
import styles from "./style.module.css";

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const weekdayAbbreviations = ["MON", "TUE", "WED", "THU", "FRI", "SAT"];

export interface WeeklyHoursProps {
  onSave: () => void;
  userId: string;
  availability: FormattedAvailability | undefined;
  loading: boolean;
}

export const WeeklyHours = ({
  onSave,
  userId,
  availability,
  loading,
}: WeeklyHoursProps) => {
  const { updateWeeklyHours } = useAvailabilityManagement();

  const [editMode, setEditMode] = useState(false);
  const [checked, setChecked] = useState<Checked>(
    JSON.parse(JSON.stringify(defaultChecked)),
  );
  const [weeklyHours, setWeeklyHours] = useState<WeeklyHoursArray>(
    JSON.parse(JSON.stringify(defaultWeeklyHours)),
  );

  useEffect(() => {
    if (availability) {
      setWeeklyHours(availability.weeklyHours.weeklyHours);
      setChecked(availability.weeklyHours.checked);
    }
  }, [availability]);

  const saveWeeklyHours = (
    userId: string,
    updWeeklyHours: WeeklyHoursArray,
    updChecked: Checked,
  ) => {
    const payload: AvailabilityPayload = {
      availabilityMaster: {
        providerId: userId,
        availability: [
          {
            dayOfWeek: 1,
            available: updChecked["MON"],
            times: updWeeklyHours["MON"],
          },
          {
            dayOfWeek: 2,
            available: updChecked["TUE"],
            times: updWeeklyHours["TUE"],
          },
          {
            dayOfWeek: 3,
            available: updChecked["WED"],
            times: updWeeklyHours["WED"],
          },
          {
            dayOfWeek: 4,
            available: updChecked["THU"],
            times: updWeeklyHours["THU"],
          },
          {
            dayOfWeek: 5,
            available: updChecked["FRI"],
            times: updWeeklyHours["FRI"],
          },
          {
            dayOfWeek: 6,
            available: updChecked["SAT"],
            times: updWeeklyHours["SAT"],
          },
        ],
      },
      availabilityExceptions: {
        providerId: userId,
        availability: [],
      },
    };
    updateWeeklyHours(userId, payload);
  };

  const handleOnSave = (
    updWeeklyHours: WeeklyHoursArray,
    updChecked: Checked,
  ) => {
    setWeeklyHours(updWeeklyHours);
    setChecked(updChecked);
    onSave(); //for Last Update section
    saveWeeklyHours(userId, updWeeklyHours, updChecked); //update the backend
    setEditMode(false);
  };

  const handleOnCancel = () => {
    setEditMode(false);
  };

  return (
    <div className={clsx(!loading && styles.weeklyHours)}>
      {!editMode ? (
        <>
          <Heading className={styles.weeklyHoursHeader} type="02">
            {"Weekly hours"}
          </Heading>
          {!loading ? (
            <div>
              {weekdayAbbreviations.map((day, index) => (
                <div key={day}>
                  <div className={styles.weekdayAndHours}>
                    <Body className={styles.weekday} size="md" weight="bold">
                      {weekdays[index]}
                    </Body>
                    {checked[day] === false ? (
                      <Body
                        className={styles.hours}
                        size="md"
                        color="secondary"
                      >
                        {"Unavailable"}
                      </Body>
                    ) : (
                      <Body className={styles.hours} size="md">
                        {weeklyHours[day][0].from.name +
                          " to " +
                          weeklyHours[day][0].to.name}
                      </Body>
                    )}
                  </div>
                  {checked[day] === true &&
                    weeklyHours[day].slice(1).map((hour) => (
                      <Body
                        key={uuidv4()}
                        className={styles.additionalHours}
                        size="md"
                      >
                        {hour.from.name + " to " + hour.to.name}
                      </Body>
                    ))}
                </div>
              ))}
              <Button
                onClick={() => {
                  setEditMode(true);
                }}
                type="primary"
                Icon={Pencil}
                label="Edit Availability"
                className={styles.editButton}
              />
            </div>
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <EditWeeklyHours
          weeklyHours={JSON.parse(JSON.stringify(weeklyHours))}
          checked={JSON.parse(JSON.stringify(checked))}
          onCancel={handleOnCancel}
          onSave={handleOnSave}
        />
      )}
    </div>
  );
};
