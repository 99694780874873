import axios, { AxiosResponse } from "axios";
import { Message } from "../interfaces/messaging";

export interface Member {
  id: string;
  identity: string;
  lastReadMessageIndex: number;
  metadata: any;
  patient?: any;
}

export interface Chat {
  id: string;
  name: string;
  createdDate: string;
  updatedDate: string;
  metadata: any;
  latestMessage: any;
  members: Member[];
}

export interface GetUserChatsArgs {
  userId: string;
  type?: string;
  controller?: AbortController;
  paginationInfo: PaginationInfo;
}

export interface PaginationInfo {
  page: number;
  pageSize: number;
  nextPageToken?: string;
}

export interface FetchAllResponse extends AxiosResponse {
  pageSize: number;
  currentPage: number;
  totalPages: number;
  nextPage: number;
  nextPageToken: string;
  chats: Chat[];
}

export const fetchAllByPage = async (
  args: GetUserChatsArgs,
): Promise<FetchAllResponse> => {
  const newUserId = `${args.type}_${args.userId}`;
  const paginationInfo = args.paginationInfo;
  const messagingUrl = paginationInfo.nextPageToken
    ? `/messaging/chat?${paginationInfo.nextPageToken.split("?")[1]}`
    : "/messaging/chat";
  const params = !paginationInfo.nextPageToken
    ? {
        userId: newUserId,
        pageNumber: paginationInfo.page,
        pageSize: paginationInfo?.pageSize,
      }
    : undefined;

  const result: FetchAllResponse = await axios
    .get<FetchAllResponse, FetchAllResponse>(messagingUrl, {
      params,
      signal: args.controller ? args.controller.signal : undefined,
    })
    .catch((err) => (axios.isCancel(err) ? { data: "cancelled" } : err));

  return result.data;
};

export const fetchOne = async (
  userId: string,
  chatId: string,
  accessToken: string,
  controller?: AbortController,
) => {
  const result = await axios
    .get<FetchAllResponse, FetchAllResponse>(`/messaging/chat`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { userId, chatId },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result.data;
};

export interface FetchResponse extends AxiosResponse {
  data: { messages: Message[] };
}

export const fetch = async (
  chatId: string,
  lastReadIndex?: number,
  controller?: AbortController,
) => {
  const result = await axios
    .get<FetchResponse, FetchResponse>("/messaging/chat-message", {
      params: lastReadIndex
        ? {
            chatId,
            lastReadIndex,
          }
        : {
            chatId,
          },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result.data;
};

export const updateLatestReadMessageIndex = async (
  chatId: string,
  memberId: string,
  messageIndex: number,
) => {
  await axios.put("/messaging/chat-message-last-read", {
    chatId,
    memberId,
    messageIndex,
  });
};

export const sendTokenResponse = (token: any, res: any) => {
  res.set("Content-Type", "application/json");
  res.send(
    JSON.stringify({
      token: token.toJwt(),
    }),
  );
};

export interface CreateResponse extends AxiosResponse {
  data: Chat;
}

export const create = async (chatData: any) => {
  const result = await axios
    .post<CreateResponse, CreateResponse>("/messaging/chat", { ...chatData })
    .catch((err) => err);

  return result;
};

export interface SendReponse extends AxiosResponse {
  // TODO
  data: unknown;
}

export const send = async (chatId: string, userId: string, body: string) => {
  const result = await axios
    .post<SendReponse, SendReponse>("/messaging/chat-message", { chatId, body })
    .catch((err) => err);

  return result;
};

export interface ChatTokenResponse extends AxiosResponse {
  data: { token: string };
}

export const fetchToken = async (tokenType: string, roomName: string) => {
  const params = roomName ? { tokenType, visitId: roomName } : { tokenType };
  const result = await axios
    .get<ChatTokenResponse, ChatTokenResponse>("/messaging/chat-token", {
      params,
    })
    .catch((err) => err);

  return result.data;
};

export default {
  fetchAllByPage: fetchAllByPage,
  fetchOne,
  fetch,
  updateLatestReadMessageIndex,
  fetchToken,
  create,
  send,
};
