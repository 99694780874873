import MeetingRoster from "../../components/MeetingRoster/MeetingRoster";

import { Flex } from "amazon-chime-sdk-component-library-react";
import { useVideoChat } from "../../context/VideoChatContext";

const NavigationControl = () => {
  const { showRoster } = useVideoChat();
  if (!showRoster) {
    return <></>;
  }
  return (
    <Flex layout="stack" style={{ height: "100vh" }}>
      <MeetingRoster />
    </Flex>
  );
};

export default NavigationControl;
