import axios, { AxiosResponse } from "axios";
import { Visit } from "../interfaces/visits";
import {
  CreateVisitRequest,
  RescheduleVisitRequest,
} from "./types/visit.types";

export interface VisitsData {
  future: Visit[];
  past: Visit[];
}
interface ResponseData {
  future: Visit[];
  past: Visit[];
}

interface VisitsResponse extends AxiosResponse {
  data: ResponseData;
}

export const fetchVisits = async (
  userId: string,
  controller?: AbortController,
): Promise<ResponseData> => {
  const result = await axios
    .get<VisitsResponse, VisitsResponse>(`/visit/search?userId=${userId}`, {
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result.data;
};

export const fetchVisitsByProviderId = async (
  startDate: string,
  endDate: string,
  providerId?: string,
) => {
  let url = `/visit/search`;

  if (providerId) {
    url += `?providerId=${providerId}`;

    if (startDate && endDate) {
      url += `&fromDateTime=${startDate}&toDateTime=${endDate}`;
    }
  } else {
    url += `?fromDateTime=${startDate}&toDateTime=${endDate}`;
  }

  const result = await axios
    .get<VisitsResponse, VisitsResponse>(url)
    .catch((err) => err);
  return result.data;
};

export const bookAppointment = async (payload: CreateVisitRequest) => {
  try {
    await axios({
      method: "post",
      url: "/visit",
      data: payload,
    }).catch((err) => {
      throw err;
    });
    return "success";
  } catch (error: any) {
    return "error";
  }
};

export const rescheduleAppointment = async (
  payload: RescheduleVisitRequest,
) => {
  try {
    await axios({
      method: "put",
      url: "/visit/reschedule",
      data: payload,
    }).catch((err) => {
      throw err;
    });
    return "success";
  } catch (error) {
    return "error";
  }
};

export const deleteAppointment = async (
  visitId: string,
  userId: string,
): Promise<AxiosResponse> => {
  const result = await axios
    .put("/visit/cancel", {
      visitId,
      userId,
    })
    .catch((err) => err);

  return result;
};

export const updateAppointment = async (
  visitId: string,
  careGiven?: boolean | "default",
  checkingInWith?: string,
) => {
  const result = await axios
    .put("/visit/update-care-status", { visitId, careGiven, checkingInWith })
    .catch((err) => err);

  return result.data;
};
