import { useMemo } from "react";
import {
  useAudioInputs,
  useAudioOutputs,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";

const useAudioInputAndOutputOptions = () => {
  const { selectedDevice: selectedInputDevice } = useAudioInputs();
  const { selectedDevice: selectedOutputDevice } = useAudioOutputs();
  const meetingManager = useMeetingManager();
  const { audioInputDevices, audioOutputDevices } = meetingManager;

  const inputOptions = useMemo(
    () =>
      audioInputDevices
        ? audioInputDevices
            .filter((device) => device.deviceId !== "")
            .map((device) => {
              return {
                kind: device.kind,
                deviceId: device.deviceId,
                label: device.label,
                checked: selectedInputDevice === device.deviceId,
                handleClick: async () =>
                  await meetingManager.startAudioInputDevice(device.deviceId),
              };
            })
        : [],
    [audioInputDevices, selectedInputDevice]
  );

  const outputOptions = useMemo(
    () =>
      audioOutputDevices
        ? audioOutputDevices
            .filter((device) => device.deviceId !== "")
            .map((device) => {
              return {
                kind: device.kind,
                deviceId: device.deviceId,
                label: device.label,
                checked: selectedOutputDevice === device.deviceId,
                handleClick: async () =>
                  await meetingManager.startAudioOutputDevice(device.deviceId),
              };
            })
        : [],
    [audioOutputDevices, selectedOutputDevice]
  );
  const options = [...inputOptions, ...outputOptions];
  const disabled = options.length === 0;

  return { audioInputDevices, audioOutputDevices, options, disabled };
};

export { useAudioInputAndOutputOptions };
