import clsx from "clsx";
import React, { HTMLInputTypeAttribute, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { IconProps, XCircle } from "phosphor-react";
import { Body } from "../typography/body/Body";
import styles from "./style.module.css";
import { format, isValid } from "date-fns";

interface TextInputProps {
  label?: string;
  placeholder?: string;
  hint?: string;
  LeftIcon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  RightIcon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  onIconClick?: () => void;
  clearable?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  type?: HTMLInputTypeAttribute;
  multiline?: boolean;
  value?: string;
  refObj?: React.RefObject<HTMLInputElement>;
  onChange?: (value: string) => void;
  onKeyPress?: (e: any) => void;
  minRows?: number;
  maxRows?: number;
  minDate?: string;
  maxDate?: string;
  className?: string;
  errorMessage?: string;
}

export const TextInput = ({
  label,
  placeholder,
  hint,
  type = "text",
  LeftIcon,
  RightIcon,
  onIconClick,
  error = false,
  clearable = false,
  disabled = false,
  readOnly = false,
  multiline = false,
  minRows,
  maxRows,
  minDate,
  maxDate,
  value,
  refObj,
  onChange = () => {},
  onKeyPress = () => {},
  className,
  errorMessage,
}: TextInputProps) => {
  const [inputFocused, setInputFocused] = useState(false);
  const _ref = useRef<HTMLInputElement>(null);
  const inputRef = refObj || _ref;
  const datePickerRef = useRef<HTMLInputElement>(null);

  const clearInput = () => onChange("");

  const getFormattedDate = (date: string) => {
    let newDate = new Date(date.split(" ").join("T"));
    if (isValid(newDate)) {
      newDate = new Date(
        newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000,
      );
      return format(newDate, "M/d/yyyy");
    }
  };

  const handleDatePick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = getFormattedDate(e.target.value);
    if (newDate) onChange(newDate);
  };

  return (
    <div className={clsx(styles.textInput, className)}>
      {label && <label className={clsx(styles.label)}> {label} </label>}

      <div
        className={clsx(
          styles.inputContainer,
          inputFocused && styles.focused,
          error && styles.error,
        )}
      >
        {LeftIcon && (
          <div className={clsx(styles.icon, styles.iconLeft)}>
            <LeftIcon size={24} onClick={onIconClick} />
          </div>
        )}

        <div className={styles.inputTextContainer}>
          {!multiline ? (
            <input
              ref={inputRef}
              disabled={disabled}
              readOnly={readOnly}
              className={styles.input}
              placeholder={placeholder}
              type={type === "date" ? "text" : type}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              onKeyPress={(e) => onKeyPress(e)}
              onFocus={() => setInputFocused(true)}
              onBlur={() => {
                setInputFocused(false);
                datePickerRef.current?.blur();
              }}
            />
          ) : (
            <TextareaAutosize
              //@ts-ignore
              ref={inputRef}
              minRows={minRows || 1}
              maxRows={maxRows || 8}
              disabled={disabled}
              className={clsx(styles.input, styles.multiline)}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              onKeyPress={(e) => onKeyPress(e)}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
          )}
          {type === "date" && (
            <input
              ref={datePickerRef}
              className={styles.datePicker}
              type="date"
              min={minDate}
              max={maxDate}
              value={value}
              onChange={handleDatePick}
              disabled={disabled}
            />
          )}
        </div>

        {clearable && value && (
          <button
            onClick={clearInput}
            className={clsx(
              styles.clearButton,
              RightIcon && styles.withRightIcon,
            )}
          >
            <XCircle weight="fill" size={20} />
          </button>
        )}

        {RightIcon && (
          <div
            className={clsx(styles.icon, styles.iconRight)}
            onFocus={(e) => {
              if (type === "date") {
                e.stopPropagation();
                e.preventDefault();
                datePickerRef.current?.focus();
              }
            }}
          >
            <RightIcon size={24} onClick={onIconClick} />
          </div>
        )}
      </div>

      {hint && (
        <Body size="sm" weight="regular" color={error ? "danger" : "secondary"}>
          {hint}
        </Body>
      )}
      {error && errorMessage && (
        <Body size="sm" weight="regular" color={"danger"}>
          {errorMessage}
        </Body>
      )}
    </div>
  );
};
