import styles from "./style.module.css";

export type GeneratedCodeModalProps = {
  code: string;
  patientName: string;
  patientBirthDate: string;
  patientEmail: string;
};

export const GeneratedCodeModal = ({
  code,
  patientName,
  patientBirthDate,
  patientEmail,
}: GeneratedCodeModalProps) => {
  return (
    <div>
      <p>Code expires in 15 minutes</p>
      <div className={styles.generatedCodeDisplay}>{code}</div>
      <div className={styles.generatedModalReminderWrapper}>
        <p>
          Remind the patient to use this exact information below to create the
          account in the Compass App.
        </p>
      </div>
      <div className={styles.generatedModalInfoWrapper}>
        <div>
          <p className={styles.generatedModalInfoItemLabel}>Patient Name</p>
          <p data-dd-privacy="mask">{patientName}</p>
        </div>
        <div>
          <p className={styles.generatedModalInfoItemLabel}>Birth Date</p>
          <p data-dd-privacy="mask">{patientBirthDate}</p>
        </div>
        <div>
          <p className={styles.generatedModalInfoItemLabel}>Email Address</p>
          <p data-dd-privacy="mask">{patientEmail}</p>
        </div>
      </div>
    </div>
  );
};
