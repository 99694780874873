import { useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ChatBottomBar } from "../../../components/chatBottomBar/ChatBottomBar";
import { Drawer } from "../../../components/drawer/Drawer";
import { ResourceCard } from "../../../components/resourceCard/ResourceCard";
import { Resource } from "../../../lib/interfaces/resource";
import { ResourceDetails } from "../../carePlan/resourceDetails/ResourceDetails";
import { ResourcesSelectionList } from "../../carePlan/resourcesSelectionList/ResourcesSelectionList";
import styles from "./style.module.css";

const mountingClassnames = {
  enter: styles.enter,
  exit: styles.exit,
  enterActive: styles.enterActive,
  exitActive: styles.exitActive,
  enterDone: styles.enterDone,
};

interface ChatInputProps {
  body: string;
  resources: Resource[];
  defaultResourceIds: string[];
  onBodyChange: (value: string) => void;
  onResourceChange: (resources: Resource[]) => void;
  onResourceExpand: (resource: Resource) => void;
  onSendMessage: (body: string, resources: Resource[]) => Promise<any>;
  onMessageFail: (failed: boolean) => void;
}
export const ChatInput = ({
  onSendMessage,
  onMessageFail,
  body,
  resources,
  defaultResourceIds,
  onResourceChange,
  onResourceExpand,
  onBodyChange,
}: ChatInputProps) => {
  const [resourcesEditDrawerVisible, setResourcesEditDrawerVisible] =
    useState(false);

  const handleBodyChange = (value: string) => onBodyChange(value);
  const handleResourcesAdd = (resource: Resource[]) => {
    setResourcesEditDrawerVisible(false);
    onResourceChange(resource);
  };
  const handleResourceRemove = (id: string) => {
    onResourceChange(resources.filter((resource) => resource.id !== id));
  };

  const handleResourceClick = () => setResourcesEditDrawerVisible(true);
  const handleSendClick = async () => {
    const res = await onSendMessage(body, resources);
    if (!res || res.status >= 200 || res.status < 300) {
      onBodyChange("");
      onResourceChange([]);
      onMessageFail(false);
    } else if (!res.status) {
      onMessageFail(true);
    }
  };

  return (
    <div className={styles.chatInput}>
      {resources.length > 0 && (
        <TransitionGroup className={styles.resourcesContainer} component="div">
          {resources.map((resource) => (
            <CSSTransition
              timeout={200}
              classNames={mountingClassnames}
              key={resource.id}
            >
              <div className={styles.resourceCardContainer}>
                <ResourceCard
                  title={resource.title}
                  caption={resource.author}
                  photo={resource.image}
                  isVideo={resource.type === "video"}
                  onClick={() => onResourceExpand(resource)}
                  onRemove={() => handleResourceRemove(resource.id)}
                />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      )}

      <ChatBottomBar
        value={body}
        onChange={handleBodyChange}
        onResourceClick={handleResourceClick}
        onSendClick={handleSendClick}
        className={styles.chatBottomBar}
      />

      <Drawer
        title="Resources"
        visible={resourcesEditDrawerVisible}
        onClose={() => setResourcesEditDrawerVisible(false)}
        initialRoute="resource-list"
        componentMap={{
          "resource-list": {
            title: "Resources",
            route: "resource-list",
            component: ResourcesSelectionList,
            props: {
              // defaultResources: resources.map(resource => resource.id),
              defaultResources: defaultResourceIds,
              preSelectedResources: resources,
              onSubmit: handleResourcesAdd,
            },
          },
          resource: {
            title: "Resources",
            route: "resource",
            component: ResourceDetails,
          },
        }}
      />
    </div>
  );
};
