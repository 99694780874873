import { ControlProps } from "../../../types";

import { ControlPopOver } from "../ControlPopOver";

import {
  StyledBaseControlButton,
  StyledBaseControlContainer,
  StyledBaseControlLabel,
  StyledBaseControlLabelAndPopOverContainer,
} from "./Styled";

const BaseControl = ({
  icon,
  onClick,
  backgroundColor,
  textColor,
  label,
  options,
  popOverHeader,
  disabled = false,
}: ControlProps) => {
  return (
    <StyledBaseControlContainer>
      <StyledBaseControlButton
        backgroundColor={backgroundColor}
        textColor={textColor}
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
      >
        {icon}
      </StyledBaseControlButton>
      <StyledBaseControlLabelAndPopOverContainer>
        {label && <StyledBaseControlLabel>{label}</StyledBaseControlLabel>}
        {options && options.length > 0 && (
          <ControlPopOver
            options={options}
            popOverHeader={popOverHeader}
            textColor={textColor}
          />
        )}
      </StyledBaseControlLabelAndPopOverContainer>
    </StyledBaseControlContainer>
  );
};

export { BaseControl };
