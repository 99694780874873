import axios, { AxiosResponse } from "axios";
import { VisitType } from "./types/visitReason.types";

export interface Slot {
  timeDisplay: string;
  timeSlot: string;
}

export interface ProviderAvailability {
  [date: string]: {
    [visitTypeId: string]: {
      availableTimes: Slot[];
    };
  };
}

interface ScheduleResponse extends AxiosResponse {
  data: ProviderAvailability;
}

type FetchProviderScheduleParams = {
  providerId: string;
  startDate: string;
  endDate: string;
  visitTypes: VisitType[];
  callingUserType: string;
};

export const fetchProviderSchedule = async (
  params: FetchProviderScheduleParams,
) => {
  const result = await axios
    .get<ScheduleResponse, ScheduleResponse>("/schedule", {
      params,
    })
    .catch((err) => err);
  return result.data;
};
