import axios from "axios";

export type ExperianCodeFetchParams = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
};

export interface ExperianCodeResponse {
  code: string;
}

const fetchExperianCode = async ({
  firstName,
  lastName,
  email,
  dateOfBirth,
}: ExperianCodeFetchParams) => {
  const result = await axios
    .get<ExperianCodeResponse>(
      `/identity/verification-code?firstName=${firstName}&lastName=${lastName}&email=${email}&dateOfBirth=${dateOfBirth}`,
    )
    .catch((err) => err);

  return result?.data;
};

const _export = {
  fetchExperianCode,
};

export default _export;
