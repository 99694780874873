import { useState, useEffect } from "react";
import { Select } from "../../../components/select/Select";
import { Heading } from "../../../components/typography/heading/Heading";
import { Option } from "../../../lib/interfaces/input";
import { ProviderInfo } from "../../../lib/interfaces/user";
import { ProviderSearchOption } from "../../../lib/interfaces/schedule";
import { useProvider } from "../../../lib/hooks/useProvider";
import styles from "./style.module.css";

export interface CareNavDropdown {
  signedInUser: ProviderInfo;
  handleSelectedUserChange: (selectedProviderOption: Option) => void;
}

export const CareNavDropdown = ({
  signedInUser,
  handleSelectedUserChange,
}: CareNavDropdown) => {
  const [selectedProvider, setSelectedProvider] =
    useState<ProviderSearchOption>({ provider: { name: "", value: "" } });
  const [providers, setProviders] = useState<ProviderInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { getSpecialistAndHealthCoachProviders } = useProvider();

  useEffect(() => {
    getSpecialistAndHealthCoachProviders().then((providers) => {
      const providersList = providers as ProviderInfo[];
      //add the signed in provider/user to the top of the list of providers
      providersList.unshift(signedInUser);
      setProviders(providersList);
      setSelectedProvider({
        provider: {
          name: signedInUser.name,
          value: signedInUser.id,
          avatarUrl: signedInUser.photoUrl || "",
        },
      });
    });
  }, []);

  useEffect(() => {
    if (providers.length > 0) {
      setLoading(false);
    }
  }, [providers]);

  const handleSelectedProviderChange = (selectedProviderOption: Option) => {
    setSelectedProvider({
      provider: selectedProviderOption,
    });
    handleSelectedUserChange(selectedProviderOption);
  };

  return (
    <div>
      <Heading className={styles.careNavHeader} type="03">
        {"Set availability for"}
      </Heading>
      <Select
        onChange={handleSelectedProviderChange}
        value={selectedProvider.provider}
        options={providers.map((provider) => ({
          avatarUrl: provider.photo || "",
          name: provider.firstName + " " + provider.lastName,
          value: provider.id,
        }))}
        width={326}
        loading={loading}
      ></Select>
    </div>
  );
};
