import { Logger, POSTLogger } from "amazon-chime-sdk-js";
import { ReactNode } from "react";

export type FormattedDeviceType = {
  deviceId: string;
  label: string;
};

export type DeviceType = MediaDeviceInfo | FormattedDeviceType;

export type SelectedDeviceType = string | null;

export type DeviceTypeContext = {
  devices: DeviceType[];
  selectedDevice: SelectedDeviceType;
};

export type LocalVideoContextType = {
  isVideoEnabled: boolean;
  toggleVideo: () => Promise<void>;
};

export type DeviceConfig = {
  additionalDevices?: boolean;
};

export type LocalAudioOutputContextType = {
  isAudioOn: boolean;
  toggleAudio: () => void;
};

export type ContentShareControlContextType = {
  isContentSharePaused: boolean;
  toggleContentShare: () => Promise<void>;
  togglePauseContentShare: () => void;
};

export enum MeetingMode {
  Spectator,
  Attendee,
}

export enum Layout {
  Gallery,
  Featured,
}

// Different CPU Utilizations percentage options for initializing background blur and replacement processors
export const VideoFiltersCpuUtilization = {
  Disabled: "0",
  CPU10Percent: "10",
  CPU20Percent: "20",
  CPU40Percent: "40",
};

// Video Transform Options
export const VideoTransformOptions = {
  None: "None",
  Blur: "Background Blur",
  Replacement: "Background Replacement",
};

export type VideoTransformDropdownOptionType = {
  label: string;
  value: string;
};

export type MeetingConfig = {
  simulcastEnabled: boolean;
  logger: Logger;
  postLogger?: POSTLogger; // Keep track of POSTLogger to update meeting metadata while joining a meeting.
};

// Types from @aws-sdk/client-chime-sdk-meetings library
// used on backend, trying to match return
export interface MediaPlacement {
  AudioHostUrl?: string;
  AudioFallbackUrl?: string;
  SignalingUrl?: string;
  ScreenDataUrl?: string;
  ScreenViewingUrl?: string;
  ScreenSharingUrl?: string;
  EventIngestionUrl?: string;
  TurnControlUrl?: string;
}
export interface Meeting {
  MeetingId?: string;
  ExternalMeetingId?: string;
  MediaRegion?: string;
  MediaPlacement?: MediaPlacement;
  TenantIds?: string[];
  MeetingArn?: string;
}
export enum MediaCapabilities {
  NONE = "None",
  RECEIVE = "Receive",
  SEND = "Send",
  SEND_RECEIVE = "SendReceive",
}
export interface AttendeeCapabilities {
  Audio: MediaCapabilities | undefined;
  Video: MediaCapabilities | undefined;
  Content: MediaCapabilities | undefined;
}

export interface Attendee {
  ExternalUserId?: string;
  AttendeeId?: string;
  JoinToken?: string;
  Capabilities?: AttendeeCapabilities;
}
export type JoinMeetingInfo = {
  JoinInfo: {
    Meeting: Meeting;
    Attendee: Attendee;
  };
};

export interface PopOverOption {
  checked?: boolean;
  deviceId?: string;
  kind?: MediaDeviceKind;
  label: string;
  handleClick: (() => Promise<void>) | (() => void);
}

export interface ControlProps {
  icon: ReactNode;
  onClick?: () => void;

  backgroundColor?: string;
  textColor?: string;
  label?: string;
  options?: PopOverOption[];
  popOverHeader?: string;
  disabled?: boolean;
}

export enum MediaDeviceKindEnum {
  AUDIOINPUT = "audioinput",
  AUDIOOUTPUT = "audiooutput",
  VIDEOINPUT = "videoinput",
}
