import styles from "./style.module.css";

export type SpinnerType = "normal" | "thin";

export interface SpinnerProps {
  size?: number;
  type?: SpinnerType;
  top?: number;
}

const SpinnerNormal = ({ size, top }: SpinnerProps) => (
  <svg
    className={styles.spinner}
    style={{ top: top }}
    width={size || 32}
    height={size || 32}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.418 16 16 12.4185 16 8C16 3.58154 12.418 0 8 0C3.58203 0 0 3.58154 0 8C0 12.4185 3.58203 16 8 16ZM8 14C11.3125 14 14 11.3135 14 8C14 4.68652 11.3125 2 8 2C4.6875 2 2 4.68652 2 8C2 11.3135 4.6875 14 8 14Z"
      fill="#D6D3D1"
    />
    <path
      d="M4.9375 15.3911C5.60077 15.6651 6.29491 15.8486 7.0026 15.9376C7.55057 16.0064 8 15.5523 8 15C8 14.4477 7.54924 14.0085 7.00462 13.9168C6.55972 13.842 6.12366 13.7169 5.70312 13.5435C4.97656 13.2417 4.31641 12.7998 3.75781 12.2427C3.19922 11.6855 2.75781 11.0239 2.45703 10.2959C2.28385 9.87701 2.15859 9.44063 2.08347 8.99535C1.9916 8.45076 1.55228 8 1 8C0.447715 8 -0.00644753 8.44943 0.0624434 8.9974C0.15144 9.7053 0.33499 10.3995 0.609375 11.0615C1.01172 12.0322 1.60156 12.9141 2.34375 13.6567C3.08594 14.3999 3.96875 14.9888 4.9375 15.3911Z"
      fill="#1A6E95"
    />
  </svg>
);

const SpinnerThin = ({ size, top }: SpinnerProps) => (
  <svg
    className={styles.spinner}
    style={{ top: top }}
    width={size || 88}
    height={size || 88}
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 88C68.3008 88 88 68.3003 88 44C88 19.6997 68.3008 0 44 0C19.6992 0 0 19.6997 0 44C0 68.3003 19.6992 88 44 88ZM44 82C64.9883 82 82 64.9868 82 44C82 23.0132 64.9883 6 44 6C23.0117 6 6 23.0132 6 44C6 64.9868 23.0117 82 44 82Z"
      fill="#D6D3D1"
    />
    <path
      d="M12.8867 75.1128C20.4308 82.6547 30.429 87.176 41.002 87.8978C42.655 88.0107 44 86.6569 44 85C44 83.3431 42.6548 82.0123 41.0031 81.8815C39.9406 81.7973 38.8827 81.6685 37.832 81.4956C34.9648 81.0239 32.1562 80.2241 29.457 79.1074C24.8477 77.1978 20.6602 74.3989 17.1289 70.8701C13.6016 67.3413 10.8008 63.1523 8.89062 58.542C7.36702 54.8571 6.43234 50.9607 6.11849 46.9969C5.98771 45.3452 4.65685 44 3 44C1.34315 44 -0.010658 45.345 0.102188 46.998C0.824072 57.5723 5.34608 67.5708 12.8867 75.1128Z"
      fill="#1A6D93"
    />
  </svg>
);

export const Spinner = ({ size, type = "normal", top }: SpinnerProps) => (
  <div className={styles.spinnerContainer}>
    {type === "normal" && <SpinnerNormal size={size} top={top} />}
    {type === "thin" && <SpinnerThin size={size} top={top} />}
  </div>
);
