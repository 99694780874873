import { useEffect, useState } from "react";
import { Heading } from "../../../components/typography/heading/Heading";
import { Table } from "../../../components/table/Table";
import { Drawer } from "../../../components/drawer/Drawer";
import { NotesDetail } from "../notesDetail/NotesDetail";
import { ResultDetail } from "../resultDetail/ResultDetail";
import { CellProps } from "../../../components/table/Table";
import { useNotes } from "../../../lib/hooks/useNotes";
import { PatientInfo } from "../../../lib/interfaces/user";
import styles from "./style.module.css";
import { Spinner } from "../../../components/spinner/Spinner";
import axios from "axios";

const columns = ["Date", "Encounter Type", "Author", "Preview"];

export interface NotesProps {
  patient: PatientInfo;
}

export interface NoteDetails {
  noteId: string | null;
  author: string | null;
  date: string | null;
  notes: string | null;
  tag: string | null;
}

export const Notes = ({ patient }: NotesProps) => {
  const { initNotesTableData } = useNotes();

  const controller = new AbortController();

  const [loading, setLoading] = useState<boolean>(true);
  const [notes, setNotes] = useState<CellProps[][]>([]);
  const [viewNotesDetail, setViewNotesDetail] = useState(false);
  const [noteId, setNoteId] = useState("");
  const [noteDetails, setNoteDetails] = useState<NoteDetails>({
    noteId: "",
    author: "",
    date: "",
    notes: "",
    tag: "",
  });

  useEffect(() => {
    initNotesTableData(patient.id, handleViewNoteClick, controller).then(
      (data) => {
        setNotes(data);
        setLoading(false);
      },
    );

    return () => controller.abort();
  }, []);

  function extractContent(s: string) {
    const span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  const handleViewNoteClick = (
    noteId: string,
    author: string,
    date: string,
    notes: string,
    tag: string,
  ) => {
    setNoteDetails({
      noteId: noteId,
      author: author,
      date: date,
      notes: notes,
      tag: tag,
    });
    setViewNotesDetail(true);
  };

  const handleViewDetailCancel = () => {
    //setNoteId("")
    setViewNotesDetail(false);
  };

  return (
    <div>
      <Heading type="02" className={styles.header}>
        Notes
      </Heading>
      {!loading ? (
        <div>
          <Table data={notes} friendlyTitle="notes" columns={columns} />
        </div>
      ) : (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      <Drawer
        initialRoute="page1"
        onClose={() => {
          handleViewDetailCancel();
        }}
        visible={viewNotesDetail}
        title={"Title"}
        usePortal={true}
        componentMap={{
          page1: {
            route: "page1",
            title: "Hospital Visit",
            tag: noteDetails?.tag ? noteDetails.tag : undefined,
            tagType: "neutral",
            component: NotesDetail,
            props: {
              noteId: noteDetails.noteId,
              author: noteDetails.author,
              date: noteDetails.date,
              //notes: noteDetails.notes ? extractContent(noteDetails.notes) : ""
              notes: noteDetails.notes ? noteDetails.notes : "",
            },
          },
          page2: {
            route: "page2",
            title: "Comprehensive Metabolic Panel",
            centerTitle: true,
            tag: "Active",
            tagType: "info",
            component: ResultDetail,
          },
        }}
      />
    </div>
  );
};
