import { Modal } from "../../components/modal/Modal";
import { Button } from "../../components/button/Button";
import { ButtonGroup } from "../../components/button/Button";
import styles from "./style.module.css";

export interface Logout {
  handleLogout: () => void;
  handleCancel: () => void;
}

export const Logout = ({ handleLogout, handleCancel }: Logout) => {
  return (
    <Modal
      onCloseModal={() => {}}
      title="Log out of Compass?"
      dismissable={false}
    >
      <div style={{ overflow: "auto" }}>
        <div style={{ height: 32 }} />
        <ButtonGroup className={styles.buttons}>
          <Button
            onClick={() => {
              handleCancel();
            }}
            type="secondary-gray"
            label="Cancel"
          />
          <Button
            onClick={() => {
              handleLogout();
            }}
            label="Log Out"
          />
        </ButtonGroup>
      </div>
    </Modal>
  );
};
