import { useEffect, useState } from "react";
import { Heading } from "../../../components/typography/heading/Heading";
import { Table } from "../../../components/table/Table";
import { useVisits } from "../../../lib/hooks/useVisits";
import { CellProps } from "../../../components/table/Table";
import styles from "./style.module.css";
import { PatientInfo } from "../../../lib/interfaces/user";
import { useCarePlan } from "../../../lib/hooks/useCarePlan";
import { Drawer } from "../../../components/drawer/Drawer";
import { AppointmentDetail } from "../../schedule/appointmentDetail/AppointmentDetail";
import { Spinner } from "../../../components/spinner/Spinner";
import { Visit } from "../../../lib/interfaces/visits";

const columns = ["Date", "Type", "Provider"];

export interface VisitsProps {
  patient: PatientInfo;
  futureVisits: Visit[] | undefined;
  pastVisits: Visit[] | undefined;
}

export const Visits = ({ patient, futureVisits, pastVisits }: VisitsProps) => {
  const { getFutureAndPastVisitsTableData } = useVisits();
  const { getCarePlan } = useCarePlan();

  const [loading, setLoading] = useState<boolean>(true);
  const [upcomingDataLoaded, setUpcomingDataLoaded] = useState<boolean>(false);
  const [pastDataLoaded, setPastDataLoaded] = useState<boolean>(false);
  const [upcomingVisits, setUpcomingVisits] = useState<CellProps[][]>([]);
  const [previousVisits, setPreviousVisits] = useState<CellProps[][]>([]);
  const [viewVisitDetail, setViewVisitDetail] = useState(false);
  const [visitDetailId, setVisitDetailId] = useState("");
  const [noCarePlan, setNoCarePlan] = useState(false);

  useEffect(() => {
    getCarePlan(patient.id).then((res) => {
      setNoCarePlan((res && !res.id) || !res);
    });
  }, [patient]);

  useEffect(() => {
    if (futureVisits && pastVisits) {
      getFutureAndPastVisitsTableData(
        futureVisits,
        pastVisits,
        handleViewVisitClick,
        patient.id,
      ).then((_visits) => {
        setUpcomingVisits(_visits.futureVisitsTableData);
        setPreviousVisits(_visits.pastVisitsTableData);
        setUpcomingDataLoaded(true);
        setPastDataLoaded(true);
      });
    }
  }, [futureVisits, pastVisits]);

  useEffect(() => {
    if (upcomingDataLoaded && pastDataLoaded) {
      setLoading(false);
    }
  }, [upcomingDataLoaded, pastDataLoaded]);

  const handleViewVisitClick = (visitId: string) => {
    setVisitDetailId(visitId);
    setViewVisitDetail(true);
  };

  const handleClose = () => {
    setViewVisitDetail(false);
    setVisitDetailId("");
    setNoCarePlan(false);
  };

  const handleVisitsSort = (visits: CellProps[][]) => {
    return visits?.sort((a, b) => {
      const dateA = new Date(
        (typeof a[0] === "string" ? a[0] : "").replace(" at ", " "),
      );
      const dateB = new Date(
        (typeof b[0] === "string" ? b[0] : "").replace(" at ", " "),
      );
      return dateB.getTime() - dateA.getTime();
    });
  };

  return (
    <div>
      <Heading type="02" className={styles.header}>
        Visits
      </Heading>
      {!loading ? (
        <div>
          <div className={styles.upcomingTable}>
            <Table
              title="Upcoming"
              friendlyTitle="upcoming vists"
              data={handleVisitsSort(upcomingVisits)}
              columns={columns}
            />
          </div>
          <div>
            <Table
              title="Past"
              friendlyTitle="past visits"
              data={handleVisitsSort(previousVisits)}
              columns={columns}
            />
          </div>
        </div>
      ) : (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      <Drawer
        initialRoute="page1"
        onClose={() => {
          handleClose();
        }}
        visible={viewVisitDetail}
        title={"Appointment Detail"}
      >
        <AppointmentDetail patient={patient} visitId={visitDetailId} />
      </Drawer>
    </div>
  );
};
