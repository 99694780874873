import {
  Dispatch,
  SetStateAction,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";

interface IVideoChatContext {
  meetingId: string | undefined;
  setMeetingId: Dispatch<SetStateAction<string | undefined>>;
  showRoster: boolean;
  toggleRoster: () => void;
  attendeeId: string | undefined;
  setAttendeeId: Dispatch<SetStateAction<string | undefined>>;
}
const VideoChatContext = createContext<IVideoChatContext>({
  meetingId: undefined,
  setMeetingId: () => {},
  showRoster: true,
  toggleRoster: () => {},
  attendeeId: undefined,
  setAttendeeId: () => {},
});

interface IVideoChatContextProvider {
  children: ReactNode;
}
const VideoChatContextProvider = ({ children }: IVideoChatContextProvider) => {
  const [meetingId, setMeetingId] = useState<string>();
  const [showRoster, setShowRoster] = useState<boolean>(true);
  const [attendeeId, setAttendeeId] = useState<string>();

  const toggleRoster = () => setShowRoster(!showRoster);
  return (
    <VideoChatContext.Provider
      value={{
        meetingId,
        setMeetingId,
        showRoster,
        toggleRoster,
        attendeeId,
        setAttendeeId,
      }}
    >
      {children}
    </VideoChatContext.Provider>
  );
};

const useVideoChat = () => useContext(VideoChatContext);

export { useVideoChat, VideoChatContextProvider };
