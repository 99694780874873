import { useState } from "react";
import { Participant, Message } from "../../lib/interfaces/messaging";
import { SidePanel } from "../../containers/messaging/sidePanel/SidePanel";
import { ChatView } from "../../containers/messaging/chatView/ChatView";
import styles from "./style.module.css";

export interface SelectedChat {
  id: string;
  participants: Participant[];
  regarding?: Participant;
  template?: string;
}

interface MessagesProps {
  onChatSelect: (chat: SelectedChat) => void;
}

export const Messages = ({ onChatSelect }: MessagesProps) => {
  const [selectedChat, setSelectedChat] = useState<SelectedChat>();
  const [newMsg, setNewMessage] = useState<Message>();
  const [refreshMsg, setRefreshMsgs] = useState<Boolean>(true);

  const handleChatSelect = (chat: SelectedChat) => {
    setSelectedChat(chat);
    onChatSelect(chat);
    setRefreshMsgs(false);
  };

  const handleMessageAdded = (message: Message) => {
    setNewMessage(message);
  };

  return (
    <div className={styles.messagesPage}>
      <SidePanel
        onChatSelect={handleChatSelect}
        newMessage={newMsg}
        resetSelectedMessage={() => setRefreshMsgs(true)}
      />
      <div className={styles.chatContainer}>
        {selectedChat && !refreshMsg && (
          <ChatView
            chat={selectedChat}
            handleMessageAdded={handleMessageAdded}
          />
        )}
      </div>
    </div>
  );
};
