import { useEffect, useRef, useState } from "react";
import {
  ActionPlan,
  CarePlan as CarePlanType,
  Focus,
  Intention,
  LoveLetter as LoveLetterType,
} from "../../lib/interfaces/carePlan";
import { CarePlanFooter } from "../../containers/carePlan/carePlanFooter/CarePlanFooter";
import { PatientInfo } from "../../lib/interfaces/user";
import { useInView } from "react-intersection-observer";
import {
  Tabs,
  ActiveCarePlanTab,
  ActiveRecordTab,
} from "../../components/tabs/Tabs";
import { CarePlanFocus } from "../../containers/carePlan/carePlanFocus/CarePlanFocus";
import { ProgressActionPlan } from "../../containers/carePlan/progressActionPlan/ProgressActionPlan";
import { MedicationsActionPlan } from "../../containers/carePlan/medicationsActionPlan/MedicationsActionPlan";
import { ActionPlanSection } from "../../containers/carePlan/pillarsActionPlan/PillarsActionPlan";
import { CarePlanResources } from "../../containers/carePlan/carePlanResources/CarePlanResources";
import { LoveLetter } from "../../containers/carePlan/loveLetter/LoveLetter";
import { CarePlanProgress, useCarePlan } from "../../lib/hooks/useCarePlan";
import { Spinner } from "../../components/spinner/Spinner";
import { CarePlanIntentions } from "../../containers/carePlan/carePlanIntentions/CarePlanIntentions";
import { useAlerts } from "../../lib/hooks/useAlerts";
import { compareAsc, format, subWeeks } from "date-fns";
import { Modal } from "../../components/modal/Modal";
import { Body } from "../../components/typography/body/Body";
import { Button, ButtonGroup } from "../../components/button/Button";
import styles from "./style.module.css";
//@ts-ignore
import smoothscroll from "smoothscroll-polyfill";
import { getLocalDateTime } from "../../lib/util/date";

smoothscroll.polyfill();

export interface CarePlanProps {
  patient: PatientInfo;
}

export interface PillarActionPlan {
  move?: ActionPlan;
  nourish?: ActionPlan;
  calm?: ActionPlan;
}

export const CarePlan = ({ patient }: CarePlanProps) => {
  const { getCarePlan, postCarePlan, getProgressData } = useCarePlan();
  const { alerts, pushAlert, closeAlert, clearAlerts } = useAlerts();

  const controller = new AbortController();

  const [loading, setLoading] = useState(true);
  const [publishing, setPublishing] = useState(false);
  const [carePlan, setCarePlan] = useState<CarePlanType>();
  const [progress, setProgress] = useState<CarePlanProgress>({
    move: [],
    nourish: [],
    calm: [],
    progress: [],
  });
  const [sendToEpic, setSendToEpic] = useState(false);
  const [unpublishedChanges, setUnpublishedChanges] = useState(false);
  const progressRef = useRef<CarePlanProgress>({
    move: [],
    nourish: [],
    calm: [],
    progress: [],
  });
  const carePlanRef = useRef<CarePlanType>();
  const postingCarePlan = useRef<boolean>(false);
  const [
    publishWithoutLoverLetterModalVisible,
    setPublishWithoutLoverLetterModalVisible,
  ] = useState(false);

  const [summarySectionRef, summaryInView] = useInView({ threshold: 0.1 });
  const [intentionSectionRef, intentionInView] = useInView({ threshold: 0.1 });
  const [progressSectionRef, progressInView] = useInView({ threshold: 0.1 });
  const [medicationsSectionRef, medicationsInView] = useInView({
    threshold: 0.1,
  });
  const [moveSectionRef, moveInView] = useInView({ threshold: 0.1 });
  const [nourishSectionRef, nourishInView] = useInView({ threshold: 0.1 });
  const [calmSectionRef, calmInView] = useInView({ threshold: 0.1 });
  const [resourcesSectionRef, resourcesInView] = useInView({ threshold: 0.1 });
  const [loveLetterSectionRef, loveLetterInView] = useInView({
    threshold: 0.1,
  });

  const [activeTab, setActiveTab] = useState<ActiveCarePlanTab>();

  const getActiveTab = (): ActiveCarePlanTab => {
    if (summaryInView) return "summary";
    else if (intentionInView) return "intention";
    else if (progressInView) return "progress";
    else if (medicationsInView) return "medications";
    else if (moveInView) return "move";
    else if (nourishInView) return "nourish";
    else if (calmInView) return "calm";
    else if (resourcesInView) return "resources";
    else if (loveLetterInView) return "loveLetter";
    else return undefined;
  };

  const handleTabChange = (newTab: ActiveCarePlanTab | ActiveRecordTab) => {
    if (newTab)
      document.getElementById(newTab)?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    getCarePlan(patient.id, controller).then((_carePlan) => {
      if (_carePlan) {
        carePlanRef.current = _carePlan;
        setCarePlan(_carePlan);

        if (_carePlan.firstPublishedOn && _carePlan.id) {
          const today = new Date();

          // const from = format(subMonths(startOfCarePlan, 6), 'yyyy_ww')
          const from = _carePlan.firstPublishedOn;
          const to = today.toString();

          getProgressData(_carePlan.id, "", from, to, from, controller).then(
            (res) => {
              if (res) {
                const { move, calm, nourish, progress } = res.progressData;
                progressRef.current = {
                  move,
                  calm,
                  nourish,
                  progress,
                };
                setProgress(progressRef.current);
              }
            },
          );
        }
      } else {
        setLoading(false);
      }
    });
  }, [patient]);

  useEffect(() => () => setLoading(false), [carePlan]);

  useEffect(() => {
    setActiveTab(getActiveTab());
  }, [
    summaryInView,
    intentionInView,
    progressInView,
    medicationsInView,
    moveInView,
    nourishInView,
    calmInView,
    resourcesInView,
    loveLetterInView,
  ]);

  useEffect(() => {
    if (carePlan) {
      const lastModified = getLocalDateTime(carePlan.modifiedOn);
      const lastPublished = getLocalDateTime(carePlan.publishedOn);
      if (compareAsc(lastModified, lastPublished) === 1) {
        setUnpublishedChanges(true);
      } else {
        setUnpublishedChanges(false);
      }
    }
  }, [carePlan]);

  const handleFocusChange = async (focus: Focus) => {
    if (!postingCarePlan.current) {
      postingCarePlan.current = true;
      carePlanRef.current = await postCarePlan(patient.id, {
        ...carePlanRef.current,
        id: carePlanRef.current?.id,
        focus,
      });
      postingCarePlan.current = false;

      if (carePlanRef.current) {
        setCarePlan(carePlanRef.current);
        return true;
      }
      return false;
    } else return false;
  };

  const handleIntentionChange = async (intention: Intention) => {
    if (!postingCarePlan.current) {
      postingCarePlan.current = true;
      carePlanRef.current = await postCarePlan(patient.id, {
        ...carePlanRef.current,
        id: carePlanRef.current?.id,
        intention,
      });
      postingCarePlan.current = false;

      if (carePlanRef.current) {
        setCarePlan(carePlanRef.current);
        return true;
      }
      return false;
    } else return false;
  };

  const handlePillarChange = async (actionPlan: PillarActionPlan) => {
    if (!postingCarePlan.current) {
      postingCarePlan.current = true;
      carePlanRef.current = await postCarePlan(patient.id, {
        ...carePlanRef.current,
        id: carePlanRef.current?.id,
        pillars: {
          ...carePlanRef.current?.pillars,
          ...actionPlan,
        },
      });
      postingCarePlan.current = false;

      if (carePlanRef.current) {
        setCarePlan(carePlanRef.current);
        return true;
      }
      return false;
    } else return false;
  };

  const handleLoveLetterChange = async (loveLetter: LoveLetterType) => {
    if (!postingCarePlan.current) {
      postingCarePlan.current = true;
      carePlanRef.current = await postCarePlan(patient.id, {
        ...carePlanRef.current,
        id: carePlanRef.current?.id,
        loveLetter,
      });
      postingCarePlan.current = false;

      if (carePlanRef.current) {
        setCarePlan(carePlanRef.current);
        return true;
      }
      return false;
    } else return false;
  };

  const onPublish = async (skipLoveLetter?: boolean) => {
    if (!skipLoveLetter && !carePlan?.loveLetter?.value) {
      setPublishWithoutLoverLetterModalVisible(true);
      return;
    }

    clearAlerts();
    if (carePlanRef.current) {
      setPublishing(true);
      const result = await postCarePlan(
        patient.id,
        carePlanRef.current,
        true,
        sendToEpic,
      );
      if (result) {
        carePlanRef.current = result;
        setCarePlan(carePlanRef.current);
        setUnpublishedChanges(false);
      }
    }

    setPublishing(false);
  };
  const handleSendToEpicChange = () => setSendToEpic(!sendToEpic);
  const handleCloseModal = () =>
    setPublishWithoutLoverLetterModalVisible(false);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className={styles.carePlan}>
        <Tabs
          type={"carePlan"}
          activeTab={activeTab}
          onTabClick={handleTabChange}
          showVerificationTab={false}
        />
        <div className={styles.carePlanSections}>
          <div className={styles.section} id="summary" ref={summarySectionRef}>
            <CarePlanFocus focus={carePlan?.focus} onSave={handleFocusChange} />
          </div>
          <div
            className={styles.section}
            id="intention"
            ref={intentionSectionRef}
          >
            <CarePlanIntentions
              intention={carePlan?.intention}
              onSave={handleIntentionChange}
            />
          </div>
          <div
            className={styles.section}
            id="progress"
            ref={progressSectionRef}
          >
            <ProgressActionPlan progress={progress.progress} />
          </div>
          <div
            className={styles.section}
            id="medications"
            ref={medicationsSectionRef}
          >
            <MedicationsActionPlan patientId={patient.id} />
          </div>
          <div className={styles.section} id="move" ref={moveSectionRef}>
            <ActionPlanSection
              progress={progress.move}
              patient={patient}
              actionPlan={carePlan?.pillars?.move}
              pillar="move"
              onChange={handlePillarChange}
            />
          </div>
          <div className={styles.section} id="nourish" ref={nourishSectionRef}>
            <ActionPlanSection
              progress={progress.nourish}
              patient={patient}
              actionPlan={carePlan?.pillars?.nourish}
              pillar="nourish"
              onChange={handlePillarChange}
            />
          </div>
          <div className={styles.section} id="calm" ref={calmSectionRef}>
            <ActionPlanSection
              progress={progress.calm}
              patient={patient}
              actionPlan={carePlan?.pillars?.calm}
              pillar="calm"
              onChange={handlePillarChange}
            />
          </div>
          <div
            className={styles.section}
            id="resources"
            ref={resourcesSectionRef}
          >
            <CarePlanResources pillars={carePlan?.pillars} />
          </div>
          <div
            className={styles.section}
            id="loveLetter"
            ref={loveLetterSectionRef}
          >
            <LoveLetter
              loveLetter={carePlan?.loveLetter}
              onSave={handleLoveLetterChange}
            />
          </div>
        </div>
        <CarePlanFooter
          publishedBy={carePlan?.publishedBy}
          publishedOn={carePlan?.publishedOn}
          sentToEpicOn={carePlan?.sentToEpicOn}
          unpublishedChanges={unpublishedChanges}
          publishButtonDisabled={carePlan ? false : true}
          publishButtonLoading={publishing}
          alerts={alerts}
          onCloseAlert={closeAlert}
          onPublish={onPublish}
          sendToEpic={sendToEpic}
          onSendToEpicChange={handleSendToEpicChange}
        />

        <Modal
          title="Publish Care Plan"
          visible={publishWithoutLoverLetterModalVisible}
          onCloseModal={handleCloseModal}
        >
          <Body className={styles.skipLoveLetterMessage}>
            {
              "Are you sure you want to publish this care plan without a love letter?"
            }
          </Body>
          <ButtonGroup align="end">
            <Button
              label="Cancel"
              type="secondary-gray"
              onClick={handleCloseModal}
            />
            <Button
              label="Publish without Love Letter"
              onClick={() => {
                onPublish(true);
                handleCloseModal();
              }}
            />
          </ButtonGroup>
        </Modal>
      </div>
    </>
  );
};
