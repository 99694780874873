import { GenderType } from "./gender.types";
import { MembershipType } from "./membership.types";
import { ProviderSubset } from "./provider.types";

export enum VisitType {
  ImmediateCareVirtual = "Immediate Care Virtual",
  ImmediateCareInPerson = "Immediate Care In-Person",
  GeneralVisitVirtual = "General Visit Virtual",
  GeneralVisitInPerson = "General Visit In-Person",
  WellWomensExam = "Well Women's Exam",
  AnnualPhysical = "Annual Physical",
  HealthCoachFollowUpVirtual = "Health Coach Follow-up Virtual",
  HealthCoachFollowUpInPerson = "Health Coach Follow-up In-Person",
  WelcomeVisit = "Welcome Visit",
  InitialConsult = "Initial Consult",
  CompassLabs = "Compass Labs",
  Labs = "Labs",
  ProviderReviewVirtual = "Provider Review Virtual",
  ProviderReviewInPerson = "Provider Review In-Person",
  GenomicsReviewVirtual = "Genomics Review Virtual",
  GenomicsReviewInPerson = "Genomics Review In-Person",
  HealthCoachVisitVirtual = "Health Coach Visit Virtual",
  HealthCoachVisitInPerson = "Health Coach Visit In-Person",
}

export type VisitReason = {
  id: string;
  visitType: VisitType;
  displayTitle: string;
  description: string;
  duration: number;
  epicVisitType: string;
  locationType: AppointmentType;
  memberships: MembershipType[];
  genders: GenderType[];
  providers?: ProviderSubset[];
  questionnaires?: string[];
  isOnboardingOnly?: boolean;
  allowSameDayVisit?: boolean;
  index?: number;
};

export enum AppointmentType {
  InPerson = "In-Person",
  Virtual = "Virtual",
}
