import axios from "axios";
import _ from "lodash";
import { Notepad } from "phosphor-react";
import { MutableRefObject, useEffect, useState } from "react";
import { Drawer } from "../../../components/drawer/Drawer";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { Spinner } from "../../../components/spinner/Spinner";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { useNotes } from "../../../lib/hooks/useNotes";
import { CarePlanNote } from "../../../lib/interfaces/carePlan";
import { PatientInfo } from "../../../lib/interfaces/user";
import { Note } from "../note/Note";
import { NoteEditForm } from "../noteEditForm/NoteEditForm";
import styles from "./style.module.css";

interface NotesProps {
  patient: PatientInfo;
  setPrimaryAction: (_?: any) => void;
}

export const Notes = ({ patient, setPrimaryAction }: NotesProps) => {
  const [notes, setNotes] = useState<CarePlanNote[]>([]);
  const [showNoteEditDrawer, setShowNoteEditDrawer] = useState(false);
  const [selectedNote, setSelectedNote] = useState<CarePlanNote | false>();
  const [loading, setLoading] = useState(true);
  const { getCarePlanNotes } = useNotes();

  const controller = new AbortController();

  useEffect(() => {
    setPrimaryAction(() => () => setSelectedNote(false));
    getCarePlanNotes(patient.id, controller).then((notes) => {
      const _notes = _.sortBy(
        notes,
        (note) => new Date(note.modifiedOn),
      ).reverse();
      setNotes(_notes);
    });

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (selectedNote || selectedNote === false) setShowNoteEditDrawer(true);
    else setShowNoteEditDrawer(false);
  }, [selectedNote]);

  useEffect(() => {
    return () => setLoading(false);
  }, [notes]);

  const handleNoteEditSave = () => {
    setSelectedNote(undefined);
    setLoading(true);
    getCarePlanNotes(patient.id).then((notes) => {
      const _notes = _.sortBy(
        notes,
        (note) => new Date(note.modifiedOn),
      ).reverse();
      setNotes(_notes);
    });
  };

  const handleNoteEditClose = () => {
    setSelectedNote(undefined);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className={styles.notesList}>
          {notes && notes?.length > 0 ? (
            notes.map((note) => (
              <Note
                key={note.id}
                note={note}
                patientId={patient.id}
                onEditClick={() => setSelectedNote(note)}
              />
            ))
          ) : (
            <div className={styles.noNotes}>
              <FeaturedIcon Icon={Notepad} type="gray" />
              <div className={styles.noNotesMessage}>
                <Heading type="02">No Notes</Heading>
                <Body color="secondary">
                  When you create or receive a note, it will appear here
                </Body>
              </div>
            </div>
          )}
        </div>
      )}
      <Drawer
        title={selectedNote === false ? "New Note" : "Edit Note"}
        visible={showNoteEditDrawer}
        onClose={() => setSelectedNote(undefined)}
      >
        <NoteEditForm
          patient={patient}
          note={selectedNote || undefined}
          onSubmit={handleNoteEditSave}
          onClose={handleNoteEditClose}
        />
      </Drawer>
    </>
  );
};
