import { useState, ChangeEvent } from "react";
import {
  Roster,
  RosterHeader,
  RosterGroup,
  useRosterState,
  RosterAttendeeType,
  Heading,
} from "amazon-chime-sdk-component-library-react";

import RosterAttendeeWrapper from "../RosterAttendeeWrapper/RosterAttendeeWrapper";
import { useVideoChat } from "../../context/VideoChatContext";

const MeetingRoster = () => {
  const { roster } = useRosterState();
  const [filter, setFilter] = useState("");
  const { toggleRoster } = useVideoChat();

  let attendees = Object.values(roster);

  if (filter) {
    attendees = attendees.filter((attendee: RosterAttendeeType) =>
      attendee?.name?.toLowerCase().includes(filter.trim().toLowerCase()),
    );
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const attendeeItems = attendees.map((attendee: RosterAttendeeType) => {
    const { chimeAttendeeId } = attendee || {};
    return (
      <RosterAttendeeWrapper
        key={chimeAttendeeId}
        attendeeId={chimeAttendeeId}
      />
    );
  });

  return (
    <Roster className="roster">
      <RosterHeader
        searchValue={filter}
        onSearch={handleSearch}
        onClose={toggleRoster}
        title={<Heading level={6}>Attendees</Heading>}
      />
      <RosterHeader title="Present" badge={attendees.length} />
      <RosterGroup>{attendeeItems}</RosterGroup>
    </Roster>
  );
};

export default MeetingRoster;
