import MeetingJoinDetails from "../../containers/MeetingJoinDetails";

import { StyledWrapper, StyledCard } from "./Styled";
import MicrophoneDevices from "./MicrophoneDevices";
import SpeakerDevices from "./SpeakerDevices";
import CameraDevices from "./CameraDevices";

const DeviceSelection = () => (
  <StyledWrapper>
    <StyledCard>
      <CameraDevices />
    </StyledCard>
    <div>
      <StyledCard>
        <MicrophoneDevices />
      </StyledCard>
      <StyledCard>
        <SpeakerDevices />
      </StyledCard>
      <MeetingJoinDetails />
    </div>
  </StyledWrapper>
);

export default DeviceSelection;
