import axios, { AxiosResponse } from "axios";

interface Component {
  comments?: string;
  flag?: string;
  high?: string;
  id: string;
  low?: string;
  name: string;
  range: string;
  unit: string;
  value: string;
}

interface Id {
  id: string;
  type: string;
}

export interface Comment {
  DateTime: string;
  Text: string;
  ViewedBy: string;
  ViewedDateTime: string;
}

export interface Test {
  collectedDate: string;
  comments?: string | Comment[];
  components?: Component[];
  id: string;
  impressions?: string;
  isAbnormal?: boolean;
  name: string;
  narrative?: string;
  note?: string;
  orderedBy: {
    name: string;
    ids: Id[];
  };
  providerName?: string;
  resultDate: string;
  status: string;
  visitDatId: string;
}

interface TestsResponse extends AxiosResponse {
  data: Test[];
}

export const fetchPatientTests = async (userId: string) => {
  const result = await axios
    .get<TestsResponse, TestsResponse>(`/patient/tests?userId=${userId}`)
    .catch((err) => err);
  return result.data;
};
