import React, { useCallback, useEffect, useRef, useState } from "react";
import { Check } from "phosphor-react";
import { Body } from "../typography/body/Body";
import { PatientOption } from "../../lib/interfaces/input";
import { Spinner } from "../spinner/Spinner";
import { CircleWavyCheck } from "phosphor-react";
import styles from "./style.module.css";
import _ from "lodash";
import clsx from "clsx";

export interface PatientSearchDropdownOptionProps {
  onToggle: (selected: boolean) => void;
  option: PatientOption;
  selected?: boolean;
}

export const PatientSearchDropdownOption = ({
  onToggle,
  option,
  selected,
}: PatientSearchDropdownOptionProps) => {
  const [hover, setHover] = useState(false);

  return (
    <label
      className={clsx(styles.dropdownOption, selected && styles.selected)}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <div className={styles.dropdownOptionNameAndSecondaryText}>
        <div className={styles.dropdownOptionName}>
          <Body
            size="md"
            color={hover ? "link" : "primary"}
            className={clsx(styles.dropdownOptionText)}
          >
            {option.name}
          </Body>
          {option.premiumMember && (
            <CircleWavyCheck
              color="#1A6D93"
              weight="fill"
              className={styles.premium}
            />
          )}
        </div>
        {option?.secondaryText && (
          <Body size="xs" color={hover ? "link" : "secondary"}>
            {option.secondaryText}
          </Body>
        )}
      </div>
      <input
        className={styles.dropdownOptionCheckbox}
        checked={selected}
        type="checkbox"
        onChange={(event) => onToggle(event.target.checked)}
      />
      <Check className={styles.dropdownOptionCheckmark} size={24} />
    </label>
  );
};

export interface PatientSearchDropdownProps {
  onOptionSelect: (option: PatientOption, selected: boolean) => void;
  options: PatientOption[];
  selectedValue?: string | string[];
  loading?: boolean;
  dropdownStyle?: string;
  onLoadMore?: () => void;
  width?: number;
  isPatientSearchPage?: boolean;
}

export const PatientSearchDropdown = ({
  onOptionSelect,
  options,
  selectedValue = [],
  loading,
  dropdownStyle,
  onLoadMore,
  isPatientSearchPage = false,
}: PatientSearchDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isFetching, setIsFetching] = useState(false);

  const isSelected = (option: PatientOption) => {
    if (Array.isArray(selectedValue))
      return selectedValue.includes(option.value || option.id);
    else
      return (
        selectedValue === option.value ||
        selectedValue === option.id ||
        _.isEqual(selectedValue, option.value)
      );
  };

  const preventFocusSteal = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleScroll = useCallback(
    _.debounce(() => {
      if (dropdownRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
        if (scrollTop + clientHeight >= scrollHeight && !isFetching) {
          setIsFetching(true);
          onLoadMore && onLoadMore();
          setIsFetching(false);
        }
      }
    }, 500),
    [onLoadMore, isFetching],
  );

  useEffect(() => {
    const dropdownElement = dropdownRef.current;
    if (dropdownElement) {
      dropdownElement.addEventListener("scroll", handleScroll);
      return () => {
        dropdownElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <div
      className={clsx(
        styles.dropdown,
        dropdownStyle && isPatientSearchPage ? dropdownStyle : styles.w100,
      )}
      onMouseDown={preventFocusSteal}
    >
      <div
        className={clsx(styles.dropdownScroll)}
        ref={dropdownRef}
        style={{ maxHeight: "200px", overflowY: "scroll" }}
      >
        {options.length > 0 &&
          options.map((option) => (
            <PatientSearchDropdownOption
              option={option}
              key={option.id || option.value?.id || option.value}
              selected={isSelected(option)}
              onToggle={(selected) => onOptionSelect(option, selected)}
            />
          ))}
      </div>
      {loading && (
        <div className={styles.noResults}>
          <Spinner top={8} />
        </div>
      )}
      {options.length === 0 && !loading && (
        <label className={styles.noResults}>
          <Body size="md" color="secondary">
            {" "}
            No Results{" "}
          </Body>
        </label>
      )}
    </div>
  );
};
