import { useOktaAuth } from "@okta/okta-react";
import { fetchProviderSchedule } from "../apis/schedule";
import { AppointmentSlots } from "../interfaces/schedule";
import { Slot } from "../apis/schedule";
import { Option } from "../interfaces/input";
import {
  format,
  isToday,
  getDay,
  addDays,
  isAfter,
  startOfWeek,
  startOfToday,
  parse,
} from "date-fns";
import { AlertContext } from "../context/context";
import { useContext } from "react";
import { VisitType } from "../apis/types/visitReason.types";

export const useSchedule = () => {
  const { oktaAuth } = useOktaAuth();
  const { pushAlert, clearAlerts } = useContext(AlertContext);
  const accessToken = oktaAuth.getAccessToken();

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  //this is the default schedule that will show when there is no provider chosen
  const getInitialSchedule = (startDate: string): AppointmentSlots[] => {
    return [
      {
        today: isToday(new Date(startDate + "T00:00:00")),
        dayOfWeek: daysOfWeek[getDay(new Date(startDate + "T00:00:00")) - 1],
        date: startDate + "T00:00:00",
        times: [],
      },
      {
        today: isToday(addDays(new Date(startDate + "T00:00:00"), 1)),
        dayOfWeek:
          daysOfWeek[getDay(addDays(new Date(startDate + "T00:00:00"), 1)) - 1],
        date:
          format(addDays(new Date(startDate), 1), "yyyy-MM-dd") + "T00:00:00",
        times: [],
      },
      {
        today: isToday(addDays(new Date(startDate + "T00:00:00"), 2)),
        dayOfWeek:
          daysOfWeek[getDay(addDays(new Date(startDate + "T00:00:00"), 2)) - 1],
        date:
          format(addDays(new Date(startDate), 2), "yyyy-MM-dd") + "T00:00:00",
        times: [],
      },
      {
        today: isToday(addDays(new Date(startDate + "T00:00:00"), 3)),
        dayOfWeek:
          daysOfWeek[getDay(addDays(new Date(startDate + "T00:00:00"), 3)) - 1],
        date:
          format(addDays(new Date(startDate), 3), "yyyy-MM-dd") + "T00:00:00",
        times: [],
      },
      {
        today: isToday(addDays(new Date(startDate + "T00:00:00"), 4)),
        dayOfWeek:
          daysOfWeek[getDay(addDays(new Date(startDate + "T00:00:00"), 4)) - 1],
        date:
          format(addDays(new Date(startDate), 4), "yyyy-MM-dd") + "T00:00:00",
        times: [],
      },
      {
        today: isToday(addDays(new Date(startDate + "T00:00:00"), 5)),
        dayOfWeek:
          daysOfWeek[getDay(addDays(new Date(startDate + "T00:00:00"), 5)) - 1],
        date:
          format(addDays(new Date(startDate), 5), "yyyy-MM-dd") + "T00:00:00",
        times: [],
      },
    ];
  };

  const getTimes = (slots: Slot[]) => {
    const times: Option[] = [];
    const dateTimeNow = new Date();
    slots.forEach((timeSlot) => {
      const dateTime = parse(
        timeSlot.timeSlot,
        "yyyy-MM-dd:HH:mm:ss",
        new Date(),
      );
      if (isAfter(dateTime, dateTimeNow)) {
        const formattedTime = format(dateTime, "h:mmaaa");
        const time = {
          name: formattedTime,
          value: timeSlot.timeSlot, //formattedTime
        };
        times.push(time);
      }
    });
    return times;
  };

  const getProviderSchedule = async (
    providerId: string,
    startDate: string,
    endDate: string,
    visitType: VisitType,
    epicVisitTypeId: string,
  ) => {
    if (!accessToken) {
      return [];
    }

    const availability = await fetchProviderSchedule({
      providerId,
      startDate,
      endDate,
      visitTypes: [visitType],
      callingUserType: "provider",
    });

    const dates = Object.keys(availability);
    if (!dates.length) {
      pushAlert("Failed to get provider's schedule", "danger");
      return getInitialSchedule(
        format(addDays(startOfWeek(startOfToday()), 1), "yyyy-MM-dd"),
      );
    }

    const schedule = dates.map((date) => {
      const availableTimes = availability[date][epicVisitTypeId].availableTimes;
      return {
        today: isToday(
          parse(`${date}T00:00:00`, "yyyy-MM-ddHH:mm:ss", new Date()),
        ),
        dayOfWeek:
          daysOfWeek[getDay(parse(date, "yyyy-MM-dd", new Date())) - 1],
        date: `${date}T00:00:00`,
        times: getTimes(availableTimes),
      };
    });
    clearAlerts();
    return schedule;
  };

  return { getInitialSchedule, getProviderSchedule };
};
