import { datadogLogs } from "@datadog/browser-logs";

const logger = datadogLogs.logger;

export const logDebug = (message: string, context: object = {}) => {
  logger.debug(message, context);
};

export const logInfo = (message: string, context: object = {}) => {
  logger.info(message, context);
};

export const logWarn = (message: string, context: object = {}) => {
  logger.warn(message, context);
};

export const logError = (
  message: string,
  context: object = {},
  error: Error,
) => {
  logger.error(message, context, error);
};
