import _ from "lodash";
import { useEffect, useState, useContext, useRef } from "react";
import { format, isBefore, isSameDay, subHours } from "date-fns";
import { Visit } from "../../../lib/interfaces/visits";
import { AppointmentDetailCard } from "../../../components/appointmentDetailCard/AppointmentDetailCard";
import { TextInput } from "../../../components/textInput/TextInput";
import { Heading } from "../../../components/typography/heading/Heading";
import { Body } from "../../../components/typography/body/Body";
import { Button } from "../../../components/button/Button";
import { Paperclip } from "phosphor-react";
import { useVisits } from "../../../lib/hooks/useVisits";
import { Avatar } from "../../../components/avatar/Avatar";
import { AlertContext } from "../../../lib/context/context";
import {
  defaultPatient,
  PatientInfo,
  ProviderInfo,
} from "../../../lib/interfaces/user";
import { Spinner } from "../../../components/spinner/Spinner";
import { Attachment, CarePlanNote } from "../../../lib/interfaces/carePlan";
import { useProvider } from "../../../lib/hooks/useProvider";
import { useNotes } from "../../../lib/hooks/useNotes";
import { Tag } from "../../../components/tag/Tag";
import { File } from "phosphor-react";
import styles from "./style.module.css";
import { NotificationType } from "../../../components/alertBanner/AlertBanner";
import { ProviderType } from "../../../lib/apis/types/provider.types";
import { VisitType } from "../../../lib/apis/types/visitReason.types";

export interface Log {
  timeStamp: string;
  authorStamp: string;
  provider: ProviderInfo;
}

export interface AddedAttachment {
  name: string;
  file: File;
}

interface AttachmentsRef {
  added: AddedAttachment[];
  deleted: Attachment[];
}
export interface AppointmentDetailProps {
  visitId: string;
  patient: PatientInfo;
  postCall?: boolean;
  careGivenStatus?: boolean;
  reason?: string;
  onCancelAppointment?: (visitId: string) => void;
  onAppointmentUpdated?: (
    close: boolean,
    alertMessage?: string,
    alertType?: NotificationType,
  ) => void;
}

export const AppointmentDetail = ({
  patient,
  visitId,
  postCall,
  careGivenStatus,
  onCancelAppointment,
  onAppointmentUpdated,
}: AppointmentDetailProps) => {
  const { updateCareStatus, cancelAppointment, isPreVisitCompleted } =
    useVisits();
  const { pushAlert, clearAlerts } = useContext(AlertContext);

  const [selectedAppt, setSelectedAppt] = useState<Visit | null>(null);
  const [beforeAppt, setBeforeAppt] = useState<boolean | undefined>(true);
  const [beforeMidnight, setBeforeMidnight] = useState<boolean | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [note, setNote] = useState<CarePlanNote | false>();
  const [careGiven, setCareGiven] = useState<boolean | undefined>(
    careGivenStatus,
  );

  const {
    updateCarePlanNote,
    createCarePlanNote,
    getCarePlanNotes,
    getCarePlanNote,
  } = useNotes();

  const capitalizedAppt =
    selectedAppt?.appointmentType &&
    selectedAppt?.appointmentType.charAt(0).toUpperCase() +
      selectedAppt?.appointmentType.slice(1) +
      " Visit";

  const title =
    selectedAppt?.visitType +
    " with " +
    selectedAppt?.providerName +
    " - " +
    capitalizedAppt;

  const [changesMade, setChangesMade] = useState(false);
  const [attachmentsChanged, setAttachmentsChanged] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState("");
  const [attachments, setAttachments] = useState<Attachment[]>(
    (note && note?.attachments) || [],
  );
  const [logs, setLogs] = useState<Log[]>();
  const filePickerRef = useRef<HTMLInputElement>(null);
  const modifiedAttachments = useRef<AttachmentsRef>({
    added: [],
    deleted: [],
  });
  const { getProvider, getProviderById } = useProvider();
  const [updNote, setUpdNote] = useState<CarePlanNote>();
  const [loadingAttachments, setLoadingAttachments] = useState<Attachment[]>(
    [],
  );

  useEffect(() => {
    if (patient) {
      getCarePlanNotes(patient.id).then((notes) => {
        const visitNote = _.find(notes, (note) => note.id === visitId);
        if (visitNote) setNote(visitNote);
      });
    }
  }, []);

  useEffect(() => {
    if (loadingAttachments.length > 0 && note) {
      getCarePlanNote(patient.id, note.id).then((_carePlanNote) =>
        setUpdNote(_carePlanNote[0]),
      );
    }
  }, [loadingAttachments]);

  useEffect(() => {
    if (updNote) {
      if (updNote.attachments.length > 0) {
        loadingAttachments.map((loadingAttachment) => {
          updNote.attachments.map((updNoteAttachment) => {
            if (updNoteAttachment.name === loadingAttachment.name) {
              window.open(updNoteAttachment.url);
              const updLoadingAttachments: Attachment[] = JSON.parse(
                JSON.stringify(loadingAttachments),
              );
              const attachmentToRemove =
                updLoadingAttachments.indexOf(loadingAttachment);
              updLoadingAttachments.splice(attachmentToRemove, 1);
              setLoadingAttachments(updLoadingAttachments);
            }
          });
        });
      }
    }
  }, [updNote]);

  const getLastModifiedDate = async (note: CarePlanNote) => {
    const date = subHours(new Date(note.modifiedOn.split(" ").join("T")), 7);
    const author = await getProvider(note.modifiedBy);
    const timeStamp = `${format(date, "MMMM dd, yyyy")} at ${format(
      date,
      "h:mm a",
    )}`;
    const authorStamp = `${note.history.length > 0 ? "Edited" : "Created"} by ${
      author.name
    }`;
    return { timeStamp, authorStamp, provider: author };
  };

  useEffect(() => {
    if (note) {
      if (note.history.length > 0) {
        Promise.all(
          note?.history.map(async (log, i): Promise<Log> => {
            const date = subHours(
              new Date(log.modifiedOn.split(" ").join("T")),
              7,
            );
            const author = await getProvider(log.modifiedBy);
            const timeStamp = `${format(date, "MMMM dd, yyyy")} at ${format(
              date,
              "h:mm a",
            )}`;
            const authorStamp = `${i === 0 ? "Created" : "Edited"} by ${
              author.name
            }`;
            return { timeStamp, authorStamp, provider: author };
          }),
        ).then(async (res) => {
          getLastModifiedDate(note).then((modifiedDate) =>
            res.push(modifiedDate),
          );
          setLogs(res);
          setDescription(note.description);
          setAttachments(note.attachments);
        });
      } else {
        getLastModifiedDate(note).then((modifiedDate) =>
          setLogs([modifiedDate]),
        );
        setDescription(note.description);
        setAttachments(note.attachments);
      }
    }
  }, [note]);

  useEffect(() => () => setChangesMade(true), [attachments, careGiven]);
  useEffect(() => () => {
    setAttachmentsChanged(true), [attachments];
  });

  useEffect(() => {
    if (postCall) {
      checkPostCallCareGivenPopup();
    }
  }, [postCall]);

  const checkPostCallCareGivenPopup = async () => {
    const providerId = selectedAppt?.providerId;

    if (providerId) {
      const providerInfo = await getProviderById(providerId);

      const visible =
        providerInfo?.providerType !== ProviderType.HealthCoach &&
        providerInfo?.providerType !== ProviderType.InsideSpecialist &&
        providerInfo?.providerType !== ProviderType.OutsideSpecialist &&
        [
          VisitType.HealthCoachFollowUpVirtual,
          VisitType.HealthCoachFollowUpInPerson,
          VisitType.HealthCoachVisitVirtual,
          VisitType.HealthCoachVisitInPerson,
        ].includes(selectedAppt?.visitType);

      return;
    }
  };

  const handleDeleteAttachment = (attachment: Attachment) => {
    if (
      _.find(
        modifiedAttachments.current?.added,
        (_attachment) => _attachment.name === attachment.name,
      )
    )
      modifiedAttachments.current.added =
        modifiedAttachments.current.added.filter(
          (_attachment) => _attachment.name !== attachment.name,
        );
    else modifiedAttachments.current.deleted.push(attachment);

    setAttachments(
      attachments.filter((_attachment) => _attachment.name !== attachment.name),
    );
  };

  const handleAddAttachmentClick = () => {
    filePickerRef.current?.click();
  };

  const handleFilePick = async (_files: FileList | null) => {
    const files = Object.values(_files || {}).filter(
      (file) =>
        !attachments.map((attachment) => attachment.name).includes(file.name),
    );
    modifiedAttachments.current?.added.push(
      ...files.map((file) => ({ name: file.name, file: file })),
    );
    setAttachments([
      ...attachments,
      ...files.map((file) => ({
        name: file.name,
        url: file.name, // here used only for react key prop for rendering
      })),
    ]);
  };

  const handleCancelAppointment = async () => {
    const result = await cancelAppointment(visitId, patient.id);
    if (result && onCancelAppointment) onCancelAppointment(visitId);
    return result;
  };

  const onNoteUpdated = () => {
    getCarePlanNotes(patient.id).then((notes) => {
      for (let i = 0; i < notes.length; i++) {
        if (notes[i].id === visitId) setNote(notes[i]);
      }
    });
  };

  // TODO: do not show no care given confirmation modal if past day of appt or was status already provided
  const handleSaveClick = async (noCareConfirmed?: boolean) => {
    const onDone = (noteResult?: boolean) => {
      setUploading(false);
      onNoteUpdated();
      if (onAppointmentUpdated) onAppointmentUpdated(false);
      if (typeof noteResult !== "undefined")
        noteResult
          ? pushAlert(
              "Care coordination notes and or attachments successfully updated.",
              "success",
            )
          : pushAlert(
              "Care coordination notes and or attachments could not be updated. Please try again.",
              "danger",
            );
    };

    if ((attachmentsChanged || note) && !description) {
      pushAlert("Note description required.", "danger");
      return;
    } else clearAlerts();

    setUploading(true);
    const careResult = await updateCareStatus(visitId, careGiven, "");
    let noteResult;
    if (note) {
      const updatedNote = {
        id: note.id,
        userId: patient.id,
        description,
        title,
      };
      noteResult = await updateCarePlanNote(
        updatedNote,
        modifiedAttachments.current.added,
        modifiedAttachments.current.deleted,
        onDone,
      );
    } else if (!note && description !== "") {
      const newNote = {
        id: visitId,
        userId: patient.id,
        description,
        title,
      };
      noteResult = await createCarePlanNote(
        newNote,
        modifiedAttachments.current.added,
        onDone,
      );
    } else onDone();

    await Promise.all([careResult, noteResult]);
  };

  const { getOneVisitData } = useVisits();

  useEffect(() => {
    if (patient && visitId) {
      getOneVisitData(patient.id, visitId).then((_visit) => {
        setSelectedAppt(_visit);
      });
    }
  }, [patient, visitId]);

  useEffect(() => {
    if (selectedAppt) {
      setLoading(false);
      setBeforeAppt(
        isBefore(
          new Date(),
          new Date(selectedAppt.dateTime.split(" ").join("T")),
        ),
      );
      setBeforeMidnight(
        isSameDay(
          new Date(),
          new Date(selectedAppt.dateTime.split(" ").join("T")),
        ),
      );
    }
  }, [selectedAppt]);

  const getFriendlyDate = (unfriendlyDate: string) => {
    const date = new Date(unfriendlyDate.split(" ").join("T"));
    return format(date, "ccc, MMM d, h:mmaaa");
  };

  const handleChangeDescription = (value: string) => {
    setChangesMade(true);
    setDescription(value);
  };

  return (
    <div>
      {!selectedAppt || selectedAppt.visitId === "" ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        <AppointmentDetailCard
          onClickCancelAppointment={handleCancelAppointment}
          onAppointmentUpdated={onAppointmentUpdated}
          date={getFriendlyDate(selectedAppt.dateTime)}
          visit={selectedAppt}
          beforeAppt={isBefore(
            new Date(),
            new Date(selectedAppt.dateTime.split(" ").join("T")),
          )}
          preVisitInfoComplete={isPreVisitCompleted(selectedAppt)}
          patient={patient || defaultPatient}
        />
      )}

      {selectedAppt?.reason && (
        <div className={styles.reasonBox}>
          <Heading type={"03"} className={styles.reasonTitle}>
            Reason for Visit
          </Heading>
          <Body>{selectedAppt?.reason}</Body>
        </div>
      )}

      <div className={styles.noteSection}>
        <div className={styles.noteBox}>
          <Heading type={"03"} className={styles.noteTitle}>
            Care Coordination Notes
          </Heading>
          <TextInput
            className={styles.descriptionInput}
            value={description}
            placeholder="Add a description of patient progress"
            onChange={handleChangeDescription}
            multiline
            minRows={6}
            maxRows={12}
          />
        </div>
        <div className={styles.authorLog}>
          <Body size={"md"} weight={"bold"} className={styles.logTitle}>
            Author Log
          </Body>
          {logs && logs.length > 0 ? (
            <div className={styles.logs}>
              <div>
                <Body size="sm">{logs[0].timeStamp}</Body>
                <div className={styles.logAuthor}>
                  <Avatar size="xxs" user={logs[0].provider} />
                  <Body
                    className={styles.authorName}
                    size="xs"
                    color="secondary"
                  >
                    {logs[0].authorStamp}
                  </Body>
                </div>
              </div>
              {logs.length > 1 && (
                <div>
                  <Body size="sm">{logs[logs.length - 1].timeStamp}</Body>
                  <div className={styles.logAuthor}>
                    <Avatar size="xxs" user={logs[logs.length - 1].provider} />
                    <Body
                      className={styles.authorName}
                      size="xs"
                      color="secondary"
                    >
                      {logs[logs.length - 1].authorStamp}
                    </Body>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Body size="sm" color="secondary">
              {"No edits have been made to this note"}
            </Body>
          )}
        </div>
      </div>

      <div className={styles.attachmentSection}>
        <Body size={"md"} weight={"bold"} className={styles.logTitle}>
          Attachments (optional)
        </Body>
        <Body size={"sm"} color={"secondary"} className={styles.attachText1}>
          Add files that relate to this patient’s progress description note. You
          can always go back and edit this later.
        </Body>
        <Body
          size={"sm"}
          color={"secondary"}
          weight="bold"
          className={styles.attachText2}
        >
          The maximum number of attachments is 2.
        </Body>
        {attachments.length > 0 && (
          <div className={styles.attachments}>
            {attachments.map((attachment) => (
              <Tag
                key={attachment.url}
                content={attachment.name}
                type="black"
                size="lg"
                Icon={File}
                onDelete={() => handleDeleteAttachment(attachment)}
                onClick={
                  attachment.url !== attachment.name
                    ? () =>
                        setLoadingAttachments([
                          ...loadingAttachments,
                          attachment,
                        ])
                    : undefined
                }
                loading={loadingAttachments.includes(attachment)}
              />
            ))}
          </div>
        )}

        <Button
          label="Add Attachment"
          type="secondary-gray"
          onClick={handleAddAttachmentClick}
          disabled={attachments.length > 1}
          Icon={Paperclip}
        />
        <input
          ref={filePickerRef}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={(e) => {
            handleFilePick(e.target.files);
          }}
        />
      </div>

      <div className={styles.careGivenSection}>
        <div className={styles.saveBtn}>
          <Button
            type={"primary"}
            label={"Save"}
            loading={uploading}
            disabled={!changesMade}
            onClick={handleSaveClick}
          />
        </div>
        <div style={{ height: 64 }} />
      </div>
    </div>
  );
};
