import React from "react";

import { useAttendeeStatus } from "amazon-chime-sdk-component-library-react";
import { StyledRosterAttendee } from "./Styled";
interface Props {
  attendeeId: string;
}

const RosterAttendeeWrapper: React.FC<Props> = ({ attendeeId }) => {
  const { videoEnabled } = useAttendeeStatus(attendeeId);
  return <StyledRosterAttendee attendeeId={attendeeId} />;
};

export default RosterAttendeeWrapper;
