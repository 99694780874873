import React, { useState, memo } from "react";
import { PatientNames } from "../../lib/apis/patient";
import styles from "./style.module.css";
import clsx from "clsx";
import MembershipTypeLabel from "../../components/MembershipTypeLabel/MembershipTypeLabel";
import { Button } from "../../components/button/Button";
import { Chat } from "phosphor-react";
import { useBaseContext } from "../../containers/_base/Base";
import { useChats } from "../../lib/hooks/useChats";
import { ChatPreview } from "../../lib/interfaces/messaging";
import { useHistory } from "react-router-dom";
import { MembershipTier } from "../../lib/interfaces/user";
import { formatDate, formatDateTime } from "../../lib/util/date";
interface PatientResultsListProps {
  patient: PatientNames;
}

const PatientResultsList: React.FC<PatientResultsListProps> = ({ patient }) => {
  const { openPopoutChat } = useBaseContext();
  const [newMsgLoading, setNewMsgLoading] = useState<string[]>([]);
  const { getChatWithUsers } = useChats();
  const history = useHistory();
  const onNewMsgClick = (patient: PatientNames, template: boolean) => {
    setNewMsgLoading((currentNewMsgLoading) => [
      ...currentNewMsgLoading,
      patient.userId,
    ]);

    const patients = {
      id: patient?.userId,
      firstName: patient?.firstName,
      lastName: patient?.lastName,
      title: "Patient",
      name: `${patient?.firstName} ${patient?.lastName}`,
    };
    getChatWithUsers(patients).then((res) => {
      const chat: ChatPreview | undefined = res?.chat;
      if (chat) {
        openPopoutChat &&
          openPopoutChat(
            {
              id: chat.id,
              participants: chat.participants,
              regarding: chat.regarding,
            },
            template
              ? `Hello ${patient.firstName}! How has your week been? I noticed that you haven't booked an appointment yet. I'm reaching out to make sure you're alright and see how I can help.`
              : "",
          );
      }
      setNewMsgLoading((currentNewMsgLoading) =>
        currentNewMsgLoading.filter(
          (patientId) => patientId !== patient.userId,
        ),
      );
    });
  };

  const handlePatientDetail = (userId: string) => {
    history.push(`patient/${userId}/careplan`);
  };

  return (
    <div>
      <div className={styles.patientResultList}>
        <div
          className={clsx(
            styles.patientResultListItem,
            styles.patientResultListItem1,
          )}
        >
          <div className={clsx(styles.names)}>
            <div
              className={clsx(styles.link)}
              onClick={() => handlePatientDetail(patient.userId)}
            >
              {[patient.firstName, patient.lastName].join(" ")}
            </div>
          </div>
        </div>
        <div className={styles.visitTierWrapper}>
          <div
            className={clsx(
              styles.patientResultListItem,
              styles.patientResultListItem2,
            )}
          >
            {patient?.futureVisitDatetime ? (
              <>
                <span className={clsx(styles.visitStyle)}>Next Visit</span>
                <div className={clsx(styles.visiteDateStyle)}>
                  {formatDateTime(patient?.futureVisitDatetime)}
                </div>
              </>
            ) : patient?.latestPastVisitDatetime ? (
              <>
                <span className={clsx(styles.visitStyle)}>Last Visit:</span>
                <div className={clsx(styles.visiteDateStyle)}>
                  {formatDate(patient?.latestPastVisitDatetime)}
                </div>
              </>
            ) : (
              <div className={clsx(styles.visiteDateStyle)}>
                No visits scheduled
              </div>
            )}
          </div>
          <div
            className={clsx(
              styles.patientResultListItem,
              styles.patientResultListItem3,
            )}
          >
            {patient.membershipTier && (
              <MembershipTypeLabel
                label={patient.membershipTier}
                type={
                  patient.membershipTier === MembershipTier.TIER_ONE
                    ? "light"
                    : "dark"
                }
              />
            )}
          </div>
        </div>
        <div
          className={clsx(
            styles.patientResultListItem,
            styles.patientResultListItem4,
          )}
        >
          <Button
            onClick={() => onNewMsgClick(patient, false)}
            label="Message"
            Icon={Chat}
            type="secondary"
            size="small"
            loading={newMsgLoading.includes(patient.userId)}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(PatientResultsList);
