import axios, { AxiosResponse } from "axios";

interface ResponseData {
  userId: string;
  providerIds: string[];
}

interface CareTeamResponse extends AxiosResponse {
  data: ResponseData[];
}

export interface CareTeamPayload {
  userId: string;
  providerIds: string[];
}

export const fetchCareTeam = async (
  userId: string,
  controller?: AbortController,
) => {
  const result = await axios
    .get<CareTeamResponse, CareTeamResponse>(`/careteam?userId=${userId}`, {
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result.data;
};

export const postCareTeam = async (payload: CareTeamPayload) => {
  await axios({
    method: "post",
    url: "/careteam",

    data: payload,
  })
    .catch((err) => err)
    .then((res) => res);
  return "success";
};
