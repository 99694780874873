import { differenceInMilliseconds, addMinutes } from "date-fns";
import { Phone } from "phosphor-react";

import { Button } from "../button/Button";
import { useEffect, useState } from "react";

const APPT_BUFFER_MS = 600000;

interface JoinMeetingButtonProps {
  visitDateTime: string;
  visitDurationMinutes?: number;
  handleClick: () => void;
}
const JoinMeetingButton = ({
  visitDateTime,
  visitDurationMinutes = 60,
  handleClick,
}: JoinMeetingButtonProps) => {
  const [buttonEnabled, setButtonEnabled] = useState(false);
  useEffect(() => {
    let enabledTimer: NodeJS.Timeout;
    let disabledTimer: NodeJS.Timeout;
    const now = new Date(),
      visitStartDateTimeFormatted = new Date(
        visitDateTime.split(" ").join("T")
      ),
      visitEndDateTimeFormatted = new Date(
        addMinutes(visitStartDateTimeFormatted, visitDurationMinutes)
      ),
      msFromStartOfAppt = differenceInMilliseconds(
        visitStartDateTimeFormatted,
        now
      ),
      msFromApptBuffer = msFromStartOfAppt - APPT_BUFFER_MS,
      msFromEndOfAppt = differenceInMilliseconds(
        visitEndDateTimeFormatted,
        now
      );
    if (msFromApptBuffer <= 0 && msFromEndOfAppt > 0) {
      setButtonEnabled(true);
    } else if (msFromEndOfAppt <= 0) {
      setButtonEnabled(false);
    } else {
      enabledTimer = setTimeout(() => setButtonEnabled(true), msFromApptBuffer);
      disabledTimer = setTimeout(
        () => setButtonEnabled(false),
        msFromEndOfAppt
      );
    }

    return () => {
      clearTimeout(enabledTimer);
      clearTimeout(disabledTimer);
    };
  }, []);

  return (
    <Button
      size="small"
      type="primary"
      label="Join Video Visit"
      disabled={!buttonEnabled}
      Icon={Phone}
      iconPosition="left"
      onClick={handleClick}
    />
  );
};

export { JoinMeetingButton };
