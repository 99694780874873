import {
  darkTheme,
  lightTheme,
} from "amazon-chime-sdk-component-library-react";

const {
  buttons: lightThemeButtons,
  fonts: lightThemeFonts,
  globalStyle: lightThemeGlobalStyle,
} = lightTheme;
const {
  roster: darkThemeRoster,
  controlBar: darkThemeControlBar,
  videoGrid: darkThemeVideoGrid,
} = darkTheme;

const customTheme = {
  ...lightTheme,
  globalStyle: {
    ...lightThemeGlobalStyle,
    bgd: "#F5F5F4",
  },
  fonts: {
    ...lightThemeFonts,
    body: "proximanova",
    monospace: "proximanova",
  },
  buttons: {
    ...lightThemeButtons,
    primary: {
      ...lightThemeButtons.primary,
      static: {
        ...lightThemeButtons.primary.static,
        bgd: "#003F5C",
      },
    },
    secondary: {
      ...lightThemeButtons.secondary,
      static: {
        ...lightThemeButtons.secondary.static,
        bgd: "#ECF5F9",
        text: "#1A6D93",
      },
    },
    icon: {
      ...darkTheme.buttons.icon,
      static: {
        ...darkTheme.buttons.icon.static,
        bgd: "#ffffff",
        text: "#000000",
      },
    },
  },
  roster: {
    ...darkThemeRoster,
    bgd: "#003F5C",
    primaryText: "#FFFFFF",
    secondaryText: "#FFFFFF",
    headerBorder: "#003F5C",
  },
  videoGrid: {
    ...darkThemeVideoGrid,
    bgd: "#292524",
  },
  controlBar: {
    ...darkThemeControlBar,
    bgd: "#292524",
  },
  popOver: {
    ...darkTheme.popOver,
    itemBgd: "#003F5C",
    menuBgd: "#003F5C",
    menuBorder: "1.5px solid #001824",
    itemText: "#ffffff",
    active: {
      itemBgd: "#1A6D93",
      itemText: "#FFFFF",
    },
  },
};

export { customTheme };
