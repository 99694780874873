import { Heading } from "amazon-chime-sdk-component-library-react";
import styled from "styled-components";

export const StyledLayout = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-width: 85rem;
  padding: 2rem;
  margin: auto;

  @media (max-width: 760px) {
    border-right: unset;
    align-items: unset;
    justify-content: unset;
  }
`;

export const StyledHeading = styled(Heading)`
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.15px;
`;
