import { createContext } from "react";
import { UserClaims } from "@okta/okta-auth-js";
import { SelectedChat } from "../../pages/messages/Messages";
import { NotificationType } from "../../components/alertBanner/AlertBanner";
import { ProviderInfo } from "../interfaces/user";
import { Client } from "@twilio/conversations";
import { PatientDetails } from "../../pages/patient/PatientResults";
import { Visit } from "../interfaces/visits";

export interface BaseContext {
  twilio: Client | undefined;
  user: UserClaims | undefined;
  userInfo: ProviderInfo | undefined;
  allProviders: ProviderInfo[] | undefined;
  visitsWithProvider: Visit[];
  openPopoutChat?: (chat: SelectedChat, template?: string) => void;
  closePopoutChat?: (chat: SelectedChat) => void;
  popoutChat?: SelectedChat[];
  searchQuery?: PatientDetails | undefined;
  setSearchQuery?: React.Dispatch<
    React.SetStateAction<PatientDetails | undefined>
  >;
}

export const BaseContext = createContext<BaseContext>({
  twilio: undefined,
  user: undefined,
  userInfo: undefined,
  allProviders: undefined,
  visitsWithProvider: [],
  openPopoutChat: undefined,
  closePopoutChat: undefined,
  popoutChat: [],
  searchQuery: undefined,
});

export interface AlertContext {
  pushAlert: (
    message: string,
    type?: NotificationType,
    closeable?: boolean,
    withIcon?: boolean,
  ) => void;
  clearAlerts: () => void;
}

export const AlertContext = createContext<AlertContext>({
  pushAlert: () => undefined,
  clearAlerts: () => undefined,
});
