import axios, { AxiosResponse } from "axios";

interface Reading {
  code?: string;
  value?: string | number;
  unit?: string;
}

export interface ResponseData {
  id: string;
  code?: string;
  context?: string;
  dateTime?: string;
  practitionerId?: string;
  practitionerName?: string;
  readings?: Reading[];
  status?: string;
}

interface VitalsResponse extends AxiosResponse {
  data: ResponseData[];
}

export const fetchVitals = async (
  userId: string,
  controller?: AbortController,
) => {
  const result = await axios
    .get<VitalsResponse, VitalsResponse>(`/patient/vitals?userId=${userId}`, {
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result.data;
};
