import { useHistory, useParams } from "react-router-dom";

import { ReactNode, useEffect } from "react";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";

const MeetingHomeRedirect = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const { visitId, userId } = useParams<{ visitId: string; userId: string }>();
  const meetingManager = useMeetingManager();

  useEffect(() => {
    if (!meetingManager.meetingSession) {
      history.push(`/video-chat/${visitId}/${userId}`);
    }
  }, []);

  return <>{children}</>;
};

export { MeetingHomeRedirect };
