import clsx from "clsx";
import { Paperclip, PaperPlaneTilt } from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import { Button } from "../button/Button";
import { TextInput } from "../textInput/TextInput";
import styles from "./style.module.css";

interface ChatBottomBarProps {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  onResourceClick: () => void;
  onSendClick: () => Promise<any>;
}

export const ChatBottomBar = ({
  value,
  onChange,
  onResourceClick,
  onSendClick,
  className,
}: ChatBottomBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const sendRef = useRef<HTMLButtonElement>(null);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    inputRef.current?.focus();
    document.addEventListener("keydown", enterToSubmit);
    return () => document.removeEventListener("keydown", enterToSubmit);
  }, []);

  const enterToSubmit = (e: KeyboardEvent) => {
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      document.activeElement === inputRef.current
    )
      sendRef.current?.click();
  };

  const onSendButtonClick = async () => {
    setSending(true);
    await onSendClick().then((res) => {
      setSending(false);
      return res;
    });
  };

  return (
    <div className={clsx(styles.chatBottomBar, className)}>
      <Button type="tertiary" onClick={onResourceClick} Icon={Paperclip} />
      <TextInput
        disabled={sending}
        refObj={inputRef}
        value={value}
        multiline
        maxRows={4}
        minRows={1}
        onChange={onChange}
        placeholder="Type a new message"
      />
      <Button
        refObj={sendRef}
        type="tertiary"
        onClick={onSendButtonClick}
        Icon={PaperPlaneTilt}
      />
    </div>
  );
};
