import { useLocalVideo } from "amazon-chime-sdk-component-library-react";
import {
  VideoIcon,
  VideoDisabledIcon,
} from "../../../../../components/customIcons/CustomIcons";

import { useVideoInputOptions } from "../../../hooks/useVideoInputOptions";

import { BaseControl } from "../BaseControl";

const VideoControl = () => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { options, disabled } = useVideoInputOptions();

  return (
    <BaseControl
      onClick={toggleVideo}
      icon={
        !isVideoEnabled || disabled ? (
          <VideoDisabledIcon size={18} />
        ) : (
          <VideoIcon size={20} />
        )
      }
      label="Camera"
      options={options}
      popOverHeader="Video Settings"
      disabled={disabled}
    />
  );
};

export { VideoControl };
