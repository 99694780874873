import { useEffect, useMemo, useState } from "react";
import { AlertBanner } from "../../../components/alertBanner/AlertBanner";
import {
  format,
  isToday,
  isYesterday,
  isThisYear,
  isWithinInterval,
  subDays,
  startOfToday,
} from "date-fns";
import { Button } from "../../../components/button/Button";
import { Switch } from "../../../components/switch/Switch";
import { Body } from "../../../components/typography/body/Body";
import { useProvider } from "../../../lib/hooks/useProvider";
import styles from "./style.module.css";
import { Alert } from "../../../lib/hooks/useAlerts";
import { getLocalDateTime } from "../../../lib/util/date";

export interface CarePlanFooterProps {
  onPublish: () => Promise<any>;
  sendToEpic: boolean;
  onSendToEpicChange: () => void;
  alerts: Alert[];
  onCloseAlert: (id: string) => void;
  unpublishedChanges: boolean;
  publishedBy?: string;
  publishedOn?: string;
  sentToEpicOn?: string;
  publishButtonDisabled: boolean;
  publishButtonLoading: boolean;
}

export const CarePlanFooter = ({
  onPublish,
  sendToEpic,
  onSendToEpicChange,
  alerts,
  onCloseAlert,
  publishedBy,
  publishedOn,
  unpublishedChanges,
  publishButtonDisabled,
  sentToEpicOn,
  publishButtonLoading,
}: CarePlanFooterProps) => {
  const { getProvider } = useProvider();
  const [publisher, setPublisher] = useState<string>();

  useEffect(() => {
    if (publishedBy) {
      getProvider(publishedBy).then((provider) => setPublisher(provider.name));
    }
    return () => setPublisher(undefined);
  }, [publishedBy]);

  const publishedOnFormatted = useMemo(() => {
    if (publishedOn) return format(getLocalDateTime(publishedOn), "M/dd/yy");
    else return "";
  }, [publishedOn]);

  const sentToEpicOnFormatted = useMemo(() => {
    if (sentToEpicOn) {
      const date = getLocalDateTime(sentToEpicOn);
      if (isToday(date)) return `Today ${format(date, "h:mm a")}`;
      else if (isYesterday(date)) return `Yesterday ${format(date, "h:mm a")}`;
      else if (
        isWithinInterval(date, {
          start: subDays(startOfToday(), 6),
          end: startOfToday(),
        })
      )
        return format(date, "eeee, h:mm a");
      else if (!isThisYear(date)) return format(date, "MMM d, yyyy, h:mm a");
      else return format(date, "E, MMM d, h:mm a");
    }
  }, [sentToEpicOn]);

  return (
    <div className={styles.carePlanFooter}>
      <div className={styles.publishingOptionsContainer}>
        <div className={styles.sendToEpicSwitchContainer}>
          <Switch
            active={sendToEpic}
            onToggle={onSendToEpicChange}
            label="Send to Epic"
          />
          <Body
            className={styles.lastSeenLabel}
            size="xs"
            color="secondary"
          >{`Last sent: ${sentToEpicOnFormatted || "Never"}`}</Body>
        </div>
      </div>
      <Button
        loading={publishButtonLoading}
        onClick={onPublish}
        label="Publish Care Plan"
        disabled={publishButtonDisabled}
      />
      <div className={styles.alertsContainer}>
        {unpublishedChanges && (
          <AlertBanner
            type="info"
            message={`There are unpublished changes. Last published by ${
              publisher || "..."
            } ${publishedOnFormatted}`}
          />
        )}
        {alerts?.map((alert) => (
          <AlertBanner
            type={alert.type}
            message={alert.message}
            onClose={alert.closeable ? () => onCloseAlert(alert.id) : undefined}
          />
        ))}
      </div>
    </div>
  );
};
