import { ReactNode } from "react";
import { MarkLogo } from "../customIcons/CustomIcons";
import {
  StyledSidebar,
  StyledLogoContainer,
  StyledNavButtonContainer,
} from "./Styled";

export const Sidebar = ({ children }: { children?: ReactNode }) => {
  return (
    <StyledSidebar>
      <StyledLogoContainer>
        <MarkLogo size={56} />
      </StyledLogoContainer>
      <StyledNavButtonContainer>{children}</StyledNavButtonContainer>
    </StyledSidebar>
  );
};
