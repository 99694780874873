import { EmergencyContact, Insurance, Settings } from "./patientInfo";
import { ChartItem } from "../../lib/hooks/useCarePlan";
import { Visit } from "./visits";
import { Provider, ProviderType } from "../apis/types/provider.types";

export interface UserInfo {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  photo?: string;
  title: string;
  userId?: string;
}
export enum MembershipTier {
  TIER_ONE = "1.0",
  TIER_TWO = "2.0",
}
export enum MembershipTierLabel {
  TIER_ALL = "All Members",
  TIER_ONE = "1.0  Members",
  TIER_TWO = "2.0  Members",
}
export interface PatientInfo extends UserInfo {
  identifiers?: {
    wprinternal?: string;
    epic?: string;
  };
  dateOfBirth?: string;
  first_name?: string;
  last_name?: string;
  addressLine1?: string;
  addressLine2?: string;
  emergencyContact?: EmergencyContact;
  coverage?: Insurance[];
  assignedSex?: string;
  city?: string;
  state?: string;
  zip?: string;
  mobile?: string;
  email?: string;
  settings?: Settings;
  checkedIn?: boolean;
  premiumMember?: boolean;
  atmanMember?: boolean;
  hasCarePlan?: boolean;
  hasCreditCard?: boolean;
  alreadyScheduledAppointments?: boolean;
  userId?: string;
  insuranceVerificationStatus?: string;
  membershipTier?: MembershipTier | null;
  avatarUrl?: string;
}

export interface PatientCardInfo extends UserInfo {
  nextAppointment?: Visit;
  lastAppointment?: Visit;
  progressData?: ChartItem[];
  chartStartDate?: string;
  chartEndDate?: string;
  settings?: Settings;
  careTeam?: string[]; //list of care team id's
  alreadyScheduledAppointments?: boolean;
}

export type ProviderInfo = UserInfo & Provider;

export const defaultPatient: PatientInfo = {
  id: "",
  name: "",
  firstName: "",
  lastName: "",
  title: "Patient",
};

export const defaultProvider: ProviderInfo = {
  id: "",
  name: "",
  firstName: "",
  lastName: "",
  title: "Provider",
  providerId: "",
  photoUrl: "",
  preferredName: "",
  email: "",
  providerType: ProviderType.PrimaryCarePhysician,
};
