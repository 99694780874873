import clsx from "clsx";
import { v4 as uuid } from "uuid";
import { Checkbox } from "../checkbox/Checkbox";
import { FeaturedIcon } from "../featuredIcon/FeaturedIcon";
import { Tag, TagProps } from "../tag/Tag";
import { Check, Image } from "phosphor-react";
import { Body } from "../typography/body/Body";
import { Heading } from "../typography/heading/Heading";
import { ResourceType } from "../../lib/interfaces/resource";
import styles from "./style.module.css";

export interface InteractiveCardProps {
  title: string;
  content: string;
  caption?: string;
  tags: TagProps[];
  type: ResourceType;
  checked: boolean;
  shared?: boolean;
  image?: string;
  onClick: (selected: boolean) => void;
  onContentClick?: () => void;
  className?: string;
}

export const InteractiveCard = ({
  title,
  type,
  content,
  caption,
  tags,
  checked,
  shared,
  image,
  onClick,
  onContentClick,
  className,
}: InteractiveCardProps) => (
  <div
    className={clsx(
      styles.interactiveCard,
      checked && styles.checked,
      className,
    )}
    onClick={() => onClick(!checked)}
  >
    <Checkbox checked={checked} onToggle={() => onClick(!checked)} />
    <div className={styles.contentContainer}>
      <Body size="xs" color="secondary" className={styles.type}>
        {type}
      </Body>
      <Heading className={styles.cardTitle} type="03">
        {title}
      </Heading>
      <Body size="xs" className={styles.contentBody}>
        {content}
      </Body>
      <Body size="xs" className={styles.caption} color="secondary">
        {caption}
      </Body>
      {tags.length > 0 && (
        <div className={styles.tagsContainer}>
          {tags.map((tag) => (
            <Tag key={uuid()} content={tag.content} />
          ))}
        </div>
      )}
    </div>
    <div
      className={styles.imageContainer}
      onClick={(e) => {
        e.stopPropagation();
        if (onContentClick) onContentClick();
      }}
    >
      {image && type !== "video" ? (
        <img src={image} width={120} height={120} />
      ) : (
        <FeaturedIcon
          Icon={Image}
          size="md"
          type="transparent"
          iconColor="white"
        />
      )}
      {shared && (
        <div className={styles.imageOverlay}>
          <FeaturedIcon
            size="xs"
            Icon={Check}
            type="white"
            className={styles.sharedIcon}
          />
        </div>
      )}
    </div>
  </div>
);
