import { Books } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { Drawer } from "../../../components/drawer/Drawer";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { ResourceCard } from "../../../components/resourceCard/ResourceCard";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { useResources } from "../../../lib/hooks/useResources";
import { ResourcePreview } from "../../../lib/interfaces/resource";
import { ResourceDetails } from "../resourceDetails/ResourceDetails";
import { CarePlan } from "../../../lib/interfaces/carePlan";
import _ from "lodash";
import styles from "./style.module.css";

interface CarePlanResourcesProps {
  pillars: CarePlan["pillars"];
}

export const CarePlanResources = ({ pillars }: CarePlanResourcesProps) => {
  const [expandedResource, setExpandedResource] = useState<string>();
  const [resources, setResources] = useState<ResourcePreview[]>([]);
  const [resourceDetailVisible, setResourceDetailVisible] = useState(false);
  const { getMetadata } = useResources();

  useEffect(() => {
    let selectedResources: string[] = [];
    if (pillars?.move?.resourceIds)
      selectedResources.push(...pillars.move.resourceIds);
    if (pillars?.calm?.resourceIds)
      selectedResources.push(...pillars.calm.resourceIds);
    if (pillars?.nourish?.resourceIds)
      selectedResources.push(...pillars.nourish.resourceIds);

    selectedResources = _.uniq(selectedResources);

    Promise.all(
      selectedResources.map(async (id) => {
        return await getMetadata(id, { idOnly: true });
      }),
    ).then(setResources);
  }, [pillars]);

  const hasResources = useMemo(() => resources.length > 0, [resources]);

  useEffect(() => {
    if (expandedResource) setResourceDetailVisible(true);
  }, [expandedResource]);

  return (
    <div className={styles.resources}>
      <div className={styles.header}>
        <FeaturedIcon Icon={Books} type="orange" />
        <div>
          <Heading type="02">All Resources</Heading>
          <Body
            className={styles.caption}
            size="md"
            weight="medium"
            color="secondary"
          >
            Browse resources shared across all pillars
          </Body>
        </div>
      </div>
      {hasResources ? (
        <div className={styles.content}>
          {resources.map((resource) => (
            <div className={styles.resourceCardContainer} key={resource.id}>
              <ResourceCard
                title={resource.title}
                caption={resource.domain}
                photo={resource.imageUrl}
                isVideo={resource.type === "video"}
                onClick={() => setExpandedResource(resource.id)}
              />
            </div>
          ))}
        </div>
      ) : (
        <Body size="md" color="secondary">
          Add resources for your patient
        </Body>
      )}
      <Drawer
        title="Resources"
        visible={resourceDetailVisible}
        onClose={() => setResourceDetailVisible(false)}
      >
        <ResourceDetails resourceId={expandedResource} />
      </Drawer>
    </div>
  );
};
