import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./style.module.css";
import { PatientOption } from "../../lib/interfaces/input";
import { usePatient } from "../../lib/hooks/usePatient";
import { ParamProps, PatientNames } from "../../lib/apis/patient";
import { useHistory } from "react-router-dom";
import { PatientSearchSelect } from "../../components/patientSearchSelect/PatientSearchSelect";
import { BaseContext } from "../../lib/context/context";
import { MembershipTierLabel } from "../../lib/interfaces/user";
import { logError } from "../../lib/util/logger";
import clsx from "clsx";
import { ProviderType } from "../../lib/apis/types/provider.types";
interface PatientSearchProps {
  headingText?: string;
  controlAligment?: string;
  placeholder?: string;
  handlePatientsChange?: (value: any) => void;
  value?: PatientOption | PatientOption[];
  disabled?: boolean;
  width?: number;
  label?: string;
  className?: string;
  isPatientSearchPage: boolean;
}

const PatientSearch: React.FC<PatientSearchProps> = ({
  headingText,
  controlAligment = "start",
  placeholder,
  handlePatientsChange,
  value,
  disabled,
  width,
  label,
  className,
  isPatientSearchPage,
}) => {
  const multiple = Array.isArray(value);
  const [loading, setLoading] = useState(true);
  const [patients, setPatients] = useState<PatientNames[]>([]);
  const { setSearchQuery, visitsWithProvider, userInfo } =
    useContext(BaseContext);
  const { getAllPatientNames } = usePatient();
  const history = useHistory();
  const [selectMembership, setSelectMembership] = useState("");
  const [params, setParams] = useState<ParamProps>({
    searchText: "",
    pageNumber: 1,
    pageSize: 50,
  });
  const [totalCount, setTotalCount] = useState(0);
  const abortControllerRef = useRef<AbortController | null>(null);

  const handleSelectedPatientsChange = (
    selectedPatient: PatientOption | null | undefined,
    selected: boolean = false,
  ) => {
    let userObject: any;
    if (selectedPatient?.value !== "" && !multiple) {
      userObject = {
        ...patients?.find((user) => user.userId === selectedPatient?.value),
        membershipTier: selectedPatient?.membershipTier,
      };
    } else {
      userObject = {
        ...selectedPatient,
        membershipTier: selectMembership ?? "",
      };
    }

    if (isPatientSearchPage) {
      if (setSearchQuery) {
        setSearchQuery(userObject);
      }
      history.push(`patient`);
    } else if (
      !isPatientSearchPage &&
      typeof handlePatientsChange === "function"
    ) {
      if (!userObject.value && userObject.firstName) {
        (userObject.name = `${userObject.firstName} ${userObject.lastName}`),
          (userObject.value = userObject.userId);
      }

      if (!multiple) {
        handlePatientsChange(userObject);
      } else {
        if (
          selected &&
          value.filter((selected) => userObject.value === selected.value)
            .length === 0
        ) {
          handlePatientsChange([...value, userObject]);
        } else {
          const updatedValue = value.filter(
            (patient) => patient.value !== userObject.value,
          );
          handlePatientsChange(updatedValue);
        }
      }
    }
  };

  const handleInputChange = (inputText: string) => {
    setPatients([]);
    setTotalCount(0);
    setParams((prev) => ({ ...prev, searchText: inputText, pageNumber: 1 }));
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
  };

  const searchControlsStyle = {
    justifyContent: controlAligment as "start" | "center" | "end",
    display: "flex",
  };

  const getAllPatientSearchList = (signal: AbortSignal) => {
    setLoading(true);
    getAllPatientNames({ ...params, membershipTier: selectMembership }, signal)
      .then((res) => {
        setPatients((prevPatients) => {
          const existingPatientIds = new Set(
            prevPatients.map((patient) => patient.userId),
          );
          const newPatients: PatientNames[] =
            res?.patients?.filter(
              (item: PatientNames) => !existingPatientIds.has(item.userId),
            ) ?? [];
          const newCombinedPatientList = [...prevPatients, ...newPatients];
          let patientList: PatientNames[] = [];
          if (
            userInfo &&
            userInfo?.providerType === ProviderType.OutsideSpecialist
          ) {
            patientList = newCombinedPatientList.filter((patient) =>
              visitsWithProvider.find(
                (visit) => visit.userId === patient.userId,
              ),
            );
            return patientList;
          }
          return [...prevPatients, ...newPatients];
        });
        setTotalCount(res?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          logError("Failed to fetch patient names:", params, error);
          setPatients([]);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (params.pageNumber === 1) {
      setPatients([]);
      setTotalCount(0);
    }

    if (params.searchText !== "") {
      if (patients.length < totalCount || totalCount == 0) {
        getAllPatientSearchList(
          abortControllerRef.current?.signal ?? new AbortController().signal,
        );
      }
    } else if (!isPatientSearchPage) {
      getAllPatientSearchList(
        abortControllerRef.current?.signal ?? new AbortController().signal,
      );
    } else {
      setLoading(false);
    }
  }, [params]);

  const handleMembership = (selectMembership: string) => {
    setSelectMembership(selectMembership);
    setParams((prev) => ({ ...prev, searchText: "" }));
  };

  const loadMoreOptions = useCallback(() => {
    if (patients.length > 0) {
      setParams((prev) => ({ ...prev, pageNumber: params.pageNumber + 1 }));
    }
  }, [patients.length]);

  return (
    <div
      className={clsx(styles.patientSearchContainer, className)}
      style={searchControlsStyle}
    >
      <PatientSearchSelect
        label={label}
        headingText={headingText}
        onSelect={handleSelectedPatientsChange}
        onChange={handleInputChange}
        onSelectMembership={handleMembership}
        options={
          patients
            ? patients.map((patient: PatientNames) => ({
                name: patient.firstName + " " + patient.lastName,
                value: patient.userId,
              }))
            : []
        }
        loading={loading}
        dropdownStyle={styles.dropdownSize}
        searchText={params.searchText || ""}
        selectMembershipTier={{
          name: MembershipTierLabel.TIER_ALL,
          value: "",
        }}
        loadMoreOptions={loadMoreOptions}
        showDropdown={true}
        placeholder={placeholder}
        value={value}
        width={width}
        disabled={disabled}
        setParams={setParams}
        isPatientSearchPage={isPatientSearchPage}
      />
    </div>
  );
};

export default PatientSearch;
