import clsx from "clsx";
import { useState, useMemo } from "react";
import { Body } from "../typography/body/Body";
import { Heading } from "../typography/heading/Heading";
import { AvatarLabelGroup } from "../avatarLabelGroup/AvatarLabelGroup";
import { PatientInfo, ProviderInfo } from "../../lib/interfaces/user";
import { AppointmentType } from "../../lib/apis/types/visitReason.types";
import { Modal } from "../modal/Modal";
import { UserDetails } from "../userDetails/UserDetails";
import styles from "./style.module.css";

export interface NewAppointmentCardProps {
  patient: PatientInfo;
  date: string;
  provider: ProviderInfo;
  appointmentType: AppointmentType;
  visitType: string;
  className?: string;
}

export const NewAppointmentCard = ({
  patient,
  date,
  provider,
  appointmentType,
  visitType,
  className,
}: NewAppointmentCardProps) => {
  const [showProviderModal, setShowProviderModal] = useState(false);

  const titleOverride = useMemo(
    () => (
      <>
        <div style={{ display: "flex" }}>
          <Body size="sm" color="secondary">
            {`${visitType} with `}
          </Body>
          <div
            onClick={() => {
              setShowProviderModal(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <Body size="sm" color="link">
              {provider.name}
            </Body>
          </div>
        </div>
        <Body size="sm" weight="bold" color="secondary">
          {`${appointmentType} Visit`}
        </Body>
      </>
    ),
    [provider, appointmentType, visitType],
  );

  return (
    <div>
      <div className={clsx(styles.base, className)}>
        <div className={styles.row}>
          <Heading
            type="03"
            style={{ width: 417, height: 22, paddingRight: 14 }}
          >
            {date}
          </Heading>
        </div>
        <div className={styles.row}>
          <AvatarLabelGroup
            data-dd-privacy="mask"
            users={[patient]}
            titleOverride={titleOverride}
            linkUser={true}
            openNewTab={true}
          />
          <div className={styles.tags}></div>
        </div>
      </div>
      <Modal
        onCloseModal={() => setShowProviderModal(false)}
        visible={showProviderModal}
        title={"Provider Detail"}
        centerTitle={true}
        className={styles.modal}
      >
        <UserDetails provider={provider} />
      </Modal>
    </div>
  );
};
