import styled from "styled-components";
import { CaretDown, CaretUp } from "phosphor-react";
import { ControlProps } from "../../../types/index";
import { PopOverItem } from "amazon-chime-sdk-component-library-react";

type Props = Pick<ControlProps, "backgroundColor" | "textColor">;

const caretMargin = `marginTop: 3px;`;
export const StyledCaretDown = styled(CaretDown)<Props>`
  ${caretMargin}
  color: ${(props) => (props.textColor ? props.textColor : "#ffffff")};
`;
export const StyledCaretUp = styled(CaretUp)<Props>`
  ${caretMargin}
  color: ${(props) => (props.textColor ? props.textColor : "#ffffff")};
`;

export const StyledPopOverItem = styled(PopOverItem)`
  font-family: ${(props) => props.theme.fonts.body};
  cursor: pointer;
`;
// TODO https://github.com/microsoft/TypeScript/issues/37597
// issue producing DefaultProps error
// @ts-ignore
export const StyledPopOverItemSubheader = styled(StyledPopOverItem)`
  font-size: 0.75rem;
  font-weight: 500;
  pointer-events: none;
`;
