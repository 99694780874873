import { Button, ButtonGroup } from "../../../components/button/Button";
import { Switch } from "../../../components/switch/Switch";
import { ProviderInfo } from "../../../lib/interfaces/user";
import { ProviderType } from "../../../lib/apis/types/provider.types";

export interface FiltersFormProps {
  signedInUser: ProviderInfo;
  assignedToMe: boolean;
  appointmentsWithMe: boolean;
  handleAppointmentsWithMeToggle: () => void;
  handleAssignedToMeToggle: () => void;
  handleFilterApply: () => void;
  handleFilterClearAll: () => void;
}

export const FiltersForm = ({
  signedInUser,
  assignedToMe,
  appointmentsWithMe,
  handleAppointmentsWithMeToggle,
  handleAssignedToMeToggle,
  handleFilterApply,
  handleFilterClearAll,
}: FiltersFormProps) => {
  return (
    <div>
      <Switch
        active={assignedToMe}
        label={"Assigned To Me"}
        disabled={signedInUser.providerType !== ProviderType.CareCoordinator}
        onToggle={() => {
          handleAssignedToMeToggle();
        }}
      />
      <div style={{ height: "16px" }}></div>
      <Switch
        active={appointmentsWithMe}
        label={"Appointments With Me"}
        disabled={
          signedInUser.providerType === ProviderType.CareCoordinator ||
          signedInUser.providerType === ProviderType.OutsideSpecialist
        }
        onToggle={() => {
          handleAppointmentsWithMeToggle();
        }}
      />
      <div style={{ height: "448px" }}></div>
      <ButtonGroup>
        <Button
          type="secondary-gray"
          disabled={false}
          onClick={() => {
            handleFilterClearAll();
          }}
          label="Clear All"
        />
        <Button
          type="primary"
          disabled={false}
          onClick={() => {
            handleFilterApply();
          }}
          label="Apply Filters"
        />
      </ButtonGroup>
    </div>
  );
};
