import axios, { AxiosResponse } from "axios";
import { VisitReason } from "./types/visitReason.types";
import { GenderType } from "./types/gender.types";

interface VisitReasonsResponse extends AxiosResponse {
  data: VisitReason[];
}

export const fetchVisitReasons = async (): Promise<VisitReason[]> => {
  const result = await axios
    .get<VisitReasonsResponse, VisitReasonsResponse>("/visit/reasons")
    .catch((err) => err);
  return result.data;
};

export const fetchVisitReasonsByGender = async (
  genderType: GenderType,
): Promise<VisitReason[]> => {
  const result = await axios
    .post<VisitReasonsResponse, VisitReasonsResponse>("/visit/reasons", {
      genderType,
    })
    .catch((err) => err);
  return result.data;
};
