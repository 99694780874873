import { Video } from "../../containers/videoChat/Video";
import { useParams } from "react-router-dom";
import {
  LoggerProvider,
  MeetingProvider,
  GlobalStyles,
  BackgroundBlurProvider,
  BackgroundReplacementProvider,
} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import { VideoChatContextProvider } from "../../containers/videoChat/context/VideoChatContext";
import { customTheme } from "../../containers/videoChat/theme/theme";
import { StyledVideoCallLayout } from "./Styled";
import { ConsoleLogger, LogLevel } from "amazon-chime-sdk-js";

export interface VideoCallProps {
  visitId: string;
}

export const VideoCall = () => {
  const { visitId, userId } = useParams<{ visitId: string; userId: string }>();
  const logger = new ConsoleLogger("MyLogger", LogLevel.WARN);

  return (
    <StyledVideoCallLayout>
      <ThemeProvider theme={customTheme}>
        <GlobalStyles />
        <LoggerProvider logger={logger}>
          <MeetingProvider>
            <BackgroundBlurProvider options={{ filterCPUUtilization: 40 }}>
              <BackgroundReplacementProvider>
                <VideoChatContextProvider>
                  <Video visitId={visitId} userId={userId} />
                </VideoChatContextProvider>
              </BackgroundReplacementProvider>
            </BackgroundBlurProvider>
          </MeetingProvider>
        </LoggerProvider>
      </ThemeProvider>
    </StyledVideoCallLayout>
  );
};
