import { Heading } from "../../../components/typography/heading/Heading";
import styles from "./style.module.css";
import { MembershipTier, PatientInfo } from "../../../lib/interfaces/user";
import AccountInformationCard from "../../../components/accountInformationCard/AccountInformationCard";

interface AccountInformationProps {
  patient: PatientInfo;
}

const membershipTierOptions = [
  { name: "Not Subscribed", value: null },
  { name: MembershipTier.TIER_ONE, value: MembershipTier.TIER_ONE },
  { name: MembershipTier.TIER_TWO, value: MembershipTier.TIER_TWO },
];

const AccountInformation = ({ patient }: AccountInformationProps) => {
  return (
    <div>
      <Heading className={styles.heading} type="02">
        Account Information
      </Heading>
      <div className={styles.cardsContainer}>
        <AccountInformationCard
          membershipTierOptions={membershipTierOptions}
          patient={patient}
        />
      </div>
    </div>
  );
};

export default AccountInformation;
