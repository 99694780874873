import { CalendarBlank } from "phosphor-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useExperianVerificationCode } from "../../lib/hooks/useExperianVerificationCode";
import { Button } from "../button/Button";
import { Modal } from "../modal/Modal";
import { TextInput } from "../textInput/TextInput";
import { Heading } from "../typography/heading/Heading";
import { GeneratedCodeModal } from "./GeneratedCodeModal";

import styles from "./style.module.css";

export const ExperianBypassCodeForm = () => {
  const [modalData, setModalData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const { getVerificationCode } = useExperianVerificationCode();
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setBirthDate("");
  };

  const onCloseModal = () => {
    setShowCodeModal(false);
    setModalData({
      firstName: "",
      lastName: "",
      email: "",
      birthDate: "",
    });
  };

  const fetchVerificationCode = async () => {
    setLoading(true);

    const result = await getVerificationCode(
      firstName,
      lastName,
      encodeURIComponent(email),
      birthDate,
    );

    if (result?.code) {
      setVerificationCode(result.code);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (verificationCode && !showCodeModal) {
      setShowCodeModal(true);
    }
  }, [verificationCode]);

  const submit = async () => {
    await fetchVerificationCode();
    setModalData({ firstName, lastName, email, birthDate });
    clearForm();
  };

  const validateEmail = useCallback(() => {
    const emailRegex = /\S+@\S+\.\S+/;
    const emailValid = emailRegex.test(email);

    setEmailValid(emailValid);
  }, [email]);

  useEffect(() => {
    validateEmail();
  }, [email, validateEmail]);

  const isFormValid = useMemo(() => {
    return firstName && lastName && birthDate && email && emailValid;
  }, [firstName, lastName, birthDate, email, emailValid]);

  return (
    <div className={styles.formWrapper}>
      <Heading>Generate Code</Heading>
      <p>
        Enter the exact information the patient will use when creating the
        account.
      </p>

      <div className={styles.fieldsWrapper}>
        <TextInput
          className={styles.inputField}
          label="First Name*"
          value={firstName}
          onChange={setFirstName}
        />

        <TextInput
          className={styles.inputField}
          label="Last Name*"
          value={lastName}
          onChange={setLastName}
        />

        <TextInput
          className={styles.inputField}
          label="Birth Date*"
          type="date"
          clearable
          placeholder="MM/DD/YYYY"
          value={birthDate}
          onChange={(date) => {
            setBirthDate(date);
          }}
          RightIcon={CalendarBlank}
        />

        <TextInput
          error={email?.length > 0 && !emailValid}
          errorMessage="Please enter a valid Email."
          className={styles.inputField}
          label="Email Address*"
          value={email}
          onChange={setEmail}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          loading={loading}
          disabled={!isFormValid}
          label="Generate Code"
          onClick={submit}
        />
      </div>
      <Modal
        dismissable={true}
        visible={showCodeModal}
        onCloseModal={onCloseModal}
        title="Generated Code"
      >
        <GeneratedCodeModal
          code={verificationCode}
          patientName={`${modalData.firstName} ${modalData.lastName}`}
          patientBirthDate={modalData.birthDate}
          patientEmail={modalData.email}
        />
      </Modal>
    </div>
  );
};
