import axios, { AxiosResponse } from "axios";
import { EmergencyContact, Insurance } from "../interfaces/patientInfo";
import { Visit } from "../interfaces/visits";
import { ResponseData as Provider } from "../../lib/apis/provider";

export interface ParamProps {
  searchText: string | undefined;
  pageNumber: number;
  pageSize: number;
  membershipTier?: string | undefined;
}
export interface AllResponseData {
  settings?: {
    General?: {
      Membership?: string;
    };
    Consent?: {
      Privacy?: string;
      Terms?: string;
      HIPAA?: string;
      Telehealth?: string;
      Membership?: string;
    };
  };
  userId: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  mobile?: string;
  email?: string;
  photo?: string;
  alreadyScheduledAppointments?: boolean;
}

export interface SingleResponseData extends AllResponseData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  assignedSex: string;
  identifiers: {
    name: string;
    value: string;
  }[];
  coverage: Insurance[];
  emergencyContact: EmergencyContact;
}

interface AllPatientsResponse extends AxiosResponse {
  data: AllResponseData[];
}

export interface PatientNames {
  userId: string;
  firstName: string;
  lastName: string;
  latestPastVisitDatetime?: string;
  futureVisitDatetime?: string;
  membershipTier?: string;
}

interface PatientNamesResponse extends AxiosResponse {
  data: {
    totalCount: number;
    patients: PatientNames[];
  };
}

export type UserDetailsResponse = {
  visits: {
    future: Visit[];
    past: Visit[];
  };
  careteam: Provider[];
  progress: any | null;
};

interface PatientResponse extends AxiosResponse {
  data: SingleResponseData;
}

export const fetchAllPatients = async () => {
  const result = await axios
    .get<AllPatientsResponse, AllPatientsResponse>("/patient/all")
    .catch((err) => err);

  return result.data ?? [];
};

export const fetchAllPatientNames = async (
  { searchText, pageNumber, pageSize, membershipTier }: ParamProps,
  signal?: AbortSignal,
) => {
  const params: any = {
    searchText,
    pageNumber,
    pageSize,
    ...(membershipTier && { membershipTier }),
  };
  const result = await axios
    .get<PatientNamesResponse, PatientNamesResponse>("/patient/names", {
      params: params,
      signal,
    })
    .catch((err) => err);
  return result.data ?? [];
};

export const fetchPatient = async (id: string) => {
  const result = await axios
    .get<PatientResponse, PatientResponse>("/patient", {
      params: {
        userId: id,
      },
    })
    .catch((err) => err);

  return result.data ?? null;
};

export const fetchPatientDetails = async (userId: string) => {
  const result = await axios
    .get<
      UserDetailsResponse,
      UserDetailsResponse
    >(`/patient/details?userId=${userId}`)
    .catch((err) => err);

  return result.data;
};

export const updateIDVerificationStatus = async (
  userId: string,
  status: object,
) => {
  const result = await axios
    .post<string>(`/patient/verification-status?userId=${userId}`, { status })
    .catch((err) => err);
  return result.data;
};

export const updateInsuranceVerificationStatus = async (
  userId: string,
  status: object,
) => {
  const result = await axios
    .post<string>(`/patient/insurance-status?userId=${userId}`, { status })
    .catch((err) => err);
  return result.data;
};

export const getInsuranceVerificationStatus = async (userId: string) => {
  const result = await axios
    .get<string>(`/patient/insurance-status?userId=${userId}`)
    .catch((err) => err);
  return result.data;
};

export const updateMembershipTier = async (
  userId: string,
  membershipTier: string,
) => {
  const result = await axios
    .post<string>(`/patient/update-membership-tier`, {
      userId,
      membershipTier,
    })
    .catch((err) => err);
  return result.data;
};
