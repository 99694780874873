import clsx from "clsx";
import { IconProps, X } from "phosphor-react";
// import { Spinner } from '../spinner/Spinner'
import { Body } from "../typography/body/Body";
import styles from "./style.module.css";

type TagSize = "md" | "lg";
type IconPosition = "left" | "right";
export type TagType = "neutral" | "info" | "success" | "danger" | "black";

const Spinner = () => (
  <svg
    className={styles.spinner}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.418 16 16 12.4185 16 8C16 3.58154 12.418 0 8 0C3.58203 0 0 3.58154 0 8C0 12.4185 3.58203 16 8 16ZM8 14C11.3125 14 14 11.3135 14 8C14 4.68652 11.3125 2 8 2C4.6875 2 2 4.68652 2 8C2 11.3135 4.6875 14 8 14Z"
      fill="#D6D3D1"
    />
    <path
      d="M4.9375 15.3911C5.60077 15.6651 6.29491 15.8486 7.0026 15.9376C7.55057 16.0064 8 15.5523 8 15C8 14.4477 7.54924 14.0085 7.00462 13.9168C6.55972 13.842 6.12366 13.7169 5.70312 13.5435C4.97656 13.2417 4.31641 12.7998 3.75781 12.2427C3.19922 11.6855 2.75781 11.0239 2.45703 10.2959C2.28385 9.87701 2.15859 9.44063 2.08347 8.99535C1.9916 8.45076 1.55228 8 1 8C0.447715 8 -0.00644753 8.44943 0.0624434 8.9974C0.15144 9.7053 0.33499 10.3995 0.609375 11.0615C1.01172 12.0322 1.60156 12.9141 2.34375 13.6567C3.08594 14.3999 3.96875 14.9888 4.9375 15.3911Z"
      fill="#1A6E95"
    />
  </svg>
);

export interface TagProps {
  onDelete?: () => void;
  onClick?: () => void;
  content: string;
  size?: TagSize;
  Icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  iconPosition?: IconPosition;
  type?: TagType;
  className?: string;
  loading?: boolean;
}

export const Tag = ({
  onDelete,
  onClick,
  content,
  size = "md",
  Icon,
  iconPosition = "left",
  type = "neutral",
  className,
  loading,
}: TagProps) => (
  <div className={clsx(styles.tag, styles[size], styles[type], className)}>
    <div
      onClick={onClick}
      className={clsx(styles.tagInner, onClick && styles.clickable)}
    >
      {Icon && iconPosition === "left" && <Icon size={12} weight="bold" />}
      <Body
        size={"sm"}
        weight="medium"
        className={clsx(styles[type], loading && styles.loading)}
      >
        {content}
      </Body>
    </div>
    {loading && <Spinner />}
    {onDelete ? (
      <button onClick={onDelete} style={{ cursor: "pointer" }}>
        <X size={12} weight="bold" />
      </button>
    ) : (
      Icon && iconPosition === "right" && <Icon size={12} weight="bold" />
    )}
  </div>
);
