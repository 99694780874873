import {
  MeetingStatus,
  useLocalVideo,
  useMeetingStatus,
} from "amazon-chime-sdk-component-library-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useStartVideo = () => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const location = useLocation<{ isUserVideo: boolean }>();
  const { isUserVideo } = location.state ?? {};
  const status = useMeetingStatus();

  useEffect(() => {
    if (!(status === MeetingStatus.Succeeded)) {
      return;
    }
    if (isVideoEnabled !== isUserVideo) {
      toggleVideo();
    }
  }, [status]);
};

export { useStartVideo };
