import { useContext, useEffect, useState } from "react";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { RecordsAppointmentCard } from "../../../components/recordsAppointmentCard/RecordsAppointmentCard";
import { Visit } from "../../../lib/interfaces/visits";
import { useVisits } from "../../../lib/hooks/useVisits";
import { format, isValid } from "date-fns";
import { PatientInfo, ProviderInfo } from "../../../lib/interfaces/user";
import { BaseContext } from "../../../lib/context/context";
import { Spinner } from "../../../components/spinner/Spinner";
import styles from "./style.module.css";
import { Modal } from "../../../components/modal/Modal";
import { UserDetails } from "../../../components/userDetails/UserDetails";

export interface UpcomingAppointmentProps {
  patient: PatientInfo;
  futureVisits: Visit[] | undefined;
}

export const UpcomingAppointment = ({
  patient,
  futureVisits,
}: UpcomingAppointmentProps) => {
  const { findClosestFutureDateTime } = useVisits();
  const { allProviders } = useContext(BaseContext);
  const [upcomingAppt, setUpcomingAppt] = useState<Visit>();
  const [hasCreditCard, setHasCreditCard] = useState<boolean>();
  const [provider, setProvider] = useState<ProviderInfo>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showProviderModal, setShowProviderModal] = useState(false);

  useEffect(() => {
    if (futureVisits) {
      setUpcomingAppt(findClosestFutureDateTime(futureVisits));
    }
  }, [futureVisits]);

  useEffect(() => {
    if (upcomingAppt && allProviders) {
      const targetProvider = allProviders.find(
        (provider) => provider.id === upcomingAppt.providerId,
      );

      if (targetProvider) {
        setProvider(targetProvider);
      }

      setLoading(false);
    }
  }, [upcomingAppt, allProviders]);

  const getFriendlyDate = (unfriendlyDate: string) => {
    const browserFriendlyDate =
      unfriendlyDate.substring(0, 10) + "T" + unfriendlyDate.substring(10 + 1);
    if (!isValid(new Date(browserFriendlyDate))) {
      return "";
    }
    const date = new Date(browserFriendlyDate);
    return format(date, "ccc, MMM d, h:mmaaa");
  };

  return (
    <div>
      <Heading className={styles.heading} type="02">
        Upcoming Appointment
      </Heading>
      {!loading ? (
        <div>
          {typeof upcomingAppt === "undefined" ||
          upcomingAppt.visitId === "" ? (
            <Body size="md" color="disabled">
              {"There are no upcoming appointments"}
            </Body>
          ) : (
            <RecordsAppointmentCard
              date={
                upcomingAppt?.dateTime !== ""
                  ? getFriendlyDate(upcomingAppt.dateTime)
                  : ""
              }
              provider={provider}
              appointmentType={upcomingAppt?.appointmentType ?? ""}
              visitType={upcomingAppt?.visitType ?? ""}
              insurance={
                patient?.coverage && patient.coverage.length !== 0
                  ? true
                  : false
              }
              creditCard={hasCreditCard ? hasCreditCard : false}
              healthQuestionnaire={
                upcomingAppt?.questionnairesComplete
                  ? upcomingAppt.questionnairesComplete
                  : false
              }
              consentForms={
                typeof patient?.settings?.Consent?.HIPAA !== "undefined" &&
                patient.settings?.Consent.HIPAA !== "" &&
                typeof patient?.settings?.Consent?.Membership !== "undefined" &&
                patient.settings?.Consent.Membership !== "" &&
                typeof patient?.settings?.Consent?.Privacy !== "undefined" &&
                patient.settings?.Consent.Privacy !== "" &&
                typeof patient?.settings?.Consent?.Telehealth !== "undefined" &&
                patient.settings?.Consent.Telehealth !== "" &&
                typeof patient?.settings?.Consent?.Terms !== "undefined" &&
                patient.settings?.Consent.Terms !== ""
              }
              onProviderClick={() => {
                setShowProviderModal(true);
              }}
            />
          )}
        </div>
      ) : (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      <Modal
        onCloseModal={() => setShowProviderModal(false)}
        visible={showProviderModal}
        title={"Provider Detail"}
        centerTitle={true}
        className={styles.modal}
      >
        <UserDetails provider={provider} />
      </Modal>
    </div>
  );
};
