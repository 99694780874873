import clsx from "clsx";
import { useEffect } from "react";
import { MouseEvent, useRef, useState } from "react";
import { CaretDown, IconProps, XCircle } from "phosphor-react";
import { Body } from "../typography/body/Body";
import { Dropdown } from "../dropdown/Dropdown";
import { Option } from "../../lib/interfaces/input";
import { format, isValid } from "date-fns";
import styles from "./style.module.css";

interface AppointmentDropdownProps {
  placeholder?: string;
  hint?: string;
  LeftIcon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  disabled?: boolean;
  error?: boolean;
  width?: number | string;
  options?: Option[];
  optional?: boolean;
  value: Option;
  displayedValue?: string;
  onChange?: (option: any, date: string) => void;
  today: boolean;
  dayOfWeek: string;
  date: string;
  initialNumberAvailable: number;
  className?: string;
}

export const AppointmentDropdown = ({
  placeholder,
  hint,
  LeftIcon,
  error = false,
  disabled,
  width,
  optional,
  options = [],
  value,
  displayedValue,
  onChange = () => {},
  today = true,
  dayOfWeek,
  date,
  initialNumberAvailable,
  className,
}: AppointmentDropdownProps) => {
  // used to update container style when child input component in focused otherwise not possible in pure css
  const [focused, setFocused] = useState(false);
  const [numberAvailable, setNumberAvailable] = useState<number>(
    initialNumberAvailable,
  );
  const selectRef = useRef<any>();

  useEffect(() => {
    //if another date has been selected, set the number of available appointments to its original value
    if (value.value.dateTime) {
      if (value.value.dateTime.slice(0, 10) !== date.slice(0, 10)) {
        setNumberAvailable(initialNumberAvailable);
      } else if (value.value.dateTime.slice(0, 10) === date.slice(0, 10)) {
        setNumberAvailable(initialNumberAvailable - 1);
      }
    } else {
      setNumberAvailable(initialNumberAvailable);
    }
  }, [value, initialNumberAvailable]);

  const handleOptionSelect = (option: Option, selected: boolean) => {
    onChange(option, date);
    //setNumberAvailable(initialNumberAvailable - 1)
    selectRef.current.blur();
  };

  const clearOption = (e: MouseEvent) => {
    e.stopPropagation();
    onChange({ name: "", value: "" }, "");
    // selectRef.current.blur()
  };

  const getFriendlyDate = (unfriendlyDate: string) => {
    const browserFriendlyDate =
      unfriendlyDate.substring(0, 10) + "T" + unfriendlyDate.substring(10 + 1);
    if (!isValid(new Date(browserFriendlyDate))) {
      return "";
    }
    const date = new Date(browserFriendlyDate);
    return format(date, "MM/dd");
  };

  return (
    <div
      className={clsx(styles.select, className)}
      style={width ? { flexGrow: 0, width: width } : undefined}
    >
      <div
        onClick={() => (!disabled ? selectRef.current.focus() : null)}
        className={clsx(
          styles.valueContainer,
          focused && styles.focused,
          error && styles.error,
          disabled && styles.disabled,
        )}
      >
        <div className={styles.selectedValue}>
          <div className={styles.row}>
            {today ? (
              <Body size="xs" color="link" className={styles.today}>
                Today
              </Body>
            ) : (
              <Body weight="bold" className={styles.today}>
                {dayOfWeek}
              </Body>
            )}
            <div className={clsx(styles.icon, styles.iconRight)}>
              <CaretDown size={24} />
            </div>
          </div>
          {today && (
            <Body weight="bold" className={styles.dayOfWeek}>
              {dayOfWeek}
            </Body>
          )}
          <Body
            weight="bold"
            className={clsx(
              today && styles.dateToday,
              !today && styles.dateNotToday,
            )}
          >
            {getFriendlyDate(date)}
          </Body>
          <Body color="secondary" className={styles.numberAvailable}>
            {numberAvailable} available
          </Body>
          <input
            readOnly
            ref={selectRef}
            disabled={disabled}
            onFocus={() => (!disabled ? setFocused(true) : null)}
            onBlur={() => setFocused(false)}
            style={{ opacity: 0 }}
          />
        </div>
        {optional && value.value !== "" && (
          <button onClick={clearOption} className={clsx(styles.clearButton)}>
            <XCircle weight="fill" size={20} />
          </button>
        )}
      </div>

      {focused && options && (
        <Dropdown
          options={options}
          selectedValue={value.id}
          onOptionSelect={handleOptionSelect}
          width={150}
        />
      )}

      {hint && (
        <Body
          className={styles.hint}
          size="sm"
          weight="regular"
          color={error ? "danger" : "secondary"}
        >
          {hint}
        </Body>
      )}
    </div>
  );
};
