import experianCodeApi, { ExperianCodeResponse } from "../apis/experianCode";
import { format } from "date-fns";
import { useContext } from "react";
import { AlertContext } from "../context/context";

export const useExperianVerificationCode = () => {
  const { pushAlert } = useContext(AlertContext);

  const getVerificationCode = async (
    firstName: string,
    lastName: string,
    email: string,
    dateOfBirth: string,
  ): Promise<ExperianCodeResponse> => {
    const apiFormattedDate = format(new Date(dateOfBirth), "MM-dd-yyyy");

    try {
      const result = await experianCodeApi.fetchExperianCode({
        firstName,
        lastName,
        email,
        dateOfBirth: apiFormattedDate,
      });
      return result;
    } catch (error: any) {
      pushAlert(`Failed to get verification code: ${error.message}`, "danger");
    }

    return {
      code: "",
    };
  };

  return {
    getVerificationCode,
  };
};
