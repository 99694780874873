import { useToggleLocalMute } from "amazon-chime-sdk-component-library-react";

import {
  MicrophoneIcon,
  MicrophoneDisabledIcon,
} from "../../../../../components/customIcons/CustomIcons";
import { useAudioInputAndOutputOptions } from "../../../hooks/useAudioInputAndOutputOptions";
import { DeviceSelectionHeader } from "../DeviceSelectionHeader";
import { StyledMicSelection } from "../Styled";

import MicrophoneActivityPreview from "./MicrophoneActivityPreview";

const MicrophoneDevices = () => {
  const { muted, toggleMute } = useToggleLocalMute();
  const { disabled } = useAudioInputAndOutputOptions();
  return (
    <div>
      <DeviceSelectionHeader
        Icon={muted || disabled ? MicrophoneDisabledIcon : MicrophoneIcon}
        text={`Join ${muted || disabled ? "Muted" : "Unmuted"}`}
        active={!muted && !disabled}
        onToggle={toggleMute}
        disabled={disabled}
      />
      <StyledMicSelection label="Select Mic" $disabled={muted || disabled} />
      <MicrophoneActivityPreview />
    </div>
  );
};

export default MicrophoneDevices;
