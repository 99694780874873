import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Avatar } from "../avatar/Avatar";
import packageJson from "../../../package.json";
import { UserInfo } from "../../lib/interfaces/user";
import { MenuButton } from "../menuButton/MenuButton";
import { StyledVersionParagraph, StyledSeparator } from "./Styled";

interface BaseSidebarContentProps {
  messageBadge?: boolean;
  user?: UserInfo;
}
export const BaseSidebarContent = ({
  messageBadge,
  user,
}: BaseSidebarContentProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const currentApp = useMemo(
    () => pathname.split("/").filter(Boolean)[0],
    [pathname]
  );

  const signedInUser = useMemo(() => {
    return (
      user || {
        id: "",
        name: "",
        firstName: "",
        lastName: "",
        title: "",
      }
    );
  }, [user]);

  const handleAvatarClick = () => {
    history.push("/availability");
  };
  return (
    <>
      <li>
        <MenuButton
          onClick={() => history.push("/scheduling")}
          type={"calendar"}
          active={currentApp === "scheduling"}
        />
      </li>
      <li>
        <MenuButton
          onClick={() => history.push("/messages")}
          type={"messages"}
          badge={messageBadge}
          active={currentApp === "messages"}
        />
      </li>
      <li>
        <MenuButton
          onClick={() => history.push("/patientsearch")}
          type={"patientsearch"}
          active={currentApp === "patientsearch" || currentApp === "patient"}
        />
      </li>
      <StyledSeparator />
      <li>
        <MenuButton
          onClick={() => history.push("/notifications")}
          type={"notifications"}
          active={currentApp === "notifications"}
        />
      </li>
      <li>
        <MenuButton onClick={handleAvatarClick} type="avatar">
          <Avatar user={signedInUser} size={"lg"} title={"Profile"} />
        </MenuButton>
      </li>
      <li>
        <StyledVersionParagraph>v{packageJson.version}</StyledVersionParagraph>
      </li>
    </>
  );
};
