import { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { MeetingHomeRedirect } from "./containers/MeetingHomeRedirect";
import { useVideoChat } from "./context/VideoChatContext";
import DeviceSetup from "./views/DeviceSetup";
import Meeting from "./views/Meeting";
import Home from "./views/Home";

interface IVideoProps {
  visitId: string;
  userId: string;
}
const Video = ({ visitId, userId }: IVideoProps) => {
  const { path } = useRouteMatch();
  const { setMeetingId, setAttendeeId } = useVideoChat();

  useEffect(() => {
    if (visitId) setMeetingId(visitId);
    if (userId) setAttendeeId(userId);
  }, [visitId, userId]);

  return (
    <Switch>
      <Route path={`${path}/meeting`}>
        <MeetingHomeRedirect>
          <Meeting />
        </MeetingHomeRedirect>
      </Route>
      <Route path={`${path}/devices`}>
        <MeetingHomeRedirect>
          <DeviceSetup />
        </MeetingHomeRedirect>
      </Route>
      <Route path="">
        <Home />
      </Route>
    </Switch>
  );
};

export { Video };
