import { useMemo } from "react";
import {
  useAudioInputs,
  useAudioOutputs,
  useMeetingManager,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";

import {
  MicrophoneIcon,
  MicrophoneDisabledIcon,
} from "../../../../../components/customIcons/CustomIcons";
import { useAudioInputAndOutputOptions } from "../../../hooks/useAudioInputAndOutputOptions";

import { BaseControl } from "../BaseControl";

const MicrophoneControl = () => {
  const { muted, toggleMute } = useToggleLocalMute();
  const { options, disabled } = useAudioInputAndOutputOptions();

  return (
    <BaseControl
      onClick={toggleMute}
      icon={
        muted || disabled ? (
          <MicrophoneDisabledIcon size={20} />
        ) : (
          <MicrophoneIcon size={20} />
        )
      }
      label="Mic"
      options={options}
      popOverHeader="Audio Settings"
      disabled={disabled}
    />
  );
};
export default MicrophoneControl;
