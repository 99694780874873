import React from "react";

import DeviceSelection from "../../components/DeviceSelection";

import { StyledHeading, StyledLayout } from "./Styled";

const DeviceSetup: React.FC = () => {
  return (
    <StyledLayout>
      <StyledHeading level={4}>
        Choose your video and audio settings
      </StyledHeading>
      <DeviceSelection />
    </StyledLayout>
  );
};

export default DeviceSetup;
