import { useOktaAuth } from "@okta/okta-react";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import resourceAPI from "../apis/resources";
import { Resource, ResourcePreview } from "../interfaces/resource";

interface ResourceCalls {
  previews: {
    [id: string]: ResourcePreview;
  };
}

export const useResources = () => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();

  const getResources = async (): Promise<Resource[]> => {
    if (accessToken) {
      const res = await resourceAPI.fetchAll();
      const resources = res.data;
      if (resources) {
        resources.forEach((resource: any) => {
          resource.id = String(resource.id);
          resource.author = [resource.author, resource.author2]
            .filter(Boolean)
            .join(", ");
          resource.authorCredential = [
            resource.authorCredential,
            resource.author2Credential,
          ]
            .filter(Boolean)
            .join(", ");
          resource.tags = resource.tags.sort();
        });
        return resources as Resource[];
      } else return [];
    } else return [];
  };

  const getResource = async (
    id: string,
    controller?: AbortController,
  ): Promise<Resource> => {
    if (accessToken) {
      const res = await resourceAPI.fetch(id, controller);
      let resource;
      if (res?.data !== "cancelled") resource = res?.data?.[0];

      if (resource) {
        resource.author = [resource.author, resource.author2]
          .filter(Boolean)
          .join(", ");
        resource.tags = resource.tags.sort();
        resource.id = resource.id.toString();
        return resource as Resource;
      }
    }

    return {
      id: uuid(),
      title: "Title",
      previewDescription: "",
      image: "",
      imageCaption: "",
      author: "Author",
      authorCredential: "",
      author2: "",
      author2Credential: "",
      body: "",
      image2: "",
      image2Caption: "",
      body2: "",
      image3: "",
      image3Caption: "",
      body3: "",
      tags: [],
      type: "article",
      content: [],
    } as Resource;
  };

  const getMetadata = async (
    url: string,
    options?: { idOnly: boolean },
  ): Promise<ResourcePreview> => {
    if (accessToken) {
      let _url = url;
      if (options?.idOnly) {
        const baseUrl = await getCustomResourceUrl();
        if (baseUrl) _url = `${baseUrl}${url}`;
      }
      const res = await resourceAPI.fetchMetadata(_url);
      const data = await res?.data;
      if (data) {
        const metadata: ResourcePreview = {
          id: _.last(data.url.split("/")) as string,
          type: data.type,
          title: data.title,
          domain: data.domain,
          imageUrl: data.imageUrl,
          url: data.url,
        };
        return metadata;
      }
    }
    return {
      id: uuid(),
      type: "article",
      title: "Title",
      url: "",
      domain: "",
    };
  };

  const getCustomResourceUrl = async () => {
    if (accessToken) {
      const res = await resourceAPI.fetchMetadata(accessToken);
      return res?.data.customResourceBaseUrl;
    } else return undefined;
  };

  return { getResources, getResource, getMetadata, getCustomResourceUrl };
};
