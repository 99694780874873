import { logError } from "./logger";

export const getLocalDateTime = (dateString?: string) => {
  if (dateString) {
    const dateArray = dateString.split(" ").join("T").split(/\D/);
    return Date.UTC(
      Number(dateArray[0]),
      Number(dateArray[1]) - 1,
      Number(dateArray[2]),
      Number(dateArray[3]),
      Number(dateArray[4]),
      Number(dateArray[5]),
    );
  } else return Date.UTC(0, 0, 0, 0, 0, 0);
};

export const addMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * 60000);
};

export const formatDate = (dateString: string): string => {
  try {
    const date = new Date(dateString);
    return date
      .toLocaleString("en-US", { month: "short", day: "numeric" })
      .replace(/^\w/, (c) => c.toUpperCase());
  } catch (error) {
    logError("Failed to format date:", { dateString }, error as Error);
    return "";
  }
};

export const formatTime = (dateString: string): string => {
  try {
    const date = new Date(dateString);
    return date
      .toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .toLowerCase();
  } catch (error) {
    logError("Failed to format time:", { dateString }, error as Error);
    return "";
  }
};

export const getRelativeDay = (dateString: string): string | null => {
  try {
    const date = new Date(dateString);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return "Tomorrow";
    } else {
      return null;
    }
  } catch (error) {
    logError("Failed to get relative day:", { dateString }, error as Error);
    return null;
  }
};

export const formatDateTime = (dateString: string): string => {
  try {
    const relativeDay = getRelativeDay(dateString);
    if (relativeDay) {
      return `${relativeDay} at ${formatTime(dateString)}`;
    } else {
      return `${formatDate(dateString)} at ${formatTime(dateString)}`;
    }
  } catch (error) {
    logError("Failed to format date time:", { dateString }, error as Error);
    return "";
  }
};
