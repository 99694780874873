import clsx from "clsx";
import { Image, Play, X } from "phosphor-react";
import { v4 as uuid } from "uuid";
import { FeaturedIcon } from "../featuredIcon/FeaturedIcon";
import { Body } from "../typography/body/Body";
import styles from "./style.module.css";

interface ResourceCardProps {
  photo?: string;
  title: string;
  caption?: string;
  isVideo?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
  className?: string;
  key?: string;
  deleteLoading?: boolean;
}

export const ResourceCard = ({
  photo,
  title,
  caption,
  isVideo,
  onClick,
  onRemove,
  deleteLoading,
  className,
}: ResourceCardProps) => (
  <div className={clsx(styles.resourceContainer, className)} onClick={onClick}>
    <div className={styles.photoContainer}>
      {photo && !isVideo ? (
        <img src={photo} className={styles.photo} width={180} height={120} />
      ) : (
        <FeaturedIcon
          Icon={Image}
          size="md"
          type="transparent"
          iconColor="white"
        />
      )}
      {(isVideo || onRemove) && (
        <div className={styles.photoOverlay}>
          {[
            isVideo && (
              <FeaturedIcon
                key={uuid()}
                Icon={Play}
                size="sm"
                className={styles.playIcon}
                type="white"
              />
            ),
            onRemove && (
              <button
                className={styles.removeIconContainer}
                key={uuid()}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
              >
                <FeaturedIcon
                  Icon={X}
                  size="sm"
                  type="white"
                  loading={deleteLoading}
                />
              </button>
            ),
          ]}
        </div>
      )}
    </div>
    <div className={styles.resourceFooter}>
      <Body size="sm" weight="bold" className={styles.title} title={title}>
        {title}
      </Body>
      <Body
        size="xs"
        color="secondary"
        className={styles.caption}
        title={caption}
      >
        {caption}
      </Body>
    </div>
  </div>
);
