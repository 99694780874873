import { useMemo, useState } from "react";
import { Heading } from "../typography/heading/Heading";
import styles from "./style.module.css";
import { Button } from "../button/Button";
import { Select } from "../select/Select";
import { Option } from "../../lib/interfaces/input";
import clsx from "clsx";
import { CheckCircle, Prohibit } from "phosphor-react";
import { MembershipTier, PatientInfo } from "../../lib/interfaces/user";
import { usePatient } from "../../lib/hooks/usePatient";

export interface AccountInformationCardProps {
  membershipTierOptions: Option[];
  patient: PatientInfo;
}

const AccountInformationCard = ({
  membershipTierOptions,
  patient,
}: AccountInformationCardProps) => {
  const membershipTier =
    membershipTierOptions.find(
      (item) => item.value === patient?.membershipTier,
    ) ?? membershipTierOptions[0];
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<string>("");
  const { updateMembershipTierStatus } = usePatient();
  const [currentMembershipTier, setCurrentMembershipTier] =
    useState(membershipTier);
  const [selectedMembershipTier, setSelectedMembershipTier] =
    useState(membershipTier);

  const handleSelection = (value: Option) => {
    setSelectedMembershipTier(value);
    setAlert("");
  };

  const getIcon = () => {
    switch (selectedMembershipTier.value) {
      case MembershipTier.TIER_TWO:
        return (
          <div className={clsx(styles.iconbg, styles.iconGreen)}>
            <CheckCircle size={14} />
          </div>
        );
      case null:
        return (
          <div className={clsx(styles.iconbg, styles.iconRed)}>
            <Prohibit size={14} />
          </div>
        );
      default:
        return null;
    }
  };

  const handleUpdateMembershipTierStatus = async () => {
    setLoading(true);
    try {
      if (patient.id) {
        const res = await updateMembershipTierStatus(
          patient.id,
          selectedMembershipTier.value,
        );
        const updatedTier =
          membershipTierOptions.find(
            (item) => item.value === res.membershipTier,
          ) ?? membershipTierOptions[0];
        setCurrentMembershipTier(updatedTier);
        setAlert("Membership tier has been updated.");
      } else {
        setAlert("Patient ID is missing.");
      }
    } catch (error) {
      setAlert("Failed to update membership tier.");
    } finally {
      setLoading(false);
    }
  };

  const statusMessage = useMemo(() => {
    if (selectedMembershipTier.value) {
      return currentMembershipTier.value !== selectedMembershipTier.value
        ? "Membership Tier can be updated to"
        : selectedMembershipTier.value === MembershipTier.TIER_ONE
          ? "Member is Currently not part of the Latest Tier."
          : "Member is Currently Participating in the Latest Tier.";
    }
    return "Membership Tier must be either 1.0 or 2.0.";
  }, [selectedMembershipTier.value, currentMembershipTier.value]);

  const shouldUpdateMembershipTier =
    currentMembershipTier.value !== selectedMembershipTier.value &&
    selectedMembershipTier.value !== null;

  return (
    <div className={styles.base}>
      <div className={styles.selectionContainer}>
        <div>
          <Heading className={styles.heading} type="03">
            Membership Tier
          </Heading>
          <div data-dd-privacy="mask" className={styles.steps}>
            {statusMessage}
          </div>
        </div>
        <div className={styles.rightContainer}>
          {getIcon()}
          <Select
            className={styles.valueContainer}
            options={membershipTierOptions}
            value={selectedMembershipTier}
            onChange={handleSelection}
          />
        </div>
      </div>
      <div className={styles.alertButtonContainer}>
        <div>
          {alert ? (
            <div className={styles.alert}>{alert}</div>
          ) : (
            shouldUpdateMembershipTier && (
              <div className={styles.alertGreen}>
                Updating Membership Tier in Provider App Does Not Update Pricing
                in Stripe.
              </div>
            )
          )}
        </div>
        <div>
          {shouldUpdateMembershipTier && (
            <Button
              data-dd-privacy="mask"
              label="Update Membership Tier"
              onClick={handleUpdateMembershipTierStatus}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountInformationCard;
