import { useOktaAuth } from "@okta/okta-react";
import { useContext, useRef } from "react";
import providerAPI from "../apis/provider";
import { defaultProvider, ProviderInfo } from "../interfaces/user";
import { AlertContext } from "../context/context";
import { ProviderType } from "../apis/types/provider.types";

interface Ref {
  providers: { [id: string]: ProviderInfo };
  pendingCalls: { [id: string]: Promise<any> };
}

export const useProvider = () => {
  const { oktaAuth } = useOktaAuth();
  const { pushAlert } = useContext(AlertContext);
  const accessToken = oktaAuth.getAccessToken();
  const ref = useRef<Ref>({ providers: {}, pendingCalls: {} });

  const getAllProviders = async () => {
    if (accessToken) {
      const _providers = await providerAPI.fetchAll();
      if (!_providers) {
        pushAlert("Failed to get providers", "danger");
        return [];
      }
      const providers = _providers.map((p) => {
        const formattedProvider = {
          ...p,
          id: p.providerId,
          title: p.providerType,
          name: `${p.firstName} ${p.lastName}`,
        };
        ref.current.pendingCalls[formattedProvider.id] =
          Promise.resolve(formattedProvider);

        return formattedProvider;
      });
      return providers;
    } else return [];
  };

  const getNonEpicProviders = async () => {
    if (accessToken) {
      const _providers = await providerAPI.fetchAll();
      if (!_providers) {
        pushAlert("Failed to get non-epic providers", "danger");
        return [];
      }
      const providers = _providers.filter((p) => {
        if (!p.ehrId || p.ehrId === "") {
          const formattedProvider = {
            ...p,
            id: p.providerId,
            title: p.providerType,
            name: `${p.firstName} ${p.lastName}`,
          };
          ref.current.pendingCalls[formattedProvider.id] =
            Promise.resolve(formattedProvider);
          return true;
        }
        return false;
      });
      return providers;
    } else return [];
  };

  const getSpecialistAndHealthCoachProviders = async () => {
    if (accessToken) {
      const _providers = await providerAPI.fetchAll();
      if (!_providers) {
        pushAlert("Failed to get specialists and health coaches", "danger");
        return [];
      }
      const providers = _providers.filter((p) => {
        if (
          p.providerType === ProviderType.InsideSpecialist ||
          p.providerType === ProviderType.OutsideSpecialist ||
          p.providerType === ProviderType.HealthCoach
        ) {
          const formattedProvider = {
            ...p,
            id: p.providerId,
            title: p.providerType,
            name: `${p.firstName} ${p.lastName}`,
          };
          ref.current.pendingCalls[formattedProvider.id] =
            Promise.resolve(formattedProvider);
          return true;
        }
        return false;
      });
      return providers;
    } else return [];
  };

  const getCurrentProvider = async (
    id: string,
    newCall?: boolean,
  ): Promise<ProviderInfo> => {
    if (accessToken) {
      if (!ref.current.pendingCalls[id] || newCall) {
        ref.current.pendingCalls[id] = providerAPI.fetch(id);
      }

      const provider = await ref.current.pendingCalls[id];

      if (provider) {
        const formattedProvider: ProviderInfo = {
          ...provider,
          id: provider.providerId,
          name: `${provider.firstName} ${provider.lastName}`,
          title: provider.providerType,
        };
        return formattedProvider;
      }
    }
    return defaultProvider;
  };

  const getProviderById = async (providerId: string): Promise<ProviderInfo> => {
    const provider = await providerAPI.fetch(providerId);

    return provider;
  };

  return {
    getAllProviders,
    getNonEpicProviders,
    getSpecialistAndHealthCoachProviders,
    getProvider: getCurrentProvider,
    getProviderById,
  };
};
