import { useOktaAuth } from "@okta/okta-react";
import {
  deleteCarePlanNoteAttachment,
  fetchCarePlanNotes,
  fetchCarePlanNote,
  fetchNotes,
  postCarePlanNote,
  postCarePlanNoteAttachment,
  ResponseData,
} from "../apis/notes";
import { Note } from "../interfaces/notes";
import { Attachment, CarePlanNote } from "../interfaces/carePlan";
import { CellProps } from "../../components/table/Table";
import { format } from "date-fns";
import { AddedAttachment } from "../../containers/notes/noteEditForm/NoteEditForm";
import { title } from "process";
import { AlertContext } from "../context/context";
import { useContext } from "react";
import { CancelTokenSource } from "axios";

interface UpdatedCarePlanNote {
  id: string;
  userId: string;
  description: string;
  title: string;
}

type NewCarePlanNote = Omit<UpdatedCarePlanNote, "id">;

export const useNotes = () => {
  const { oktaAuth } = useOktaAuth();
  const { pushAlert } = useContext(AlertContext);
  const accessToken = oktaAuth.getAccessToken();

  const getCarePlanNotes = async (
    userId: string,
    controller?: AbortController,
  ): Promise<CarePlanNote[]> => {
    if (accessToken) {
      const result = await fetchCarePlanNotes(userId, controller);
      if (!result)
        pushAlert(
          "Failed to get patient's notes. Please try refreshing the page.",
          "danger",
        );
      if (!result || result === "cancelled") return [];
      return result;
    }
    return [];
  };

  const getCarePlanNote = async (
    userId: string,
    carenoteId: string,
  ): Promise<CarePlanNote[]> => {
    if (accessToken) {
      const result = await fetchCarePlanNote(userId, carenoteId);
      return result;
    }
    return [];
  };

  const addCarePlanNoteAttachment = async (
    noteId: string,
    attachments: AddedAttachment[],
    onDone: Function,
  ) => {
    if (accessToken) {
      let uploadedCount = 0;
      attachments.forEach(async (_attachment) => {
        const reader = new FileReader();
        reader.readAsDataURL(_attachment.file);
        reader.onload = async () => {
          if (reader.result) {
            const attachment = {
              carenoteId: noteId,
              name: _attachment.name,
              file: reader.result.toString(),
            };
            const result = await postCarePlanNoteAttachment(attachment);
            if (result.status === 200) {
              uploadedCount += 1;
              if (uploadedCount === attachments.length) onDone(true);
            } else onDone(false);
          }
        };
      });
    }
  };

  const removeCarePlanNoteAttachment = async (
    noteId: string,
    attachments: Attachment[],
  ) => {
    if (accessToken)
      await Promise.all(
        attachments.map(async (_attachment) => {
          const attachment = {
            carenoteId: noteId,
            name: _attachment.name,
          };
          await deleteCarePlanNoteAttachment(attachment);
        }),
      );
  };

  const updateCarePlanNote = async (
    note: UpdatedCarePlanNote,
    addAttachments: AddedAttachment[],
    deleteAttachments: Attachment[],
    onDone: Function,
  ) => {
    if (accessToken) {
      const _note = {
        id: note.id,
        userId: note.userId,
        description: note.description,
        title: note.title,
      };

      const result = await postCarePlanNote(_note);

      if (result.data) {
        await removeCarePlanNoteAttachment(note.id, deleteAttachments);
        if (addAttachments.length > 0)
          addCarePlanNoteAttachment(note.id, addAttachments, onDone);
        else onDone(true);
      } else {
        onDone(false);
      }
      return result.data;
    }
  };

  const createCarePlanNote = async (
    note: NewCarePlanNote,
    attachments: AddedAttachment[],
    onDone: Function,
  ) => {
    if (accessToken) {
      const result = await postCarePlanNote(note);
      const newNote = result.data;
      if (newNote) {
        if (attachments.length > 0)
          addCarePlanNoteAttachment(newNote.id, attachments, onDone);
        else onDone(true);
      } else onDone(false);
    }
  };

  const getNotes = async (userId: string, controller?: AbortController) => {
    if (accessToken) {
      const _notes = await fetchNotes(userId, controller);
      if (!_notes) {
        pushAlert("Failed to get patient's notes", "danger");
      }
      if (!_notes || _notes === "cancelled") return [];
      if (_notes.length > 0) {
        const notes: Note[] = _notes.map((note: ResponseData) => ({
          ...note,
          ids: note.ids || [],
        }));
        return notes;
      } else return [];
    } else return [];
  };

  function extractContent(s: string) {
    const span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  const initNotesTableData = async (
    userId: string,
    handleViewNoteClick: (
      noteId: string,
      author: string,
      date: string,
      notes: string,
      tag: string,
    ) => void,
    controller?: AbortController,
  ) => {
    if (accessToken) {
      const _notes = await getNotes(userId, controller);
      const noteTableData: CellProps[][] = [];
      _notes.map((note) => {
        const row: CellProps[] = [];
        row.push(
          note?.visit && note?.dateOfService
            ? `${format(new Date(note.dateOfService), "M/d/yyyy")}`
            : "",
        );
        row.push(note?.visit && note?.visit.name ? note.visit.name : "");
        row.push(
          note?.detail
            ? extractContent(note.detail).substring(0, 30) + "..."
            : "",
        );
        row.push(note?.author && note?.author.name ? note.author.name : "");
        const noteId =
          note?.ids && note.ids.length > 0 && note.ids[0].ID
            ? note.ids[0].ID
            : "";
        const author =
          note?.author && note.author?.name ? note.author.name : "";
        const date = note?.dateOfService
          ? format(new Date(note.dateOfService), "M/d/yyyy")
          : "";
        const notes = note?.detail ? note.detail : "";
        const tag = note?.status ? note.status : "";
        row.push({
          label: "View Note",
          onClick: () => {
            handleViewNoteClick(noteId, author, date, notes, tag);
          },
        });
        noteTableData.push(row);
      });
      return noteTableData;
    } else return [];
  };

  return {
    getNotes,
    initNotesTableData,
    getCarePlanNotes,
    getCarePlanNote,
    updateCarePlanNote,
    createCarePlanNote,
  };
};
