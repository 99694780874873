import { useEffect, useMemo, useState } from "react";
import { Button } from "../../components/button/Button";
import { SearchOption } from "../../lib/interfaces/listOfPatients";
import { Option } from "../../lib/interfaces/input";
import { FunnelSimple } from "phosphor-react";
import { ChatList } from "../../containers/messaging/chatList/ChatList";
import {
  ChatPreview as ChatPreviewType,
  FilterOptions,
} from "../../lib/interfaces/messaging";
import { FeaturedIcon } from "../../components/featuredIcon/FeaturedIcon";
import { Heading } from "../../components/typography/heading/Heading";
import { Body } from "../../components/typography/body/Body";
import { useBaseContext } from "../../containers/_base/Base";
import { Chat } from "phosphor-react";
import { Modal } from "../../components/modal/Modal";
import { FiltersForm } from "../../containers/messaging/filtersForm/FiltersForm";
import styles from "./style.module.css";
import { PatientInfo } from "../../lib/interfaces/user";
import { useChats } from "../../lib/hooks/useChats";

//@ts-ignore
import smoothscroll from "smoothscroll-polyfill";
import _ from "lodash";

smoothscroll.polyfill();

export interface PatientMessagesProps {
  patient: PatientInfo;
  setPrimaryAction: (_?: any) => void;
}

export const PatientMessages = ({
  patient,
  setPrimaryAction,
}: PatientMessagesProps) => {
  const [noChats, setNoChats] = useState<boolean>(true);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [selectedSearchOption, setSelectedSearchOption] =
    useState<SearchOption>({ patient: { name: "", value: "" } });
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    patients: [{ name: patient.name, value: patient.id }],
    providers: [],
    timeRange: {},
  });
  const [selectedChat, setSelectedChat] = useState<ChatPreviewType>();

  const { openPopoutChat } = useBaseContext();

  useEffect(() => {
    setPrimaryAction(() => (chat: ChatPreviewType) => {
      // open popout chat between the patient and signed in user
      // getChatWithUsers(patient).then(res => {
      // let chat: ChatPreviewType | undefined = res?.chat
      // if(chat){
      // openPopoutChat && openPopoutChat({
      //   id: chat.id,
      //   participants: chat.participants,
      //   regarding: chat.regarding
      // })
      // handleChatSelect(chat)
      // }
      // })
      setSelectedChat(chat);
    });
  }, []);

  const handleChatSelect = (chat: ChatPreviewType) => {
    setSelectedChat(chat);
    openPopoutChat &&
      openPopoutChat({
        id: chat.id,
        participants: chat.participants,
        regarding: chat.regarding,
      });
  };

  // const handleSelectedPatientsChange = (selectedPatient: Option) => {
  //   setSelectedSearchOption({
  //     patient: selectedPatient,
  //   });
  // };

  const handleFilterButtonClick = () => {
    setShowFiltersModal(true);
  };

  const handleNoChats = (chats: boolean) => {
    setNoChats(chats);
  };

  const handleClearAll = () => {
    setShowFiltersModal(false);
    setFilterOptions({
      ...filterOptions,
      providers: [],
      timeRange: {},
    });
  };

  const handleFiltersApply = (selectedFilterOptions: FilterOptions) => {
    if (selectedFilterOptions.timeRange?.from === "")
      delete selectedFilterOptions.timeRange.from;
    if (selectedFilterOptions.timeRange?.to === "")
      delete selectedFilterOptions.timeRange.to;
    if (!selectedFilterOptions.providers[0]?.value)
      setFilterOptions({
        ...filterOptions,
        providers: [],
        timeRange: selectedFilterOptions.timeRange,
      });
    else setFilterOptions(selectedFilterOptions);
    setShowFiltersModal(false);
  };

  const numFilters = useMemo(() => {
    let count = 0;
    if (filterOptions.providers.length > 0) count++;
    if (!_.isEmpty(filterOptions.timeRange)) count++;
    return count;
  }, [filterOptions]);

  const emptyStateComponent: JSX.Element = (
    <>
      <div className={styles.noMessages}>
        <FeaturedIcon Icon={Chat} type="gray" />
        <div className={styles.heading}>
          <Heading type="02">No messages</Heading>
        </div>
        {_.isEmpty(filterOptions.providers) &&
        _.isEmpty(filterOptions.timeRange) ? (
          <Body color="secondary">
            When there is a message concerning this patient, it will appear
            here.
          </Body>
        ) : (
          <Body color="secondary">Adjust your filter for more results.</Body>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className={styles.content}>
        {!noChats && (
          <div className={styles.searchAndFilter}>
            {/* <SearchSelect
              placeholder='Search'
              LeftIcon={MagnifyingGlass}
              width={400}
              value={selectedSearchOption.patient}
              onChange={handleSelectedPatientsChange}
              options={patients.map(patient => ({
                avatarUrl: patient.photo||'',
                name: patient.firstName + ' ' + patient.lastName,
                value: patient.id
              }))}
              className={styles.search}
            /> */}
            <Button
              onClick={() => {
                handleFilterButtonClick();
              }}
              Icon={FunnelSimple}
              label={numFilters > 0 ? `Filters (${numFilters})` : "Filter"}
              type="secondary-gray"
            />
          </div>
        )}
        <ChatList
          filterOptions={filterOptions}
          selectedChatId={selectedChat?.id}
          onChatSelect={handleChatSelect}
          emptyStateComponent={emptyStateComponent}
          truncatePreviewOneLine={true}
          onNoChats={handleNoChats}
          selectedUserOptions={filterOptions.patients}
          patientMessages={true}
        />
      </div>
      <Modal
        visible={showFiltersModal}
        onCloseModal={() => setShowFiltersModal(false)}
        title="Filters"
      >
        <FiltersForm
          filterOptions={filterOptions}
          onClearAll={handleClearAll}
          onApply={handleFiltersApply}
          selectedUserOptions={filterOptions.patients}
          patientMessages={true}
        />
      </Modal>
    </>
  );
};
