import { useState, useEffect, useContext, useMemo } from "react";
import { Heading } from "../../../components/typography/heading/Heading";
import { SearchSelect } from "../../../components/searchSelect/SearchSelect";
import { Select } from "../../../components/select/Select";
import {
  PatientSearchOption,
  ProviderSearchOption,
  VisitTypeSearchOption,
} from "../../../lib/interfaces/schedule";
import { Option, VisitTypeOption } from "../../../lib/interfaces/input";
import { PatientInfo } from "../../../lib/interfaces/user";
import { MagnifyingGlass } from "phosphor-react";
import styles from "./style.module.css";
import { BaseContext } from "../../../lib/context/context";
import { ProviderSubset } from "../../../lib/apis/types/provider.types";
import { transformToGenderType } from "../../../lib/apis/types/gender.types";
import { VisitReason } from "../../../lib/apis/types/visitReason.types";
import PatientSearch from "../../../pages/patient/PatientSearch";

export interface VisitDetailsProps {
  visitReasons: VisitReason[];
  visitReasonsLoading: boolean;
  selectedPatient: PatientSearchOption;
  selectedPatientInfo?: PatientInfo;
  handleSelectedPatientChange: (selectedPatientOption: Option | string) => void;
  visitType: VisitTypeSearchOption;
  handleSelectedVisitTypeChange: (selectedOption: VisitTypeOption) => void;
  selectedProvider: ProviderSearchOption;
  handleSelectedProviderChange: (
    selectedProviderOption: Option | string,
  ) => void;
  disablePatientSelect: boolean;
  disableVisitField: boolean;
}

export const VisitDetails = ({
  visitReasons,
  visitReasonsLoading,
  selectedPatient,
  selectedPatientInfo,
  handleSelectedPatientChange,
  visitType,
  handleSelectedVisitTypeChange,
  selectedProvider,
  handleSelectedProviderChange,
  disablePatientSelect,
  disableVisitField,
}: VisitDetailsProps) => {
  const [providersLoading, setProvidersLoading] = useState<boolean>(true);
  const [filteredVisitReasons, setFilteredVisitReasons] = useState<
    VisitReason[]
  >([]);

  const { allProviders } = useContext(BaseContext);

  useEffect(() => {
    if (allProviders && allProviders.length > 0) {
      setProvidersLoading(false);
    }
  }, [allProviders]);

  useEffect(() => {
    const genderType = transformToGenderType(selectedPatientInfo?.assignedSex);
    setFilteredVisitReasons(
      visitReasons.filter((vr) => vr.genders.includes(genderType)),
    );
  }, [selectedPatientInfo]);

  const filteredProviderList = useMemo(() => {
    const selectedVisitType = filteredVisitReasons.find(
      (vr) => vr.visitType === visitType.value,
    );
    return selectedVisitType?.providers || [];
  }, [visitType, filteredVisitReasons, allProviders]);

  return (
    <div>
      <Heading type="03">Visit Details</Heading>
      <div className={styles.visitDetails}>
        <PatientSearch
          label="Patient"
          placeholder="Search"
          handlePatientsChange={handleSelectedPatientChange}
          value={selectedPatient.patient}
          disabled={disablePatientSelect}
          className={styles.search}
          width={310}
          isPatientSearchPage={false}
        />
        <Select
          label="Visit Type"
          placeholder="Select visit type"
          width={310}
          value={visitType}
          options={filteredVisitReasons.map((vr) => ({
            id: vr.id,
            name: vr.displayTitle,
            secondaryText: vr.locationType,
            value: vr.visitType,
            epicVisitTypeId: vr.epicVisitType,
          }))}
          onChange={handleSelectedVisitTypeChange}
          className={styles.search}
          loading={visitReasonsLoading}
          disabled={selectedPatient.patient.value === "" || disableVisitField}
        />
        <SearchSelect
          label="Provider"
          placeholder="Search"
          LeftIcon={MagnifyingGlass}
          width={310}
          value={selectedProvider.provider}
          onChange={handleSelectedProviderChange}
          options={filteredProviderList.map((provider: ProviderSubset) => ({
            avatarUrl: provider.photoUrl || "",
            name: provider.preferredName,
            value: provider.providerId,
            secondaryText: provider.providerType,
          }))}
          loading={providersLoading}
          disabled={
            selectedPatient.patient.value === "" ||
            visitType.value === "" ||
            disableVisitField
          }
        />
      </div>
    </div>
  );
};
