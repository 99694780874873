import { Fragment, useContext, useEffect, useState } from "react";
import { PatientSearchSelect } from "../../components/patientSearchSelect/PatientSearchSelect";
import { usePatient } from "../../lib/hooks/usePatient";
import { ParamProps, PatientNames } from "../../lib/apis/patient";
import { PatientOption } from "../../lib/interfaces/input";
import { Heading } from "../../components/typography/heading/Heading";
import styles from "./style.module.css";
import { Button } from "../../components/button/Button";
import { Spinner } from "../../components/spinner/Spinner";
import PatientResultsList from "./PatientResultsList";
import { BaseContext } from "../../lib/context/context";
import { MembershipTier, MembershipTierLabel } from "../../lib/interfaces/user";
import clsx from "clsx";

export interface PatientDetails {
  userId: string;
  firstName: string;
  lastName: string;
  latestPastVisitDatetime?: string;
  futureVisitDatetime?: string;
  membershipTier?: string;
  name: string;
}

export default function PatientResults() {
  const { searchQuery } = useContext(BaseContext);
  const [loading, setLoading] = useState(true);
  const [patients, setPatients] = useState<PatientNames[]>([]);
  const { getAllPatientNames } = usePatient();
  const [pageCount, setPageCount] = useState<number>(0);
  const [resultCount, setResultCount] = useState<number>(0);
  const [selectMembership, setSelectMembership] = useState(
    searchQuery?.membershipTier ?? "",
  );
  const [params, setParams] = useState<ParamProps>({
    searchText: searchQuery?.name,
    pageNumber: 1,
    pageSize: 10,
    membershipTier: selectMembership,
  });

  const handleSelectedPatientsChange = (
    selectedPatient: PatientOption | null | undefined,
  ) => {
    setParams((prev) => ({
      ...prev,
      pageNumber: 1,
      searchText: selectedPatient?.name,
      membershipTier: selectedPatient?.membershipTier,
    }));
  };

  const handleInputChange = (inputText: string) => {
    setPatients([]);
    setParams((prev) => ({
      ...prev,
      pageNumber: 1,
      searchText: inputText,
    }));
  };

  const changePage = (newPage: number) => {
    if (newPage >= 1 && newPage <= pageCount) {
      setParams((prev) => ({ ...prev, pageNumber: newPage }));
    }
  };

  useEffect(() => {
    setLoading(true);
    if (searchQuery?.userId && params.searchText === undefined) {
      setPatients([searchQuery]);
      setLoading(false);
    } else {
      getAllPatientNames({ ...params, membershipTier: selectMembership })
        .then((patients) => {
          setPatients(patients?.patients);
          setPageCount(Math.ceil(patients?.totalCount / params.pageSize));
          setResultCount(patients?.totalCount);
        })
        .catch((error) => {
          console.error("Failed to fetch patient names:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params]);

  const handleMembership = (selectMembership: string) => {
    setPatients([]);
    setParams((prev) => ({
      ...prev,
      pageNumber: 1,
      searchText: "",
      membershipTier: selectMembership,
    }));
    setSelectMembership(selectMembership);
  };

  return (
    <div className={styles.patientResultWrapper}>
      <div className={styles.patientResultSearch}>
        <Heading className={styles.headingGap} type="01">
          Patients
        </Heading>
        <div style={{ position: "relative", display: "flex" }}>
          <PatientSearchSelect
            label=""
            onSelect={handleSelectedPatientsChange}
            onChange={handleInputChange}
            onSelectMembership={handleMembership}
            options={
              patients
                ? patients.map((patient: PatientNames) => ({
                    name: `${patient.firstName} ${patient.lastName}`,
                    value: patient.userId,
                  }))
                : []
            }
            loading={loading}
            className={styles.searchBoxSize}
            dropdownStyle={styles.dropdownStyle}
            totalPatientCount={resultCount || undefined}
            searchText={params.searchText || ""}
            selectMembershipTier={{
              name:
                selectMembership === MembershipTier.TIER_ONE
                  ? MembershipTierLabel.TIER_ONE
                  : selectMembership === ""
                    ? MembershipTierLabel.TIER_ALL
                    : MembershipTierLabel.TIER_TWO,
              value: selectMembership,
            }}
            showDropdown={false}
            isPatientSearchPage={true}
          />
        </div>
      </div>

      <div className={styles.patientResultListWrapper}>
        {patients?.length > 0 ? (
          patients.map((items, index) => (
            <Fragment key={index}>
              <PatientResultsList patient={items} />
            </Fragment>
          ))
        ) : loading ? (
          <Spinner top={0} />
        ) : (
          <div className={styles.noRecordFound}>No record found</div>
        )}
      </div>

      <div className={clsx(styles.paginationWrapper)}>
        {pageCount > 1 && patients?.length > 0 && (
          <nav className={styles.paginationContainer}>
            <Button
              disabled={params.pageNumber === 1}
              label="<<"
              onClick={() => changePage(1)}
            />
            <Button
              disabled={params.pageNumber === 1}
              label="<"
              onClick={() => changePage(params.pageNumber - 1)}
            />
            <Button
              disabled={params.pageNumber === pageCount}
              label=">"
              onClick={() => changePage(params.pageNumber + 1)}
            />
            <Button
              disabled={params.pageNumber === pageCount}
              label=">>"
              onClick={() => changePage(pageCount)}
            />
            <div>
              <h3>
                Page {params.pageNumber} of {pageCount}
              </h3>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}
