import { useContext, useEffect, useMemo, useState } from "react";
import { PatientInfo } from "../../lib/interfaces/user";
import {
  Subscription,
  fetchSubscription,
  postUpgradeToPremium,
} from "../../lib/apis/subscription";
import { Button, ButtonGroup } from "../../components/button/Button";
import styles from "./style.module.css";
import { CircleWavyCheck, Compass, WarningOctagon } from "phosphor-react";
import { FeaturedIcon } from "../../components/featuredIcon/FeaturedIcon";
import { Heading } from "../../components/typography/heading/Heading";
import { Modal } from "../../components/modal/Modal";
import { CellProps, Table } from "../../components/table/Table";
import { AlertContext } from "../../lib/context/context";
import { fromUnixTime } from "date-fns";

type PatientSubscriptionProps = {
  patient: PatientInfo;
};

export const PatientSubscription = ({ patient }: PatientSubscriptionProps) => {
  const { pushAlert, clearAlerts } = useContext(AlertContext);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [postingUpgrade, setPostingUpgrade] = useState(false);

  useEffect(() => {
    if (!patient.id) return;

    fetchSubscription({ userId: patient.id }).then((subscription) => {
      if (subscription) {
        setSubscription(subscription);
      }
    });
  }, [patient]);

  const upgradeSubscription = () => {
    setPostingUpgrade(true);
    postUpgradeToPremium({ userId: patient.id, userRole: "patient" })
      .then(() => {
        setPostingUpgrade(false);
        setModalVisible(false);
        pushAlert("User upgraded to Premium successfully", "success");
        setTimeout(() => {
          clearAlerts();
          location.reload();
        }, 2000);
      })
      .catch(() => {
        pushAlert(
          "It was an issue upgrading the subscription. Please try again later.",
          "warning",
        );
      });
  };

  const NoPremiumMemberComponent = () => (
    <div className={styles.noSubscription}>
      <FeaturedIcon Icon={Compass} type="gray" />
      <div className={styles.heading}>
        <Heading type="02">This user is not a Premium Subscriber.</Heading>
      </div>
      <Button
        size="medium"
        type="primary"
        label="Upgrade To Premium"
        iconPosition="right"
        loading={postingUpgrade}
        onClick={() => setModalVisible(true)}
      />
    </div>
  );

  const NoSubscriptionComponent = () => (
    <div className={styles.noSubscription}>
      <FeaturedIcon Icon={WarningOctagon} type="gray" />
      <div className={styles.heading}>
        <Heading type="02">
          This user has no subscription and cannot be upgraded to Premium.
        </Heading>
      </div>
    </div>
  );

  const SubscriptionInfoComponent = () => {
    if (!subscription) return <></>;

    const columns = ["Property", "Value"];
    const cells: CellProps[][] = [];

    cells.push(
      [{ mainText: "Status" }, { mainText: subscription.status }],
      [
        { mainText: "Created" },
        { mainText: `${fromUnixTime(subscription.created)}` },
      ],
    );

    return (
      <Table
        data={cells}
        friendlyTitle="notes"
        columns={columns}
        title="Subscription Details"
      />
    );
  };

  const PremiumMemberComponent = () => {
    return (
      <div className={styles.noSubscription}>
        <CircleWavyCheck color="#1A6D93" weight="fill" size={40} />
        <div className={styles.heading}>
          <Heading type="02">This user is a Premium Member.</Heading>
        </div>

        {SubscriptionInfoComponent()}
      </div>
    );
  };

  return (
    <>
      <div className={styles.content}>
        {!subscription && !patient.premiumMember && NoSubscriptionComponent()}
        {subscription && !patient.premiumMember && NoPremiumMemberComponent()}
        {patient.premiumMember && PremiumMemberComponent()}
      </div>
      <Modal
        visible={modalVisible}
        onCloseModal={() => setModalVisible(false)}
        title="Confirm Upgrade"
      >
        <ButtonGroup align="center">
          <Button
            label="Cancel"
            type="secondary-gray"
            disabled={postingUpgrade}
            onClick={() => setModalVisible(false)}
          />
          <Button
            label="Confirm & Close"
            loading={postingUpgrade}
            onClick={() => upgradeSubscription()}
          />
        </ButtonGroup>
      </Modal>
    </>
  );
};
