import clsx from "clsx";
import styles from "./style.module.css";

export type BadgeType = "neutral" | "alert";
export interface BadgeProps {
  type: BadgeType;
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
}

export const Badge = ({ type, top, right, bottom, left }: BadgeProps) => (
  <div
    className={clsx(styles.base, styles[type])}
    style={{
      top: top,
      right: right,
      bottom: bottom,
      left: left,
    }}
  />
);
