import { useContext, useEffect, useMemo, useState } from "react";
import { File, Pencil } from "phosphor-react";
import { Avatar } from "../../../components/avatar/Avatar";
import { Tag } from "../../../components/tag/Tag";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { Attachment, CarePlanNote } from "../../../lib/interfaces/carePlan";
import { ProviderInfo } from "../../../lib/interfaces/user";
import { format } from "date-fns";
import { Button } from "../../../components/button/Button";
import styles from "./style.module.css";
import { getLocalDateTime } from "../../../lib/util/date";
import { useNotes } from "../../../lib/hooks/useNotes";
import { BaseContext } from "../../../lib/context/context";

interface NoteProps {
  note: CarePlanNote;
  patientId: string;
  onEditClick: () => void;
}

export const Note = ({ note, patientId, onEditClick }: NoteProps) => {
  const [modifiedBy, setModifiedBy] = useState<ProviderInfo>();
  const { getCarePlanNote } = useNotes();
  const [updNote, setUpdNote] = useState<CarePlanNote>();
  const [loadingAttachments, setLoadingAttachments] = useState<Attachment[]>(
    [],
  );
  const { allProviders } = useContext(BaseContext);

  const modifiedOn = useMemo(() => {
    return format(new Date(getLocalDateTime(note.modifiedOn)), "MMMM dd, yyyy");
  }, []);

  useEffect(() => {
    if (note.modifiedBy && allProviders) {
      const provider = allProviders.find(
        (provider) => provider.id === note.modifiedBy,
      );
      setModifiedBy(provider);
    }
  }, [note.modifiedBy, allProviders]);

  useEffect(() => {
    if (loadingAttachments.length > 0) {
      getCarePlanNote(patientId, note.id).then((_carePlanNote) =>
        setUpdNote(_carePlanNote[0]),
      );
    }
  }, [loadingAttachments]);

  useEffect(() => {
    if (updNote) {
      if (updNote.attachments.length > 0) {
        loadingAttachments.map((loadingAttachment) => {
          updNote.attachments.map((updNoteAttachment) => {
            if (updNoteAttachment.name === loadingAttachment.name) {
              window.open(updNoteAttachment.url);
              const updLoadingAttachments: Attachment[] = JSON.parse(
                JSON.stringify(loadingAttachments),
              );
              const attachmentToRemove =
                updLoadingAttachments.indexOf(loadingAttachment);
              updLoadingAttachments.splice(attachmentToRemove, 1);
              setLoadingAttachments(updLoadingAttachments);
            }
          });
        });
      }
    }
  }, [updNote]);

  return (
    <div className={styles.note}>
      <div className={styles.topRow}>
        <Body size="md" weight="bold" className={styles.date}>
          {modifiedOn}
        </Body>
        <Button
          size="small"
          type="secondary"
          onClick={onEditClick}
          Icon={Pencil}
        />
      </div>
      <Heading type="02" color="link" className={styles.title}>
        {note.title}
      </Heading>
      <Body className={styles.description}>{note.description}</Body>
      <div className={styles.modifiedBy}>
        <Avatar size="xxs" user={modifiedBy} />
        <Body size="xs" color="secondary">
          Edited by {modifiedBy?.name || "..."}
        </Body>
      </div>
      {note.attachments.length > 0 && (
        <div className={styles.attachments}>
          {note.attachments.map((attachment) => (
            <Tag
              content={attachment.name}
              Icon={File}
              onClick={() =>
                setLoadingAttachments([...loadingAttachments, attachment])
              }
              loading={loadingAttachments.includes(attachment)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
