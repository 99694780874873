import clsx from "clsx";
import { useMemo } from "react";
import { Participant } from "../../lib/interfaces/messaging";
import { UserInfo } from "../../lib/interfaces/user";
import { Avatar } from "../avatar/Avatar";
import { Body } from "../typography/body/Body";
import styles from "./style.module.css";

type messageType = "sent" | "recieved";
export interface message {
  content: string;
  timestamp?: Date;
}

export interface ChatBubbleProps {
  message: message;
  type: messageType;
  className?: string;
}

export const ChatBubble = ({ message, type, className }: ChatBubbleProps) => {
  const timestamp = useMemo(() => message.timestamp?.toDateString(), [message]);

  return type === "sent" ? (
    <div
      title={timestamp}
      className={clsx(styles.base, styles.sent, className)}
    >
      <Body
        size="sm"
        data-dd-privacy="mask"
        color="white"
        style={{ overflowWrap: "break-word" }}
      >
        {message.content}
      </Body>
    </div>
  ) : (
    <div
      title={timestamp}
      className={clsx(styles.base, styles.recieved, className)}
    >
      <Body
        size="sm"
        data-dd-privacy="mask"
        color="secondary"
        style={{ overflowWrap: "break-word" }}
      >
        {message.content}
      </Body>
    </div>
  );
};

export interface ChatSenderProps {
  user: Participant;
}

export const ChatSender = ({ user }: ChatSenderProps) => (
  <div className={styles.chatSender}>
    <Avatar user={user} size="sm" />
    <Body size="xs" color="secondary" data-dd-privacy="mask">
      {[user.firstName, " ", user.lastName, ", ", user.title].join("")}
    </Body>
  </div>
);
