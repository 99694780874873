import { ControlBar } from "amazon-chime-sdk-component-library-react";

import EndMeetingControl from "../../components/Controls/EndMeetingControl";

import { StyledControls } from "./Styled";
import MicrophoneControl from "../../components/Controls/MicrophoneControl";
import { VideoControl } from "../../components/Controls/VideoControl";

const MeetingControls: React.FC = () => {
  return (
    <StyledControls active={true}>
      <ControlBar
        className="controls-menu"
        layout="bottom"
        showLabels
        responsive={false}
      >
        <VideoControl />
        <MicrophoneControl />
        <EndMeetingControl />
      </ControlBar>
    </StyledControls>
  );
};

export default MeetingControls;
