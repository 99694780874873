import { Play } from "phosphor-react";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { Tag } from "../../../components/tag/Tag";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { Resource } from "../../../lib/interfaces/resource";
import { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { useResources } from "../../../lib/hooks/useResources";
import clsx from "clsx";
import styles from "./style.module.css";

export interface ResourceDetailsProps {
  resource?: Resource;
  resourceId?: string;
}

export const ResourceDetails = ({
  resource,
  resourceId,
}: ResourceDetailsProps) => {
  const [resourceContent, setResourceContent] = useState(resource);
  const [showVideoControls, setShowVideoControls] = useState(false);
  const [loading, setLoading] = useState(resource ? false : true);
  const { getResource } = useResources();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!resource && resourceId) {
      getResource(resourceId).then(setResourceContent);
    }
  }, [resourceId]);

  useEffect(() => {
    if (resourceContent) setLoading(false);
  }, [resourceContent]);

  const handlePlayPress = () => {
    setShowVideoControls(true);
    videoRef.current?.play();
  };

  return (
    <div className={styles.resourceDetails}>
      {resourceContent && (
        <>
          {(resourceContent.image || resourceContent.video) && (
            <div
              className={clsx(
                styles.bannerContainer,
                resourceContent.type === "video" && styles.videoThumbnail,
              )}
            >
              {resourceContent.type !== "video" ? (
                <img src={resourceContent.image} width="100%" height={394} />
              ) : (
                <video
                  ref={videoRef}
                  width="100%"
                  height="100%"
                  controls={showVideoControls}
                >
                  {/* <source src={resourceContent.image2} /> */}
                  <source src={resourceContent.video} />
                  Video format not supported
                </video>
              )}
              {resourceContent.type === "video" && !showVideoControls && (
                <button onClick={handlePlayPress} className={styles.playIcon}>
                  <FeaturedIcon Icon={Play} type="white" size="xxl" />
                </button>
              )}
            </div>
          )}

          {resourceContent.type === "video" && (
            //not sure if image2Caption corresponds with a video caption
            <Body className={styles.caption} color="secondary" size="md">
              {resourceContent.image2Caption}
            </Body>
          )}
          <Body className={styles.type} color="secondary" size="xs">
            {resourceContent.type}
          </Body>
          <Heading className={styles.title} type="01">
            {resourceContent.title}
          </Heading>
          <Body color="primary" size="sm" weight="bold">
            {resourceContent.author}
          </Body>
          <Body color="secondary" size="sm">
            {resourceContent.authorCredential}
          </Body>
          <div className={styles.tagContainer}>
            {resourceContent.tags.map((tag) => (
              <Tag key={uuid()} content={tag} />
            ))}
          </div>
          {resourceContent.body && (
            <div
              className={styles.section}
              dangerouslySetInnerHTML={{ __html: resourceContent.body }}
            />
          )}
          {resourceContent.image2 && (
            <img src={resourceContent.image2} className={styles.image} />
          )}
          {resourceContent.image2Caption && (
            <Body className={styles.caption} color="secondary" size="md">
              {resourceContent.image2Caption}
            </Body>
          )}
          {resourceContent.body2 && (
            <div
              className={styles.section}
              dangerouslySetInnerHTML={{ __html: resourceContent.body2 }}
            />
          )}
          {resourceContent.image3 && (
            <img src={resourceContent.image3} className={styles.image} />
          )}
          {resourceContent.image3Caption && (
            <Body className={styles.caption} color="secondary" size="md">
              {resourceContent.image3Caption}
            </Body>
          )}
          {resourceContent.body3 && (
            <div
              className={styles.section}
              dangerouslySetInnerHTML={{ __html: resourceContent.body3 }}
            />
          )}
          <div className={styles.emptySpace} />
        </>
      )}
    </div>
  );
};
