import { useState, useEffect, useContext, useCallback } from "react";
import {
  PatientSearchOption,
  ProviderSearchOption,
  VisitTypeSearchOption,
} from "../../../lib/interfaces/schedule";
import { Option, VisitTypeOption } from "../../../lib/interfaces/input";
import { useSchedule } from "../../../lib/hooks/useSchedule";
import { AppointmentSlots } from "../../../lib/interfaces/schedule";
import { VisitDetails } from "../visitDetails/VisitDetails";
import { SelectDate } from "../selectDate/SelectDate";
import { NewApptDetails } from "../newApptDetails/NewApptDetails";
import {
  startOfToday,
  startOfWeek,
  endOfWeek,
  addDays,
  subDays,
  format,
} from "date-fns";
import { AlertContext } from "../../../lib/context/context";
import {
  PatientCardInfo,
  PatientInfo,
  ProviderInfo,
} from "../../../lib/interfaces/user";
import { useVisitReasons } from "../../../lib/hooks/useVisitReasons";
import styles from "./style.module.css";
import { usePatient } from "../../../lib/hooks/usePatient";
import { useVisits } from "../../../lib/hooks/useVisits";
import { Visit } from "../../../lib/interfaces/visits";

export interface NewAppointmentProps {
  chosenPatient?: PatientInfo | PatientCardInfo;
  chosenVisitTypeId?: string;
  chosenProvider?: ProviderInfo;
  visitId?: string;
  onNewAppointmentBooked: (bookAnother?: boolean) => unknown;
  disableVisitField?: boolean;
  visit?: Visit;
}

type ScheduleDates = {
  start: Date;
  end: Date;
} | null;

const DEFAULT_PATIENT = {
  patient: {
    name: "",
    value: "",
  },
};
const DEFAULT_PROVIDER = { provider: { name: "", value: "" } };
const DEFAULT_VISIT_TYPE = {
  epicVisitTypeId: "",
  name: "",
  value: "",
};
const DEFAULT_TIME = {
  name: "",
  value: { dateTime: "", departmentId: "" },
};

export const NewAppointment = ({
  chosenPatient,
  chosenVisitTypeId,
  chosenProvider,
  visitId,
  onNewAppointmentBooked,
  disableVisitField,
  visit,
}: NewAppointmentProps) => {
  const { getProviderSchedule, getInitialSchedule } = useSchedule();
  const { scheduleAppointment } = useVisits();
  const { pushAlert } = useContext(AlertContext);
  const { visitReasons, loading: visitReasonsLoading } = useVisitReasons();
  const { getPatient } = usePatient();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] =
    useState<PatientSearchOption>(DEFAULT_PATIENT);
  const [selectedProvider, setSelectedProvider] =
    useState<ProviderSearchOption>(DEFAULT_PROVIDER);
  const [visitType, setVisitType] =
    useState<VisitTypeSearchOption>(DEFAULT_VISIT_TYPE);
  const [selectedPatientInfo, setSelectedPatientInfo] = useState<PatientInfo>();

  //getInitialSchedule gets an empty placeholder schedule starting on the monday of this week
  const [availableAppts, setAvailableAppts] = useState<AppointmentSlots[]>(
    getInitialSchedule(
      format(addDays(startOfWeek(startOfToday()), 1), "yyyy-MM-dd"),
    ),
  );
  //scheduleDates is used to show only 5 days of the week at a time
  const [scheduleDates, setScheduleDates] = useState<ScheduleDates>(null);
  const [selectedTime, setSelectedTime] = useState(DEFAULT_TIME);
  const [selectedDate, setSelectedDate] = useState("");

  const [bookAnother, setBookAnother] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);
  const [preventProviderClear, setPreventProviderClear] = useState(true);
  const [updVisitId, setUpdVisitId] = useState<string | undefined>(visitId);

  const handleGetPatientInfo = useCallback(
    async (patientId: string) => {
      if (patientId) {
        const patient = await getPatient(patientId);
        setSelectedPatientInfo(patient);
      }
    },
    [getPatient],
  );

  const handleNewSchedule = useCallback(() => {
    setSelectedTime({ name: "", value: { dateTime: "", departmentId: "" } });
    setSelectedDate("");
  }, [visitType, selectedProvider.provider.value]);

  const handleSelectedPatientChange = async (
    selectedPatientOption: Option | string,
  ) => {
    //this if statement handles the case where the cancel button was clicked and the search value (in the search select component) was cleared
    if (selectedPatientOption === "") {
      setSelectedPatient({ patient: { name: "", value: "" } });
    }
    //else set the value to the selected patient
    else if (typeof selectedPatientOption === "object") {
      setSelectedPatient({
        patient: selectedPatientOption,
      });
      await handleGetPatientInfo(selectedPatientOption.value ?? "");
    }
    //when the patient changes, clear the visit type and provider choices
    setSelectedProvider({ provider: { name: "", value: "" } });
    setVisitType({
      epicVisitTypeId: "",
      name: "",
      value: "",
    });
  };

  const handleSelectedProviderChange = (
    selectedProviderOption: Option | string,
  ) => {
    //this if statement handles the case where the cancel button was clicked and the search value (in the search select component was cleared
    if (selectedProviderOption === "") {
      setSelectedProvider({ provider: { name: "", value: "" } });
    }
    //else set the value to the selected provider
    else if (typeof selectedProviderOption === "object") {
      handleNewSchedule();
      setSelectedProvider({
        provider: selectedProviderOption,
      });
    }
  };

  const handleSelectedVisitTypeChange = useCallback(
    (selectedOption: VisitTypeOption) => {
      setVisitType(selectedOption);
      if (!preventProviderClear) {
        setSelectedProvider({ provider: { name: "", value: "" } });
      } else if (selectedOption.value !== "") {
        handleNewSchedule();
        setPreventProviderClear(false);
      }
    },
    [preventProviderClear, handleNewSchedule],
  );

  const handleGetVisitType = () => {
    if (chosenVisitTypeId && !visitType.value && visit) {
      const chosenVisitType = visitReasons.find(
        (reason) => reason.id === chosenVisitTypeId,
      );
      const selectedVisit = {
        epicVisitTypeId: chosenVisitType?.epicVisitType || "",
        name: chosenVisitType?.displayTitle || "",
        secondaryText: visit.appointmentType as string,
        value: visit.visitType,
      };
      handleSelectedVisitTypeChange(selectedVisit);
    }
  };

  //if a chosenPatient is passed, pre-populate the selectedPatient with the chosenPatient
  useEffect(() => {
    if (chosenPatient && !selectedPatient.patient.value) {
      handleGetPatientInfo(chosenPatient.id);
      setSelectedPatient({
        patient: {
          name: chosenPatient.name,
          value: chosenPatient.id,
          avatarUrl: chosenPatient?.photo || "",
        },
      });
    }
  }, [chosenPatient, handleGetPatientInfo, selectedPatient]);

  //if a chosenVisitTypeId is passed, pre-populate the visitType
  useEffect(() => {
    if (!loading && visitReasons.length && chosenVisitTypeId) {
      handleGetVisitType();
    }
  }, [loading, visitReasons, chosenVisitTypeId]);

  //if a chosenProvider is passed, pre-populate the selectedProvider with the chosenProvider
  useEffect(() => {
    if (chosenProvider && !selectedProvider.provider.value) {
      setSelectedProvider({
        provider: {
          name: chosenProvider.name,
          value: chosenProvider.id,
          avatarUrl: chosenProvider?.photo || "",
        },
      });
    }
  }, [chosenProvider, selectedProvider]);

  const handleGetProviderSchedule = useCallback(
    async (startDate: Date, endDate: Date) => {
      if (!visitType.value && !selectedProvider.provider.value) {
        return;
      }

      const formattedStart = format(startDate, "yyyy-MM-dd");
      const formattedEnd = format(endDate, "yyyy-MM-dd");
      setLoading(true);
      setScheduleDates({ start: startDate, end: endDate });

      const schedule = await getProviderSchedule(
        selectedProvider.provider.value,
        formattedStart,
        formattedEnd,
        visitType.value,
        visitType.epicVisitTypeId,
      );
      setAvailableAppts(schedule);
      setLoading(false);
    },
    [selectedProvider, getProviderSchedule, visitType],
  );

  useEffect(() => {
    if (visitType.value && selectedProvider.provider.value) {
      const today = new Date();
      const startDate = startOfWeek(today, { weekStartsOn: 1 });
      const endDate = endOfWeek(today, { weekStartsOn: 0 });
      handleGetProviderSchedule(startDate, endDate);
    }
  }, [selectedProvider.provider, visitType]);

  const handleCancel = () => {
    setLoading(true);
    if (!chosenPatient) {
      setSelectedPatient({ patient: { name: "", value: "" } });
    }
    setSelectedProvider({ provider: { name: "", value: "" } });
    setVisitType({
      epicVisitTypeId: "",
      name: "",
      value: "",
    });
    setAvailableAppts(
      getInitialSchedule(
        format(addDays(startOfWeek(startOfToday()), 1), "yyyy-MM-dd"),
      ),
    );
    setScheduleDates(null);
    setSelectedTime({ name: "", value: { dateTime: "", departmentId: "" } });
    setSelectedDate("");
    setBookAnother(false);
    setLoading(false);
  };

  const handleBook = async () => {
    setBookLoading(true);

    const apptData = {
      visitType: visitType.value,
      providerId: selectedProvider.provider.value,
      userId: selectedPatient.patient.value,
      timeSlot: selectedTime.value.dateTime,
      reason: "",
    };

    const res = await scheduleAppointment(apptData, updVisitId);

    setBookLoading(false);
    if (res === "success") {
      if (bookAnother) {
        //clear and reset everything
        handleCancel();
        //used to handle the case where we just rescheduled an appointment and now we want to book another new appointment
        setUpdVisitId(undefined);
        //indicate to user if appointment was successfully booked or not
        pushAlert("Appointment successfully created!", "success");
      }
      onNewAppointmentBooked(bookAnother);
    } else if (res === "error") {
      pushAlert("Appointment could not be created", "danger");
    } else if (res) {
      pushAlert(res, "danger");
    }
  };

  const handleOptionSelect = (option: Option, date: string) => {
    setSelectedTime(option);
    setSelectedDate(date);
  };

  const handleCheckboxToggle = () => {
    setBookAnother((p) => !p);
  };

  const handleThisWeekClick = async () => {
    const today = new Date();
    const startDate = startOfWeek(today, { weekStartsOn: 1 });
    const endDate = endOfWeek(today, { weekStartsOn: 0 });
    handleGetProviderSchedule(startDate, endDate);
  };

  const handleNextWeekClick = async () => {
    if (!scheduleDates) return;
    const updatedStart = addDays(scheduleDates.start, 7);
    const updatedEnd = addDays(scheduleDates.end, 7);
    handleGetProviderSchedule(updatedStart, updatedEnd);
  };

  const handlePrevWeekClick = async () => {
    if (
      scheduleDates &&
      subDays(scheduleDates.start, 7) >= addDays(startOfWeek(startOfToday()), 1)
    ) {
      const updatedStart = subDays(scheduleDates.start, 7);
      const updatedEnd = subDays(scheduleDates.end, 7);
      handleGetProviderSchedule(updatedStart, updatedEnd);
    }
  };

  return (
    <div className={styles.page}>
      <VisitDetails
        visitReasons={visitReasons}
        visitReasonsLoading={visitReasonsLoading}
        selectedPatient={selectedPatient}
        selectedPatientInfo={selectedPatientInfo}
        handleSelectedPatientChange={handleSelectedPatientChange}
        visitType={visitType}
        handleSelectedVisitTypeChange={handleSelectedVisitTypeChange}
        selectedProvider={selectedProvider}
        handleSelectedProviderChange={handleSelectedProviderChange}
        disablePatientSelect={!!chosenPatient}
        disableVisitField={disableVisitField || false}
      />
      <SelectDate
        loading={loading}
        handlePrevWeekClick={handlePrevWeekClick}
        handleThisWeekClick={handleThisWeekClick}
        handleNextWeekClick={handleNextWeekClick}
        //check that the patient, provider, and visit type has been selected
        selectDateDisabled={
          selectedPatient.patient.value === "" ||
          selectedProvider.provider.value === "" ||
          visitType.value === ""
        }
        availableAppts={availableAppts}
        selectedTime={selectedTime}
        handleOptionSelect={handleOptionSelect}
      />
      {selectedPatient.patient.value != "" && (
        <NewApptDetails
          //check that the patient, provider, visit type, date, and time has been selected
          showAppointmentDetails={
            selectedPatient.patient.value !== "" &&
            selectedProvider.provider.value !== "" &&
            visitType.value !== "" &&
            selectedTime.value.dateTime != "" &&
            selectedDate != ""
          }
          patientId={selectedPatient.patient.value}
          selectedPatientInfo={selectedPatientInfo}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          selectedProvider={selectedProvider}
          visitType={visitType}
          bookAnother={bookAnother}
          handleCheckboxToggle={handleCheckboxToggle}
          handleCancel={handleCancel}
          handleBook={handleBook}
          bookLoading={bookLoading}
        />
      )}
    </div>
  );
};
