import axios, { AxiosResponse } from "axios";
import { CarePlan } from "../interfaces/carePlan";

export interface CarePlanReponse {
  id?: string;
  userId?: string;
  focus?: {
    value?: string;
    modifiedOn?: string;
    modifiedBy?: string;
  };
  comments?: string;
  intention?: {
    value?: string;
    modifiedOn?: string;
    modifiedBy?: string;
  };
  loveletter?: {
    value?: string;
    modifiedOn?: string;
    modifiedBy?: string;
  };
  modifiedOn?: string;
  modifiedBy?: string;
  publishedOn?: string;
  publishedBy?: string;
  sentToEpicOn?: string;
  pillars?: {
    [pillar: string]: {
      resourceIds?: string[];
      comment?: {
        comment: string;
        modifiedOn?: string;
        modifiedBy?: string;
      };
      actions?: {
        actionId?: string;
        category?: string;
        activities?: string[];
        quantity?: number;
        units?: string;
        frequency?: number;
        per?: string;
        modifiedOn?: string;
        modifiedBy?: string;
      }[];
    };
  };
}

export interface Response extends AxiosResponse {
  data: CarePlanReponse[];
}

export interface ProgressItem {
  carePlanId: string;
  period: string;
  score: number;
  normalizedPeriodDate: {
    dayOfWeek: string;
    month: string;
    day: string;
    year: string;
  };
}

export interface Progress {
  userId: string;
  pillars: {
    progress?: {
      progress: ProgressItem[];
    };
    move?: {
      progress: ProgressItem[];
    };
    calm?: {
      progress: ProgressItem[];
    };
    nourish?: {
      progress: ProgressItem[];
    };
  };
}

export interface ProgressResponse {
  data: Progress;
}

export const fetch = async (userId: string, controller?: AbortController) => {
  const result = await axios
    .get<Response, Response>("/careplan", {
      params: { userId },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result;
};

export const fetchPublished = async (
  userId: string,
  controller?: AbortController,
) => {
  const result = await axios
    .get<Response, Response>("/careplan?published=true", {
      params: { userId },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });

  return result;
};

export const post = async (userId: string, carePlan: CarePlan) => {
  const result = await axios
    .post<Response, Response>("/careplan", {
      ...carePlan,
      userId,
    })
    .catch((err) => err);

  return result.data;
};

export const sendToEpic = async (carePlan: any) => {
  const result = await axios
    .post<Response, Response>("/careplan/sendToEpic", {
      ...carePlan,
    })
    .catch((err) => err);

  return result;
};

export const fetchProgressData = async (
  carePlanId: string,
  pillar: string,
  fromPeriod: string,
  toPeriod: string,
  publishDate?: string,
  controller?: AbortController,
) => {
  let requestQuery = `?careplanId=${carePlanId}&fromPeriod=${fromPeriod}&toPeriod=${toPeriod}`;
  if (pillar) {
    requestQuery += `&pillar=${pillar}`;
  }
  if (publishDate) {
    requestQuery += `&carePlanPublishDate=${publishDate}`;
  }
  const result = await axios
    .get<ProgressResponse, ProgressResponse>(
      `/careplan/progress${requestQuery}`,
      {
        signal: controller ? controller.signal : undefined,
      },
    )
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });

  return result.data;
};

export default {
  fetch,
  fetchPublished,
  post,
  sendToEpic,
  fetchProgressData,
};
