import clsx from "clsx";
import { CaretLeft, X } from "phosphor-react";
import ReactDOM from "react-dom";
import {
  createContext,
  ReactChild,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CSSTransition,
  Transition,
  TransitionGroup,
} from "react-transition-group";
import { Button } from "../button/Button";
import { Heading } from "../typography/heading/Heading";
import { useAlerts } from "../../lib/hooks/useAlerts";
import { Tag, TagType } from "../tag/Tag";
import { AlertContext } from "../../lib/context/context";
import { AlertBanner } from "../alertBanner/AlertBanner";
import styles from "./style.module.css";

const mountingClassnames = {
  enter: styles.enter,
  exit: styles.exit,
  enterActive: styles.enterActive,
  exitActive: styles.exitActive,
  enterDone: styles.enterDone,
  exitDone: styles.exitDone,
};

const transitionClassnames = {
  enter: styles.appear,
  exit: styles.hide,
  enterActive: styles.appearActive,
  exitActive: styles.hideActive,
  enterDone: styles.appearDone,
  exitDone: styles.hideDone,
};

export interface ComponentMap {
  [route: string]: {
    title: string;
    centerTitle?: boolean;
    tag?: string;
    tagType?: TagType;
    route: string;
    component: (...props: any) => JSX.Element;
    props?: object;
  };
}

interface params {
  props?: object;
  title?: string;
}

interface MemoryRouterContext {
  pushRoute: (newRoute: string, params?: params) => void;
  popRoute: () => void;
}

//@ts-ignore
const memoryRouterContext = createContext<MemoryRouterContext>();

export interface DrawerProps {
  children?: ReactChild;
  componentMap?: ComponentMap;
  onClose: () => void;
  initialRoute?: string;
  title?: string;
  tag?: string;
  tagType?: TagType;
  visible?: boolean;
  usePortal?: boolean;
  className?: string;
  headerClassName?: string;
}

export const Drawer = ({
  children,
  componentMap,
  initialRoute,
  onClose,
  title,
  tag,
  tagType,
  visible,
  usePortal = true,
  className,
  headerClassName,
}: DrawerProps) => {
  if (usePortal)
    return ReactDOM.createPortal(
      <DrawerComponent
        {...{
          children,
          componentMap,
          initialRoute,
          onClose,
          title,
          tag,
          tagType,
          visible,
          usePortal,
          className,
          headerClassName,
        }}
      />,
      document.getElementById("base") || document.body,
    );
  else
    return (
      <DrawerComponent
        {...{
          children,
          componentMap,
          initialRoute,
          onClose,
          title,
          tag,
          tagType,
          visible,
          usePortal,
          className,
          headerClassName,
        }}
      />
    );
};

const DrawerComponent = ({
  children,
  componentMap,
  initialRoute,
  onClose,
  title,
  tag,
  tagType,
  visible,
  usePortal = true,
  className,
  headerClassName,
}: DrawerProps) => {
  const { alerts, pushAlert, closeAlert, clearAlerts } = useAlerts();
  const [routeStack, setRouteStack] = useState<string[]>(
    componentMap && initialRoute ? [componentMap[initialRoute].route] : [],
  );
  const [componentDir, setComponentDir] = useState(componentMap);

  useEffect(() => setComponentDir(componentMap), [componentMap]);

  const displayedTitle = useMemo(() => {
    if (componentDir)
      return componentDir[routeStack[routeStack.length - 1]]?.title;
    else return title;
  }, [routeStack, componentDir, title]);

  const activePages = useMemo(() => {
    if (componentDir)
      return routeStack.map((route, index) => {
        const Component = componentDir[route].component;
        const props = componentDir[route].props || {};
        const currentPage = index === routeStack.length - 1;
        return { Component, props, currentPage, route };
      });
  }, [routeStack, componentDir]);

  const displayTag = useMemo(() => {
    if (tag) {
      return tag;
    } else if (
      componentMap &&
      componentMap[routeStack[routeStack.length - 1]].tag
    ) {
      return componentMap[routeStack[routeStack.length - 1]].tag;
    } else return undefined;
  }, [tag, componentDir, routeStack]);

  const displayTagType = useMemo(() => {
    if (tagType) {
      return tagType;
    } else if (
      componentMap &&
      componentMap[routeStack[routeStack.length - 1]].tagType
    ) {
      return componentMap[routeStack[routeStack.length - 1]].tagType;
    } else return undefined;
  }, [tag, componentDir, routeStack]);

  const displayCenterTitle = useMemo(() => {
    if (componentMap) {
      return componentMap[routeStack[routeStack.length - 1]].centerTitle;
    } else {
      return false;
    }
  }, [routeStack, componentDir]);

  const handleClose = () => {
    setRouteStack(
      componentMap && initialRoute ? [componentMap[initialRoute].route] : [],
    );
    onClose();
    clearAlerts();
  };

  const popRoute = () =>
    setRouteStack(routeStack.slice(0, routeStack.length - 1));
  const pushRoute = (newRoute: string, params?: params) => {
    setRouteStack([...routeStack, newRoute]);
    const props = params?.props;
    const title = params?.title;
    if (componentDir)
      setComponentDir({
        ...componentDir,
        [newRoute]: {
          ...componentDir[newRoute],
          title: title ? title : componentDir[newRoute].title,
          props,
        },
      });
  };

  const memoryRouterValues = useMemo(
    () => ({
      pushRoute,
      popRoute,
    }),
    [routeStack, componentDir],
  );

  const alertContextValue = {
    pushAlert,
    clearAlerts,
  };

  return (
    <Transition in={visible} timeout={200} unmountOnExit>
      <div
        onClick={handleClose}
        className={clsx(styles.drawerContainer, !visible && styles.closing)}
        style={{ position: usePortal ? "absolute" : "static" }}
      >
        <div
          className={clsx(styles.drawer, className)}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.headerContainer}>
            {alerts.length > 0 && (
              <div className={styles.alertContainer}>
                {alerts.map((alert) => (
                  <AlertBanner
                    type={alert.type}
                    message={alert.message}
                    key={alert.id}
                    onClose={() => closeAlert(alert.id)}
                  />
                ))}
              </div>
            )}
            <div className={clsx(styles.drawerHeader, headerClassName)}>
              {routeStack.length > 1 && displayCenterTitle && (
                <Button
                  size="small"
                  type="secondary-gray"
                  onClick={popRoute}
                  Icon={CaretLeft}
                />
              )}
              <div className={styles.headerTitleContainer}>
                {routeStack.length > 1 && !displayCenterTitle && (
                  <Button
                    size="small"
                    type="secondary-gray"
                    onClick={popRoute}
                    Icon={CaretLeft}
                  />
                )}
                <Heading type="02">{displayedTitle}</Heading>
                {displayTag && (
                  <Tag content={displayTag} type={displayTagType} />
                )}
              </div>
              <Button
                size="small"
                type="secondary-gray"
                onClick={handleClose}
                Icon={X}
              />
            </div>
          </div>

          <memoryRouterContext.Provider value={memoryRouterValues}>
            <AlertContext.Provider value={alertContextValue}>
              <div className={styles.contentContainer}>
                {children ? (
                  children
                ) : componentDir ? (
                  <TransitionGroup component={null}>
                    {activePages?.map(
                      ({ Component, props, currentPage, route }) => (
                        <CSSTransition
                          timeout={300}
                          classNames={mountingClassnames}
                          key={route}
                        >
                          <CSSTransition
                            in={currentPage}
                            timeout={300}
                            classNames={transitionClassnames}
                          >
                            <div className={styles.page}>
                              <Component {...props} />
                            </div>
                          </CSSTransition>
                        </CSSTransition>
                      ),
                    )}
                  </TransitionGroup>
                ) : undefined}
              </div>
            </AlertContext.Provider>
          </memoryRouterContext.Provider>
        </div>
      </div>
    </Transition>
  );
};

export const useMemoryRouter = () => {
  return useContext<MemoryRouterContext>(memoryRouterContext);
};
