import {
  ControlBarButton,
  ModalButton,
} from "amazon-chime-sdk-component-library-react";
import styled from "styled-components";

export const StyledP = styled.p`
  padding: 1rem 1rem 1rem 0;
`;

export const StyledEndMeetingControl = styled(ControlBarButton)`
  button {
    background-color: #d92d20;
    color: #ffffff;
    &:hover {
      background-color: #e13f32;
      color: #ffffff;
    }
  }
`;

export const StyledEndMeetingButton = styled(ModalButton)`
  background-color: #d92d20;

  &:hover {
    background-color: #e13f32;
  }
`;
