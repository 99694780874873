import { useEffect, useState } from "react";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { Table } from "../../../components/table/Table";
import { Medication } from "../../../lib/interfaces/medications";
//import { getPatientActiveMedications, getPatientInactiveMedications } from "../../../lib/apis/medications"
import { useMedications } from "../../../lib/hooks/useMedications";
import { CellProps } from "../../../components/table/Table";
import { format } from "date-fns";
import { useOktaAuth } from "@okta/okta-react";
import styles from "./style.module.css";
import { PatientInfo } from "../../../lib/interfaces/user";
import { Spinner } from "../../../components/spinner/Spinner";
import axios from "axios";
import { MedicationTables } from "../../../lib/apis/medications";

export interface MedicationsProps {
  patient: PatientInfo;
}

export const Medications = ({ patient }: MedicationsProps) => {
  const { getMedicationsTableData } = useMedications();

  const controller = new AbortController();

  const [loading, setLoading] = useState<boolean>(true);
  const [medicationDataLoaded, setMedicationDataLoaded] =
    useState<boolean>(false);
  const [activeTableData, setActiveTableData] = useState<CellProps[][]>([]);
  const [inactiveTableData, setInactiveTableData] = useState<CellProps[][]>([]);

  const columns = ["Medication", "Instruction"];

  useEffect(() => {
    getMedicationsTableData(patient.id, controller).then(
      (data: MedicationTables) => {
        setActiveTableData(data.activeTableData);
        setInactiveTableData(data.inActiveTableData);
        setMedicationDataLoaded(true);
      },
    );
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (medicationDataLoaded) {
      setLoading(false);
    }
  }, [medicationDataLoaded]);

  return (
    <div>
      <Heading type="02" className={styles.header}>
        Medications
      </Heading>
      {!loading ? (
        <div>
          <div className={styles.activeTable}>
            <Table
              title="Active"
              friendlyTitle="active medications"
              data={activeTableData}
              columns={columns}
            />
          </div>
          <div>
            <Table
              title="Inactive"
              friendlyTitle="inactive medications"
              data={inactiveTableData}
              columns={columns}
            />
          </div>
        </div>
      ) : (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
