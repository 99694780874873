import clsx from "clsx";
import { CircleWavyCheck } from "phosphor-react";
import { AvatarGroupCircle } from "../avatarGroupCircle/AvatarGroupCircle";
import { AvatarGroup } from "../avatarGroup/AvatarGroup";
import { Avatar } from "../avatar/Avatar";
import { PatientInfo, UserInfo } from "../../lib/interfaces/user";
import { ReactChild, useMemo } from "react";
import styles from "./style.module.css";
import { useHistory } from "react-router-dom";
import { MembershipPremium } from "../../lib/interfaces/patientInfo";

export type User = UserInfo | PatientInfo;

export type AvatarLabelGroupSize = "sm" | "md" | "lg" | "xl" | "xxl";
export interface AvatarLabelGroupProps {
  size?: AvatarLabelGroupSize;
  users: User[];
  className?: string;
  titleOverride?: ReactChild;
  userIncluded?: boolean;
  linkUser?: boolean;
  openNewTab?: boolean;
  avatarGroupGeneral?: boolean;
  onClick?: () => void;
  displayNames?: boolean;
}

export const AvatarLabelGroup = ({
  users,
  titleOverride,
  size = "xl",
  onClick,
  className,
  userIncluded = false,
  linkUser = false,
  openNewTab = false,
  avatarGroupGeneral = false,
  displayNames = true,
}: AvatarLabelGroupProps) => {
  const history = useHistory();

  const allNames = useMemo(
    () =>
      users.map((user) => [user.firstName, user.lastName].join(" ")).join(", "),
    [users],
  );
  const displayedNames = useMemo(
    () =>
      users
        .slice(0, 3)
        .map((user) => [user.firstName, user.lastName].join(" "))
        .join(", "),
    [users],
  );
  const userTitles = useMemo(
    () =>
      [userIncluded ? "You" : null, ...users.map((user: User) => user.title)]
        .filter(Boolean)
        .join(", "),
    [users],
  );

  const handlePatientClick = () => {
    history.push(`patient/${users[0].id}/careplan`);
  };

  const checkIfProviderInProfile = () =>
    !window.location.href.includes("/careplan");

  return (
    <div className={clsx(styles.avatarLabelGroup, className)}>
      {users.length > 1 ? (
        <div>
          {!avatarGroupGeneral ? (
            <AvatarGroupCircle
              users={users}
              //@ts-ignore
              size={
                ["lg", "xl"].includes(size)
                  ? size
                  : ["sm", "md"].includes(size)
                    ? "lg"
                    : "xl"
              }
            />
          ) : (
            <AvatarGroup users={users} size="sm" />
          )}
        </div>
      ) : (
        <div>
          {linkUser && checkIfProviderInProfile() ? (
            openNewTab ? (
              <a
                href={"patient/" + users[0].id + "/careplan"}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.newTab}
              >
                <Avatar user={users[0]} size={size} />
              </a>
            ) : (
              <Avatar
                onClick={handlePatientClick}
                user={users[0]}
                size={size}
              />
            )
          ) : (
            <Avatar onClick={onClick} user={users[0]} size={size} />
          )}
        </div>
      )}
      {displayNames && (
        <div className={clsx(styles.infoContainer, styles[size])}>
          <div
            data-dd-privacy="mask"
            title={allNames}
            className={styles.nameContainer}
          >
            <div
              data-dd-privacy="mask"
              className={clsx(styles.names, linkUser && styles.link)}
            >
              {linkUser && checkIfProviderInProfile() ? (
                openNewTab ? (
                  <a
                    href={"patient/" + users[0].id + "/careplan"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.newTab}
                  >
                    {displayedNames}
                  </a>
                ) : (
                  <div data-dd-privacy="mask" onClick={handlePatientClick}>
                    {displayedNames}
                  </div>
                )
              ) : (
                <div data-dd-privacy="mask">{displayedNames}</div>
              )}
            </div>
            {/* @ts-ignore */}
            {users.length === 1 &&
              (users[0] as PatientInfo)?.settings?.General?.Membership ===
                MembershipPremium && (
                <CircleWavyCheck color="#1A6D93" weight="fill" />
              )}
            {/* number of additional users */}
            {users.length > 3 ? (
              <div>{[" +", users.length - 3].join("")}</div>
            ) : null}
          </div>
          <div
            data-dd-privacy="mask"
            title={!titleOverride ? userTitles : undefined}
            className={styles.subText}
          >
            {titleOverride || userTitles}
          </div>
        </div>
      )}
    </div>
  );
};
