import { PatientInfo, ProviderInfo } from "./user";
import { AppointmentType, VisitType } from "../apis/types/visitReason.types";

export interface Visit {
  visitId: string | null;
  visitIdEHR: string | null;
  providerId: string;
  providerName: string;
  dateTime: string;
  visitType: VisitType;
  visitTypeId: string;
  duration: number;
  comment?: string;
  checkedIn: boolean;
  checkingInWith: string;
  questionnairesComplete: boolean;
  appointmentType: AppointmentType | null;
  userId: string;
  reason?: string;
  providerPhotoUrl?: string;
  copay?: string;
  visitDisplayTitle?: string;
  paymentCompleted?: boolean;
  careGiven?: boolean;
  patient?: PatientInfo;
}

export interface Appointment extends Visit {
  patient: PatientInfo;
  provider: ProviderInfo;
  careCoordinator?: ProviderInfo;
}

export const defaultVisit: Visit = {
  visitId: "",
  visitIdEHR: null,
  providerId: "",
  providerName: "",
  dateTime: "",
  visitType: "" as VisitType,
  visitTypeId: "",
  duration: 0,
  checkedIn: false,
  checkingInWith: "",
  questionnairesComplete: false,
  appointmentType: null,
  userId: "",
};
