import clsx from "clsx";
import { useMemo } from "react";
import styles from "./style.module.css";

export type ActiveCarePlanTab =
  | "summary"
  | "intention"
  | "progress"
  | "medications"
  | "move"
  | "nourish"
  | "calm"
  | "resources"
  | "loveLetter"
  | undefined;
export type ActiveRecordTab =
  | "requiredApprovals"
  | "accountInformation"
  | "upcomingAppointment"
  | "patientInformation"
  | "vitals"
  | "visits"
  | "notes"
  | "medications"
  | "orders"
  | "medicalHistory"
  | undefined;
export type TabType = "carePlan" | "record";

interface Tab {
  name: string;
  value?: ActiveCarePlanTab | ActiveRecordTab;
}

const carePlanTabs: Tab[] = [
  { name: "Summary", value: "summary" },
  { name: "Intention", value: "intention" },
  { name: "Overall Progress", value: "progress" },
  { name: "Medication", value: "medications" },
  { name: "Move", value: "move" },
  { name: "Nourish", value: "nourish" },
  { name: "Calm", value: "calm" },
  { name: "All Resources", value: "resources" },
  { name: "Love Letter", value: "loveLetter" },
];

const recordTabs: Tab[] = [
  { name: "Account Information", value: "accountInformation" },
  { name: "Upcoming Appointment", value: "upcomingAppointment" },
  { name: "Patient Information", value: "patientInformation" },
  { name: "Vitals", value: "vitals" },
  { name: "Visits", value: "visits" },
  { name: "Notes", value: "notes" },
  { name: "Medications", value: "medications" },
  { name: "Orders", value: "orders" },
  { name: "Medical History", value: "medicalHistory" },
];

const VerificationTab: Tab[] = [
  { name: "Required Approvals", value: "requiredApprovals" },
];

export interface TabsProps {
  type: TabType;
  activeTab: ActiveCarePlanTab | ActiveRecordTab;
  onTabClick: (tab: ActiveCarePlanTab | ActiveRecordTab) => void;
  showVerificationTab: boolean;
}

export const Tabs = ({
  type,
  activeTab,
  onTabClick,
  showVerificationTab,
}: TabsProps) => {
  const recordTabList = showVerificationTab
    ? [...VerificationTab, ...recordTabs]
    : recordTabs;

  const tabs = useMemo(() => {
    if (type === "carePlan") return carePlanTabs;
    else return recordTabList;
  }, [type]);

  return (
    <ul className={styles.tabs}>
      {tabs.map((tab) => (
        <li key={tab.value} className={styles.tabContainer}>
          <a
            onClick={() => onTabClick(tab.value)}
            className={clsx(
              styles.tab,
              activeTab === tab.value && styles.active,
            )}
            aria-label={tab.name}
          >
            {tab.name}
          </a>
        </li>
      ))}
    </ul>
  );
};
