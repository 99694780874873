import styled from "styled-components";

import { ControlProps } from "../../../types/index";

type Props = Pick<ControlProps, "backgroundColor" | "textColor" | "disabled">;
export const StyledBaseControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 1.5rem;
`;
export const StyledBaseControlButton = styled.div<Props>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#ffffff"};
  color: ${(props) => (props.textColor ? props.textColor : "#ffffff")};
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
  opacity: ${(props) => (props.disabled ? "30%" : "100%")};

  & svg {
    opacity: ${(props) => (props.disabled ? "50%" : "100%")};
  }
`;

export const StyledBaseControlLabelAndPopOverContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  line-height: 16px;
`;

export const StyledBaseControlLabel = styled.div<Props>`
  color: ${(props) => (props.textColor ? props.textColor : "#ffffff")};
  font-size: 12px;
  font-weight: 400;
`;
