import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./style.css";

export interface Route {
  name: string;
  value: any;
}

interface NavigationTabsProps {
  routes: Route[];
  value: any;
  onChange: (value: string) => void;
}

export const NavigationTabs = ({
  routes,
  value,
  onChange,
}: NavigationTabsProps) => {
  const handleTabChange = (event: React.SyntheticEvent, newTab: string) =>
    onChange(newTab);

  return (
    <Tabs
      id="tabs"
      value={value}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      onChange={handleTabChange}
    >
      {routes.map((route) => (
        <Tab
          key={route.value}
          label={route.name}
          aria-label={route.name}
          disableRipple
          value={route.value}
        />
      ))}
    </Tabs>
  );
};
