import {
  useMeetingManager,
  useVideoInputs,
} from "amazon-chime-sdk-component-library-react";

import { useMemo } from "react";

const useVideoInputOptions = () => {
  const { selectedDevice } = useVideoInputs();
  const meetingManager = useMeetingManager();
  const { videoInputDevices } = meetingManager;
  const options = useMemo(
    () =>
      videoInputDevices
        ? videoInputDevices
            .filter((device) => device.deviceId !== "")
            .map((device) => {
              return {
                deviceId: device.deviceId,
                label: device.label,
                kind: device.kind,
                checked: selectedDevice === device.deviceId,
                handleClick: async () =>
                  await meetingManager.startVideoInputDevice(device.deviceId),
              };
            })
        : [],
    [videoInputDevices, selectedDevice]
  );
  const disabled = options.length === 0;

  return { options, disabled };
};

export { useVideoInputOptions };
