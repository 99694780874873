export enum GenderType {
  Female = "Female",
  Male = "Male",
  NonBinary = "Non-binary",
  Unknown = "Unknown",
}

export const transformToGenderType = (assignedSex?: string): GenderType => {
  const dict: { [key: string]: GenderType } = {
    female: GenderType.Female,
    male: GenderType.Male,
    nonbinary: GenderType.NonBinary,
    unknown: GenderType.Unknown,
    x: GenderType.Unknown,
  };

  return dict[assignedSex?.toLowerCase() ?? "unknown"];
};
